import { LI, UL } from "../../AbstractElements";
import SimpleBar from "simplebar-react";
import SidebarBack from "./SidebarBack";
import MenuList from "./MenuList";
import { Link } from "react-router-dom";
import SVG from "../../utils/CommonSvgIcon/SVG";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SidebarMenuTypes } from "../../Data/Layout/Sidebar/SidebarTypes";
import { SidebarMenu } from "../../Data/Layout/Sidebar/Menu";
import { useAppSelector } from "../../ReaduxToolkit/Hooks";
import { AesDecrypt } from "../../Service/crypto";
import webservice from "../../Service/webservice";


export interface PropsTypes {
  setOpen: (item: boolean) => void
  open?: boolean
  onCloseSidebar?:any
}

interface MenuItem {
  RefId: string;
  ParentID: any;
  Menu_type: string;
  Menu_show: string;
  Menu_order: any;
  Menu_Label: string;
  Menu_icon: string;
  Menu_url: string;
}

interface PermissionItem {
  RefId: string;
  MenuActions: any;
  MenuKey: string;
  MenuLabel: string;
}

const SidebarMain = ({ setOpen, open ,onCloseSidebar}: PropsTypes) => {
  const { sidebarIconType } = useAppSelector((state) => state.themeCustomizer);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<string[]>([]);
  const [sideMenu, setSideMenu] = useState("")
  const { layout } = useAppSelector((state) => state.themeCustomizer);

  const [navBarItem, setNavBarItem] = useState<SidebarMenuTypes[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = localStorage.id;
        const response1 = await fetch(webservice + "api/Get_permission", {
          method: "POST",
          body: JSON.stringify({ data }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const jsonData = await response1.json();
        const Permission: PermissionItem[] = jsonData[0].Permission;

        const response = await fetch(webservice + "api/AppMenu/getmenu", {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch menu data");
        }

        const datas = await response.json();
        const menuData: MenuItem[] = AesDecrypt(datas);

        const navbarItems = generateNavbarItems(menuData, Permission);
        setNavBarItem(navbarItems);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    }

    if (navBarItem.length === 0) {
      fetchData();
    }
  }, [navBarItem]);

  const generateNavbarItems = (menuData: MenuItem[], Permission: PermissionItem[]) => {
    if (!menuData || !Permission) {
      return [];
    }

    const submenuItems = menuData.filter((menu) => menu.Menu_type === "Submenu");

    const navbarItems = menuData
      .filter(
        (menu) =>
          menu.ParentID === 0 &&
          menu.Menu_show === "yes" &&
          Permission.some((item) => item.RefId === menu.RefId)
      )
      .sort((a, b) => parseInt(a.Menu_order, 10) - parseInt(b.Menu_order, 10))
      .map((menu) => {
        const children = submenuItems
          .filter(
            (child) =>
              child.ParentID === menu.RefId &&
              Permission.some((item) => item.RefId === child.RefId)
          )
          .sort((a, b) => parseInt(a.Menu_order, 10) - parseInt(b.Menu_order, 10))
          .map((child) => ({
            title: child.Menu_Label,
            svgIcon: child.Menu_icon,
            link: child.Menu_url,
            type: "link",
          }));

        return {
          title: menu.Menu_Label,
          svgIcon:menu.Menu_icon,
          link: menu.Menu_url,
          type: "link",
          ...(children.length > 0 && { subMenu: children }),
        };
      });

    return navbarItems;
  };

  const handleClick = (item: SidebarMenuTypes) => {
    if (layout === "material-icon") {
      let menu: string = sideMenu
      menu = sideMenu !== item.title ? item.title : ""
      setSideMenu(menu);
      setOpen(item.subMenu && item.subMenu.length > 0 && menu !== "" ? true : false)      
    }
  }


  return (
    <nav className="sidebar-main">
      <div id="sidebar-menu">
        <UL className="sidebar-links simple-list" id="simple-bar">
          <SimpleBar className="w-100 simplebar-scrollable-y h-100">
            <SidebarBack />
            {navBarItem.map((data, index) => (
              <LI className={`sidebar-list`} key={index}>
                <Link
                  className={`sidebar-link sidebar-title ${sideMenu === data.title ? "active" : ""}`}
                  to={data.link ? data.link : "#"}
                  onClick={() => handleClick(data)}
                >
                  <SVG
                    className={`${sidebarIconType}-icon`}
                    iconId={`${sidebarIconType === "fill" ? "fill-" : ""}${data.svgIcon
                      }`}
                  />
                  <span>{t(data.title)}</span>
                  
                </Link>
                {data.subMenu && data.subMenu.length > 0 && (
                  <UL
                    className="sidebar-submenu custom-scrollbar simple-list"
                    style={{
                      display: `${layout === "material-icon" ? data.title === sideMenu ? "block" : "none" : ""
                        }`,
                    }}
                  >
                    {data.title1 && data.subMenu && data.subMenu.length > 0 && (
                      <li className="sidebar-head">{t(data.title1)}</li>
                    )}
                    {data.subMenu && data.subMenu.length > 0 && (
                      <MenuList
                        subMenuData={data}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        level={0}
                        setOpen={setOpen}
                        onCloseSidebar={onCloseSidebar}
                        sideMenu={sideMenu}
                        setSideMenu={setSideMenu}
                      />
                    )}
                  </UL>
                )}
              </LI>
            ))}
          </SimpleBar>
        </UL>
      </div>
    </nav>
  );
};

export default SidebarMain;
