import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, Button, CardBody, CardHeader, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import webservice from '../../../Service/webservice';
import * as Yup from 'yup';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import ReactDatePicker from 'react-datepicker';
import { MD5 } from 'crypto-js';
import { Eye, EyeOff } from 'react-feather';

interface FormValues {
  FirstName: string;
  MiddleName?: string;
  LastName: string;
  MobileNo?: string;
  EmailId: string;
  UserName: string;
  Password: string;
  ActiveStatus: string;
  Role: string;
}

const defaultValues: FormValues = {
  FirstName: '',
  MiddleName: '',
  LastName: '',
  MobileNo: '',
  EmailId: '',
  UserName: '',
  Password: '',
  ActiveStatus: 'Active',
  Role: '',
};

const statusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
];

const MandatoryFields = ['FirstName', 'LastName', 'EmailId', 'UserName', 'Password', 'ActiveStatus','Role'];

interface CheckboxSelections {
  [key: string]: boolean;
}

interface Action {
  MenuKey: string;
  MenuLabel: string;
}

interface SubMenu {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
}

interface MenuItem {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
  Menu: SubMenu[];
  RefID: number;
  ParentID: number;
}

interface PermissionData {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
  Menu: SubMenu[];
  RefID: number;
  ParentID: number;
}

type MenuAction = {
  RefID: number;
  MenuLabel: string;
  Tooltip: string;
  MenuUrl: string;
  MenuKey: string;
};

type MenuItems = {
  RefID: number;
  MenuLabel: string;
  MenuUrl: string;
  MenuKey: string;
  MenuType: string;
  ParentID: number;
  MenuActions: MenuAction[];
  Menu: MenuItem[];
};

type StaticData = {
  Menu: MenuItems[];
};


const fieldMapping: { [key: string]: string } = {
  FirstName: "First Name",
  MiddleName: "Middle Name",
  LastName: "Last Name",
  MobileNo: "Mobile No",
  EmailId: "Email Id",
  Role: "Role",
  UserType:"User Type",
  UserName: "User Name",
  ActiveStatus: "Active Status",
};

const AddUser: React.FC = () => {
  const navigate = useNavigate();
  const [DobDate, setDobDate] = useState<Date | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [method, setMethod] = useState<string>('');
  const [checkboxSelections, setCheckboxSelections] = useState<CheckboxSelections>({});
  const [isAllMenuSelected, setIsAllMenuSelected] = useState<boolean>(false);
  const [permissionData, setPermissionData] = useState<PermissionData[]>([]);

  const [selectedRole, setSelectedRole] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [RoleId, setRoleId] = useState<[]>([]);

  const [typeOptions, setTypeOptions] = useState<[]>([]);
  const [Role, setRole] = useState<string>(localStorage.UserType||'');


  
  useEffect(() => {
    const fetchRole = async () => {
      const get_Role = async (data: any) => {
        const EncParams = AesEncrypt(JSON.stringify(data));
        const response = await fetch(webservice + 'api/Usertype/get_userType', {
          method: 'POST',
          body: JSON.stringify({ EncParams }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
      };

      const response = await get_Role(Role);
      const Roles = response.map((role: any) => ({
        label: role.Role,
        value: role.Role,
        key: role._id,
        Type: role.user_type,
      }));
      setTypeOptions(Roles);
    };

    fetchRole();
  }, [Role]);

  const getPermissionMenuData = async () => {
  const response = await fetch(webservice + 'api/usermanagement/Permision', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const TempData = AesDecrypt(res)
      const convertDBDataToStaticFormat = (dbData: any): StaticData => {
        const staticData: StaticData = {
          Menu: [],
        };

        const menuMap: { [key: number]: any } = {};

        dbData.forEach((item:any) => {
          menuMap[item.RefId] = {
            RefID: item.RefId,
            MenuLabel: item.Menu_Label,
            MenuUrl: item.Menu_url,
            MenuKey: item.Menu_key,
            MenuType: item.Menu_type,
            ParentID: item.ParentID,
            MenuActions: item.Menu_Action.map((action:any, index:number) => ({
              RefID: index + 21,
              MenuLabel: action.charAt(0).toUpperCase() + action.slice(1),
              MenuUrl: '',
              MenuKey: `${item.Menu_key}_${action.toUpperCase()}`
            })),
            Menu: []
          };
        });

        // Sort the items based on Menu_order
        const sortedDBData = dbData.sort((a:any, b:any) => parseInt(a.Menu_order) - parseInt(b.Menu_order));

        sortedDBData.forEach((item:any) => {
          if (item.ParentID === 0 && item.Menu_type === "Menu") {
            staticData.Menu.push(menuMap[item.RefId]);
          } else if (menuMap[item.ParentID] && item.Menu_type === "Submenu") {
            // Sort submenu items based on Menu_order
            menuMap[item.ParentID].Menu.push(menuMap[item.RefId]);
            menuMap[item.ParentID].Menu.sort((a:any, b:any) => parseInt(a.Menu_order) - parseInt(b.Menu_order));
          }
        });
        return staticData;
      };
      const staticData = convertDBDataToStaticFormat(TempData);
      return staticData
    })
  return response;
  }
  const getPermissionCheckboxKeys = async (data:any) => {

  
  const EncParams = AesEncrypt(JSON.stringify(data));
  const response = await fetch(webservice + 'api/usermanagement/getPermissionCheckboxKeys', {
    method: 'POST',
    body: JSON.stringify({ EncParams }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })
    .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData;
  }



  const handleRoleChange = (selectedOption:any) => {
    if (selectedOption) {
        setRoleId(selectedOption.key)
        setSelectedRole(selectedOption.value);
        setSelectedType(selectedOption.Type);
    } else {
        setSelectedRole('')
        setSelectedType('');
        setRoleId([])
        setIsAllMenuSelected(false);
        setCheckboxSelections({})   
    }
};


  const handleAllMenuSelect = (event:any) => {
    const isSelected = event.target.checked;
    setIsAllMenuSelected(isSelected);


    const updatedSelections = { ...checkboxSelections };
    Object.keys(updatedSelections).forEach((key) => {
        updatedSelections[key] = isSelected;
    });

    permissionData.forEach((menuItem) => {
        updatedSelections[menuItem.MenuKey] = isSelected;
        menuItem.MenuActions.forEach((action) => {
            updatedSelections[action.MenuKey] = isSelected;
        });

        menuItem.Menu.forEach((submenu) => {
            updatedSelections[submenu.MenuKey] = isSelected;
            submenu.MenuActions.forEach((action) => {
                updatedSelections[action.MenuKey] = isSelected;
            });
        });
    });

    setCheckboxSelections(updatedSelections);
};

const handleMenuSelect = (menuItem: MenuItem, action: Action, isSelected: boolean,) => {

    const updatedSelections = { ...checkboxSelections };
    updatedSelections[menuItem.MenuKey] = isSelected;
    updatedSelections[action.MenuKey] = isSelected;

    let isAllSelected = true;
    Object.keys(updatedSelections).forEach((key) => {
        if (key !== "SelectAll" && !updatedSelections[key]) {
            isAllSelected = false;
        }
    });

    setIsAllMenuSelected(isAllSelected);

    if (action.MenuKey === `${menuItem.MenuKey}_VIEW` && !isSelected) {
        menuItem.MenuActions.forEach((relatedAction) => {
            updatedSelections[relatedAction.MenuKey] = false;
        });
    }

    if (!isSelected) {
        menuItem.Menu.forEach((submenu) => {
            updatedSelections[submenu.MenuKey] = false;
            submenu.MenuActions.forEach((submenuAction) => {
                updatedSelections[submenuAction.MenuKey] = false;
            });
        });

        const allActionsUnchecked = menuItem.MenuActions.every(
            (submenuAction) => !updatedSelections[submenuAction.MenuKey]
        );
        if (allActionsUnchecked) {
            const viewActionKey = `${menuItem.MenuKey}_VIEW`;
            updatedSelections[viewActionKey] = false;
        }

        const parentMenuItem = permissionData.find(
            (item) => item.RefID === menuItem.ParentID
        );
        if (parentMenuItem) {
            const isAllSubmenusUnchecked = parentMenuItem.Menu.every((submenu) => {
                return (
                    !updatedSelections[submenu.MenuKey] &&
                    submenu.MenuActions.every(
                        (submenuAction) => !updatedSelections[submenuAction.MenuKey]
                    )
                );
            });
            if (isAllSubmenusUnchecked) {
                updatedSelections[parentMenuItem.MenuKey] = false;
                parentMenuItem.MenuActions.forEach((parentAction) => {
                    updatedSelections[parentAction.MenuKey] = false;
                });
            }
        }
    }
    if (isSelected) {
        if (menuItem.ParentID !== 0) {
            const parentMenuItem = permissionData.find(
                (item) => item.RefID === menuItem.ParentID
            );
            if (parentMenuItem) {
                updatedSelections[parentMenuItem.MenuKey] = true;
                parentMenuItem.MenuActions.forEach((parentAction) => {
                    updatedSelections[parentAction.MenuKey] = true;
                });
            }
        }
        if (action.MenuKey !== `${menuItem.MenuKey}_VIEW`) {
            const viewActionKey = `${menuItem.MenuKey}_VIEW`;
            updatedSelections[viewActionKey] = true;
        }
    }

    setCheckboxSelections(updatedSelections);
};

useEffect(() => {
  const fetchData = async () => {
    const response = await getPermissionMenuData();
    const PermissionTemp = response.Menu;
  
    setPermissionData(PermissionTemp);
  
    if (RoleId && typeof RoleId === 'string' && RoleId !== '') {
      const response = await getPermissionCheckboxKeys(RoleId);
  
      const CheckboxKey = response[0];
      
      
      const CheckboxKeys = CheckboxKey ? CheckboxKey.Permission : [];
  
      const updatedSelections: Record<string, boolean> = {};
  
      PermissionTemp.forEach((permissionItem) => {
        const menuKey = permissionItem.MenuKey;
        updatedSelections[menuKey] = CheckboxKeys.includes(menuKey);
        if (permissionItem.MenuActions && permissionItem.MenuActions.length > 0) {
          permissionItem.MenuActions.forEach((action) => {
            const menuActionKey = `${menuKey}_${action.MenuLabel.toUpperCase()}`;
            updatedSelections[menuActionKey] = CheckboxKeys.includes(menuActionKey);
          });
        }
      });

  
      CheckboxKeys.forEach((key:any) => {
        if (!(key in updatedSelections)) {
          updatedSelections[key] = true;
        }
      });
  
      const isAllSelected = Object.values(updatedSelections).every((value) => value === true);
  
      setIsAllMenuSelected(isAllSelected);
      setCheckboxSelections(updatedSelections);
    }
  };

  fetchData();
}, [RoleId]);


  const renderMenuItems = (permissionData: MenuItem[]) => {
    return (
      <div className="MenuTree">
        <ul>
          {permissionData.map((menuItem:any) => (
            <li key={menuItem.MenuKey}>
              <div className="MenuItem">
                <div className="MenuLabel">{menuItem.MenuLabel}</div>
                <div className="MenuAction">
                  <div className="md-checkbox-inline">
                    {menuItem.MenuActions.map((action:any) => (
                      <div key={action.MenuKey} className="md-checkbox form-check-size rtl-input checkbox-checked">
                        <Input
                          type="checkbox"
                          id={`Menu_${action.MenuKey}`}
                          className="me-2 form-check-input"
                          checked={checkboxSelections[action.MenuKey]}
                          onChange={(e) =>
                            handleMenuSelect(
                              menuItem,
                              action,
                              e.target.checked,
                            )
                          }
                        />
                        <label htmlFor={`Menu_${action.MenuKey}`}>
                          <span></span>
                          <span className="check"></span>
                          <span className="box"></span>
                          {action.MenuLabel}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="separator"></div>
              {menuItem.Menu !== undefined && (
                <div className="SubMenu">
                  {renderMenuItems(menuItem.Menu)}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };



  const extractCheckedPermissions = (): any[] => { 
    const checkedPermissions: any[] = []; 

    permissionData.forEach((menuItem) => {
        const permissionItem = {
            RefId: menuItem.RefID, 
            MenuLabel: menuItem.MenuLabel,
            MenuKey: menuItem.MenuKey,
            MenuActions: [] as string[], 
        };

        menuItem.MenuActions.forEach((action) => {
            if (checkboxSelections[action.MenuKey]) {
                permissionItem.MenuActions.push(action.MenuLabel.toLowerCase());
            }
        });

        if (permissionItem.MenuActions.length > 0) {
            checkedPermissions.push(permissionItem);
        }

        menuItem.Menu.forEach((submenu:any) => {
            const submenuPermissionItem = {
                RefId: submenu.RefID,
                MenuLabel: submenu.MenuLabel,
                MenuKey: submenu.MenuKey,
                MenuActions: [] as string[], 
            };

            submenu.MenuActions.forEach((action:any) => {
                if (checkboxSelections[action.MenuKey]) {
                    submenuPermissionItem.MenuActions.push(action.MenuLabel.toLowerCase());
                }
            });

            if (submenuPermissionItem.MenuActions.length > 0) {
                checkedPermissions.push(submenuPermissionItem);
            }
        });
    });

    return checkedPermissions;
};

  const AddUser = async (data: any) => {
    const Refid = uuidv4();
    const params = data.params
    const hashedPassword = MD5(data.params.Password).toString();
    const Data = { params, Refid,hashedPassword};
    const EncParams = AesEncrypt(JSON.stringify(Data));
    const ref = await fetch(webservice + 'api/usermanagement/Adduser', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then(response => response.json());
    return AesDecrypt(ref);
  };

  const AddAuditLogData = async (data: any) => {
    data.Userid = localStorage.id;
    const ID = data.Refid;
    const EncParams = AesEncrypt(JSON.stringify({ Data: data, ID }));
    const ref =await fetch(webservice + 'api/usermanagement/AddauditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then(response => response.json());
    return AesDecrypt(ref);
  };

  const handleSubmit = async (data: FormValues) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const Permissions = extractCheckedPermissions();
    if (Permissions) {
      (data as any).Permission = Permissions
      }
    const RequiredData: { [key: string]: string | undefined } = {};
    MandatoryFields.forEach((item) => {
      RequiredData[item] = (data as any)[item];
    });


    if (Object.values(RequiredData).every(field => field && field.length > 0)) {
      const getDataValue = (field: keyof FormValues) => {
        if (data[field] === undefined) {
          return '';
        } else {
          return (data[field] as any).value || data[field];
        }
      };
      const auditLogData = Object.entries(data)
        .filter(([field, value]) => value && value !== "")
        .map(([field, value]) => {
          const item = {
            field: fieldMapping[field] || field,
            value: getDataValue(field as keyof FormValues),
          };
          if (field === 'Permission') {
            const permissionsData = Permissions.map((permission: any) => ({
              MenuLabel: permission.MenuLabel,
              MenuActions: permission.MenuActions.join(',  '),
            }));
            item.value = permissionsData;
          }
          return item;
        })

        if (RoleId) {
        (data as any).RoleId = RoleId}
        
      const Data = { auditLogData, Username: localStorage.getItem('UserName') || '' };
      try {
        const ref = await AddUser({ params: data });
       
        if (ref === 'Username is already taken.') {
            setIsSubmitting(false);
            Swal.fire({
                title: 'Warning',
                text: 'Username already exists.',
                icon: 'warning',
                customClass: {},
                showClass: {
                    popup: 'animate__animated animate__shakeX',
                },
                buttonsStyling: true,
                allowOutsideClick: false,
            });
        } else if (ref === 'email is already taken.') {
            setIsSubmitting(false);
            Swal.fire({
                title: 'Warning',
                text: 'Email already exists.',
                icon: 'warning',
                customClass: {},
                showClass: {
                    popup: 'animate__animated animate__shakeX',
                },
                buttonsStyling: true,
                allowOutsideClick: false,
            });
        } else if (ref === 'Username and email are already taken.') {
            setIsSubmitting(false);
            Swal.fire({
                title: 'Warning',
                text: 'Username and email already exist.',
                icon: 'warning',
                customClass: {},
                showClass: {
                    popup: 'animate__animated animate__shakeX',
                },
                buttonsStyling: true,
                allowOutsideClick: false,
            });
        } else {
          if (ref !== 'Username and email are already taken.' && ref !== 'Username is already taken.'
            && ref !== 'email is already taken.') {
            const response =await AddAuditLogData({ ...Data, Refid: ref })
            if (response === 'Added Successfully') {
            Swal.fire({
                title: 'Insurance Verification Platform',
                html: '<span style="color: green;">User Added successfully!</span>',
                icon: 'success',
                customClass: {},
                showClass: {
                    popup: 'animate__animated animate__flipInX',
                },
                buttonsStyling: true,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.value) {
                    navigate('/userManagement');
                }
            });
          }}
        }
    } catch (error) {
        console.error('Error adding client:', error);
    } finally {
        setIsSubmitting(false);
    }
    } else {
      // Handle missing required fields
      console.warn('Missing required fields:', RequiredData);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    UserName: Yup.string().required("User Name is required"),
    Password: Yup.string().required("Password is required"),
    EmailId: Yup.string().email("Invalid email format").required("Email is required"),
    ActiveStatus: Yup.string().oneOf(statusOptions.map(option => option.value), "Please select a valid Status").required("Please select a valid Status"),
    Role: Yup.string().oneOf(typeOptions.map((option:any) => option.value), "Please select a valid Role").required("Please select a valid Role"),
  });

  return (
    <>
      <Breadcrumbs mainTitle='Add User' parent='MOS Management' title='Add User' to={`/userManagement`} />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
            <Col></Col>
          </CardHeader>
          <CardBody className='py-2 my-25'>
            <Formik initialValues={defaultValues} validationSchema={FORM_VALIDATION} onSubmit={handleSubmit}>
              {({ setFieldValue }) => (
                <Form className="form-horizontal">
                  <Row>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> First Name  <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="FirstName" type="text" className="form-control" placeholder="Enter the First Name" />
                        <ErrorMessage name="FirstName" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Middle Name </Label>
                        <Field name="MiddleName" type="text" className="form-control" placeholder="Enter the Middle Name" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Last Name <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="LastName" type="text" className="form-control" placeholder="Enter the Last Name" />
                        <ErrorMessage name="LastName" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> User Name <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="UserName" type="text" className="form-control" placeholder="Enter the User Name" />
                        <ErrorMessage name="UserName" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Role <span style={{ color: 'red' }}> * </span></Label>
                        <Select
                          name="Role"
                          options={typeOptions}
                          onChange={(option: any) => {setFieldValue('Role', option?.value);setFieldValue('UserType', option?.Type); handleRoleChange(option); }}
                          className="react-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage name="Role" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Password <span style={{ color: 'red' }}> * </span></Label>
                        <InputGroup>
                          <Field name="Password" placeholder="Enter the Password" type={showPassword ? 'text' : 'password'} className="form-control" />
                          <InputGroupText className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <EyeOff size={14} /> : <Eye size={14} />}
                          </InputGroupText>
                        </InputGroup>
                        <ErrorMessage name="Password" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Email Id <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="EmailId" type="text" className="form-control" placeholder="Enter the email Id" />
                        <ErrorMessage name="EmailId" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Mobile No </Label>
                        <Field name="MobileNo" type="text" className="form-control" placeholder="Enter the Mobile No." />
                        <ErrorMessage name="MobileNo" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Status</Label>
                        <Select
                          name="ActiveStatus"
                          options={statusOptions}
                          defaultValue={statusOptions[0]}
                          onChange={option => setFieldValue('ActiveStatus', option?.value)}
                          className="react-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage name="ActiveStatus" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='gy-2 pt-20'  >
                    <Col  >
                      <div className="row">
                        <div className="col-md-8 UserPermissions">
                          <div className="MenuItem Title">
                            <div className="MenuLabel"> User Default Permission</div>
                            <div className="MenuAction">
                              <div className="md-checkbox-inline">
                                <div className="md-checkbox form-check-size rtl-input checkbox-checked">
                                  <Input
                                    type="checkbox"
                                    id="SelectAll"
                                    className="me-2 form-check-input"
                                    checked={isAllMenuSelected}
                                    onChange={handleAllMenuSelect}
                                  />
                                  <label htmlFor="SelectAll">
                                    <span></span>
                                    <span className="check"></span>
                                    <span className="box"></span>All
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div >
                          {renderMenuItems(permissionData)}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Button type="submit" color="success" className="me-3">Submit</Button>
                        <Link to={`/userManagement`}>
                          <Button color="danger">Cancel</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default AddUser;

