// ** React Imports
import { Fragment, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Row, Col, Card, Label, Button, CardBody, CardHeader, FormGroup } from 'reactstrap'
import webservice from '../../../Service/webservice';
import * as Yup from "yup";
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto'
import React, { useState } from 'react';
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Select from 'react-select';
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from '../../../ReaduxToolkit/Hooks'
import SVG from '../../../utils/CommonSvgIcon/SVG'

interface FormValues {
  MenuLabel: string;
  MenuType?: string;
  MenuUrl?: string;
  Menukey: string;
  MenuIcon: string;
  MenuOrder?: string;
  MenuShow: string;
  ParentMenu?: string;
  menuAction?: any;

}

const defaultValues: FormValues = {
  MenuLabel: '',
  MenuType: '',
  MenuUrl: '',
  Menukey: '',
  MenuIcon: '',
  MenuOrder: '',
  MenuShow: '',
  ParentMenu: '',
  menuAction: ''
};

const StatusOptions: { value: string; key: string; label: string }[] = [
  { value: 'yes', key: '0', label: 'Yes' },
  { value: 'no', key: '1', label: 'No' },
];


const fieldMapping: { [key: string]: string } = {
  MenuLabel: 'Menu Label',
  MenuType: 'Menu Type',
  Menukey: 'Menu Key',
  MenuIcon: 'Menu Icon',
  MenuShow: 'Menu Show',
  menuAction: 'Menu Action',
};

const MandatoryFields = ['MenuLabel', 'MenuIcon', 'MenuShow', 'Menukey'];

const Username = localStorage.getItem('UserName') || '';

const AddMenu: React.FC = () => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [menuType, setMenuType] = useState<string>('Menu');
  const [res1, setRes1] = useState<any[]>([]);

  const [parentMenuOptions, setParentMenuOptions] = useState<{ value: string; label: string }[]>([]);
  const [menuAction, setMenuAction] = useState({ add: false, edit: false, delete: false, view: true });



  const [IconOptions, setIconOptions] = useState<{ label: string, value: string }[]>([]);



  const fetchIconList = async () => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/assets/svg/icon-sprite.svg`);
      const text = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(text, "image/svg+xml");
      const icons = Array.from(xmlDoc.querySelectorAll('symbol')).map(symbol => symbol.id);
      return icons;
    } catch (error) {
      console.error('Error fetching icons:', error);
      return [];
    }
  };

  useEffect(() => {
    const loadIcons = async () => {
      const icons = await fetchIconList();
      const options:any = icons.map((iconId:any) => ({
        label: <SVG iconId={iconId} className="icon-preview" size={24} />,
        value: iconId
      }));
            
      setIconOptions(options);
    };

    loadIcons();
  }, []);


  const handleMenuTypeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setMenuType(e.target.value);
    if (e.target.value === 'Submenu') {
      try {
        const response = await fetch(webservice + 'api/AppMenu/MenuLabels', {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });
        const result = await response.json();
        const decryptedResult = AesDecrypt(result);
        setRes1(decryptedResult);
        const parentMenuOptions = decryptedResult.map((menu: any) => ({
          value: menu.Menu_Label,
          label: menu.Menu_Label,
        }));
        setParentMenuOptions(parentMenuOptions);
      } catch (error) {
        console.error('Error fetching menu labels:', error);
      }
    }
  };

  const AddMenudata = async (params: any) => {
    const Refid = uuidv4();
    const paramsS = params.params;
    const paramsObj = { ...paramsS, Refid };
    const EncParams = AesEncrypt(JSON.stringify(paramsObj));

    const ref = await fetch(webservice + 'api/AppMenu/Addmenu', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(ref);
  };

  // Modify the AddauditLogdata function
  const AddauditLogdata = async (data: any) => {
    data.Userid = localStorage.id;
    const ID = data.Refid;
    const EncParams = AesEncrypt(JSON.stringify({ Data: data, ID }));

    const response = await fetch(webservice + 'api/AppMenu/addAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

  const handleSubmit = async (data: FormValues) => {

    if (menuType === 'Submenu') {
      MandatoryFields.push('ParentMenu');
    }

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    data.MenuType = menuType;

    let ParentID: number | null = null;
    if (menuType === 'Menu') {
      ParentID = 0;
    } else {
      // data.ParentMenu = ParentMenu;
      if (data.ParentMenu !== '') {
        const foundItem = res1.find(item => item.Menu_Label === data.ParentMenu);
        ParentID = foundItem ? foundItem.RefId : null;
      }
    }

    const selectedActions = [];
    if (menuAction.add) {
      selectedActions.push('add');
    }
    if (menuAction.edit) {
      selectedActions.push('edit');
    }
    if (menuAction.delete) {
      selectedActions.push('delete');
    }
    if (menuAction.view) {
      selectedActions.push('view');
    }
    data.menuAction = selectedActions;

    const RequiredData: { [key: string]: string | undefined } = {};
    MandatoryFields.forEach((item) => {
      RequiredData[item] = (data as any)[item];
    });

    if (Object.values(RequiredData).every(field => field && field.length > 0)) {
      const getDataValue = (field: keyof FormValues) => {
        if (data[field] === undefined) {
          return '';
        } else {
          return (data[field] as any).value || data[field];
        }
      };

      const auditLogData = Object.entries(data)
        .filter(([field, value]) => value && value !== '' && field !== 'ParentID')
        .map(([field, value]) => ({
          field: fieldMapping[field] || field,
          value: getDataValue(field as keyof FormValues),
        }));


      const Data = { auditLogData, Username };
      if (ParentID !== null) {
        (data as any).ParentID = ParentID;
      }

      try {
        const ref = await AddMenudata({ params: data });
        await AddauditLogdata({ ...Data, Refid: ref });
        Swal.fire({
          title: 'Insurance Verification ',
          icon: 'success',
          text: 'Menu Added Successfully!',
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        setIsSubmitting(false);
        navigate(`/appmenu`);
      } catch (error) {
        console.error('Error adding menu:', error);
        setIsSubmitting(false);
      }
    } else {
      for (const key in RequiredData) {
        if (RequiredData[key] && RequiredData[key]!.length === 0) {

        }
      }
    }
  };


  const FORM_VALIDATION = Yup.object().shape({
    MenuLabel: Yup.string().required("Menu Label is required"),
    Menukey: Yup.string().required("Menu Key is required"),
    MenuIcon: Yup.string().oneOf(IconOptions.map((option: any) => option.value), "Please select a Menu Icon").required("Menu Icon is required"),
    MenuShow: Yup.string().oneOf(StatusOptions.map((option: any) => option.value), "Please select a Menu Show").required("Menu Show is required"),
    ParentMenu: Yup.string().when('MenuType', {
      is: 'Submenu',
      then: (schema) => schema.required("Parent Menu is required"),
      otherwise: (schema) => schema.notRequired()
    }),
  });


  return (
    <>
      <Breadcrumbs mainTitle='Add Menu' parent='App Menu' title='Add Menu' to={`/appmenu`} />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
          </CardHeader>
          <CardBody className='py-2 my-25'>
            <Formik initialValues={defaultValues} validationSchema={FORM_VALIDATION} onSubmit={handleSubmit}>
              {({ setFieldValue, errors, touched }) => (
                <Form className="form-horizontal">
                  <Row>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Label <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="MenuLabel" type="text" className="form-control" placeholder={"Enter the Menu Label"} />
                        <ErrorMessage name="MenuLabel" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Url </Label>
                        <Field name="MenuUrl" type="text" className="form-control" placeholder={"Enter the Menu Url"} />
                        <ErrorMessage name="MenuUrl" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Key <span style={{ color: 'red' }}> * </span></Label>
                        <Field
                          name="Menukey" 
                          type="text" 
                          className="form-control" 
                          onChange={(e: any) => {
                          const { name, value } = e.target;
                          const transformedValue = value.replace(/[^A-Za-z_]/g, '').toUpperCase();
                          setFieldValue(name, transformedValue);}} 
                        placeholder={"Enter the Menu Key "} />
                        <ErrorMessage name="Menukey" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Order </Label>
                        <Field name="MenuOrder" type="text" className="form-control" placeholder={"Enter the Menu Order "} 
                        onChange={(e: any) => {
                          const { name, value } = e.target;
                          const transformedValue = value.replace(/[^0-9]/g, '')
                          setFieldValue(name, transformedValue);}} 
                        />
                        <ErrorMessage name="MenuOrder" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Menu Icon <span style={{ color: 'red' }}> * </span></Label>
                        <Select
                          name="MenuIcon"
                          options={IconOptions}
                          className="react-select"
                          classNamePrefix="select"
                          onChange={(selectedOption: any) => {
                            setFieldValue('MenuIcon', selectedOption ? selectedOption.value : '');
                          }}
                        />
                        {errors.MenuIcon && touched.MenuIcon && (<ErrorMessage name="MenuIcon" component="span" className="text-danger" />)}
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Menu Show <span style={{ color: 'red' }}> * </span></Label>
                        <Select
                          name="MenuShow"
                          options={StatusOptions}
                          className="react-select"
                          classNamePrefix="select"
                          onChange={(selectedOption: any) => {
                            setFieldValue('MenuShow', selectedOption ? selectedOption.value : '');
                          }}
                        />
                        {errors.MenuShow && touched.MenuShow && (<ErrorMessage name="MenuShow" component="span" className="text-danger" />)}
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup check inline>
                        <Label check> Menu Action </Label>
                        <div className="form-check-size rtl-input checkbox-checked">
                          <FormGroup check inline>
                            <Label check>
                              <Field
                                className="me-2 form-check-input"
                                type="checkbox"
                                checked={menuAction.add}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setMenuAction({ ...menuAction, add: e.target.checked })
                                }
                              />
                              Add
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Field
                                className="me-2 form-check-input"
                                type="checkbox"
                                checked={menuAction.edit}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setMenuAction({ ...menuAction, edit: e.target.checked })
                                }
                              />
                              Edit
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Field
                                className="me-2 form-check-input"
                                type="checkbox"
                                checked={menuAction.delete}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setMenuAction({ ...menuAction, delete: e.target.checked })
                                }
                              />
                              Delete
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Field
                                className="me-2 form-check-input"
                                type="checkbox"
                                checked={menuAction.view}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setMenuAction({ ...menuAction, view: e.target.checked })
                                }
                              />
                              View
                            </Label>
                          </FormGroup>
                        </div>
                        <ErrorMessage name="menuAction" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Type <span style={{ color: 'red' }}> * </span></Label>
                        <div className="form-check-size rtl-input checkbox-checked">
                          <FormGroup check inline>
                            <Label check>
                              <Field className="me-2 form-check-input" type="radio" name="MenuType" value="Menu" checked={menuType === 'Menu'} onChange={handleMenuTypeChange} />
                              Menu
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Field className="me-2 form-check-input" type="radio" name="MenuType" value="Submenu" checked={menuType === 'Submenu'} onChange={handleMenuTypeChange} />
                              Submenu
                            </Label>
                          </FormGroup>
                        </div>
                        <ErrorMessage name="MenuType" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    {menuType === 'Submenu' && (
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Parent Menu <span style={{ color: 'red' }}> * </span></Label>
                          <Select
                            name="ParentMenu"
                            options={parentMenuOptions}
                            className="react-select"
                            classNamePrefix="select"
                            onChange={(selectedOption: any) => {
                              setFieldValue('ParentMenu', selectedOption ? selectedOption.value : '');
                            }}
                          />
                          {errors.ParentMenu && touched.ParentMenu && (<ErrorMessage name="ParentMenu" component="span" className="text-danger" />)}
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Button type="submit" color="success" className="me-3">Submit</Button>
                        <Link to={`/appmenu`}>
                          <Button color="danger">Cancel</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>

  );
};

export default AddMenu;