import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, CardBody, CardHeader, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Plus, X } from 'react-feather';
import webservice from '../../../Service/webservice';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import {
    Appname,
    Callback,
    CustomError,
    Customer,
    Customerse,
    KeyError,
    Request,
    RequestError,
    Verified
} from '../../../utils/Constant';
import Swal from 'sweetalert2';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import { v4 as uuidv4 } from 'uuid';

const Addonder: React.FC = () => {
  const [formSubmit, setFormSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' }
  ];
  const Username = localStorage.getItem('UserName') || '';

  const AddPayorData = async (params: any) => {
    const Refid = uuidv4();
    const paramsS = params.params;
    const paramsObj = { ...paramsS, Refid };
    const EncParams = AesEncrypt(JSON.stringify(paramsObj));
    
    const ref = await fetch(webservice + 'api/Onder/addonder', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(ref);
  };

  const AddAuditLogData = async (data: any) => {
     
    data.Userid = localStorage.id;
    const ID = data.Refid;
    
    const EncParams = AesEncrypt(JSON.stringify({ Data: data, ID }));
    
    const response = await fetch(webservice + 'api/Onder/addAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

  const handleSubmit = async (data: any) => {
    
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    const RequiredData: { [key: string]: any } = {
        Appname: data.Appname,
        Customkey: data.Customkey,
        Secret: data.Secret,
        Requesturl: data.Requesturl,
        Callurl: data.Callurl,
    };

    if (Object.values(RequiredData).every(field => field && field.length > 0)) {
      try {
        // Log audit data before submission
        const auditLogData = Object.entries(RequiredData)
            .filter(([field, value]) => value && value !== '')
            .map(([field, value]) => ({
                field,
                value,
            }));
        const Data = { auditLogData, Username };
        const refResponse = await AddPayorData({ params: RequiredData });
        const refData = JSON.parse(refResponse);
        const Refid = refData.success ? refData.Refid : refData.message;

        // Add audit log entry after successful submission
        await AddAuditLogData({ ...Data,Refid, action: "Payor added" });

        Swal.fire({
          title: 'Onder Management',
          icon: refData.success ? 'success' : 'error',
          text: refData.success ? 'Onder Added Successfully!' : Refid,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        setIsSubmitting(false);
        if (refData.success) {
          navigate(`/onder`);
        }
      } catch (error) {
        console.error('Error adding payor:', error);
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };


  return (
    <>
      <Breadcrumbs mainTitle='Onder Management' parent='Onder Management' title='Onder Management' to='/onder' />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
            <h3>Add Onderurl</h3>
          </CardHeader>
          <CardBody className='py-2 my-25'>
            <Formik
              initialValues={{
                Appname: '',
                Customkey: '',
                Secret: '',
                Requesturl: '',
                Callurl: '',
              }}
              onSubmit={(values) => {
                handleSubmit(values);
                setFormSubmit(true);
              }}
            >
              
                <Form className={`needs-validation custom-input ${formSubmit ? 'was-validated' : ''}`} noValidate>
                  <Row className="g-3">
                    <Col md={4} xs={12}>
                      <Label>{Appname}</Label>
                      <Field name="Appname" type="text" className="form-control" placeholder=""  />
                    </Col>
                    <Col md={4} xs={12}>
                      <Label>{Customer}<span style={{ color: 'red' }}> * </span></Label>
                      <Field name="Customkey" type="text" className="form-control" placeholder="" required />
                      <div className="invalid-feedback">{KeyError}</div>
                      <div className="valid-feedback">{Verified}</div>
                    </Col>
                    <Col md={4} xs={12}>
                      <Label>{Customerse}<span style={{ color: 'red' }}> * </span></Label>
                      <Field name="Secret" type="text" className="form-control" placeholder="" required />
                      <div className="invalid-feedback">{CustomError}</div>
                      <div className="valid-feedback">{Verified}</div>
                    </Col>
                    <Col md={4} xs={12}>
                      <Label>{Request}<span style={{ color: 'red' }}> * </span></Label>
                      <Field name="Requesturl" type="text" className="form-control" placeholder="" required />
                      <div className="invalid-feedback">{RequestError}</div>
                      <div className="valid-feedback">{Verified}</div>
                    </Col>
                    <Col md={4} xs={12}>
                      <Label>{Callback}</Label>
                      <Field name="Callurl" type="text" className="form-control" placeholder=""  />
                    </Col>

                    <Col>
                      <div className="text-end" style={{ marginTop: "100px" }}>
                        <button type="submit" className="btn btn-success me-3">Submit</button>
                        <Link to='/payor'>
                          <button type="button" className="btn btn-danger">Cancel</button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default Addonder;
