import CryptoJS from 'crypto-js';

/** AES CBC 256 Encryption method */ 

interface EncryptionResult {
  data: string;
  s: string;
  iv: string;
}

export const AesEncrypt = (text: string): EncryptionResult | null => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let iv1 = '';
  let salt = '';

  // Generate a random IV
  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    iv1 += characters.charAt(randomIndex);
  }

  // Generate a random salt
  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    salt += characters.charAt(randomIndex);
  }

  // Generate key (base64 encoded)
  const baseKey = "2dddd8e3e3319895031f098ed590b3d6";
  const base64key = btoa(baseKey);
  const key = CryptoJS.enc.Base64.parse(base64key);

  // Convert IV to Base64
  const ivBase64 = btoa(iv1);
  const iv = CryptoJS.enc.Base64.parse(ivBase64);

  try {
    // Convert text to UTF-8 encoded data
    const data = CryptoJS.enc.Utf8.parse(text);

    // Encrypt data using AES CBC mode
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      blockSize: 256,
    });

    // Convert encrypted data to Base64
    const encryptedBase64 = encrypted.toString();

    // Return the encryption result
    return {
      data: encryptedBase64,
      s: salt,
      iv: iv1,
    };
  } catch (error) {
    console.error('Encryption error:', error);
    return null;
  }
};

/** AES CBC 256 Decryption method */ 
interface DecryptionParams {
  data: string;
  iv: string;
}

export const AesDecrypt = (params: DecryptionParams): any | null => {
  const { data: cipherText, iv: ivBase64 } = params;
  const baseKey = "2dddd8e3e3319895031f098ed590b3d6";
  const base64key = btoa(baseKey);
  const key = CryptoJS.enc.Base64.parse(base64key);
  const iv = CryptoJS.enc.Base64.parse(ivBase64);

  try {
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText),
    });

    const decrypted = CryptoJS.AES.decrypt(
      cipherParams,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText);
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};
