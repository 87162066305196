import LoginSimple from "../Pages/Others/Authentication/LoginSimple/LoginSimple";
import LoginWithBackGroundImage from "../Pages/Others/Authentication/LoginWithBackGroundImage/LoginWithBackGroundImage";
import LoginWithImageTwo from "../Pages/Others/Authentication/LoginWithImageTwo/LoginWithImageTwo";
import LoginWithSweetAlert from "../Pages/Others/Authentication/LoginWithSweetAlert/LoginWithSweetAlert";
import LoginWithTooltip from "../Pages/Others/Authentication/LoginWithTooltip/LoginWithTooltip";
import LoginWithValidation from "../Pages/Others/Authentication/LoginWithValidation/LoginWithValidation";
import Maintenance from "../Pages/Others/Authentication/Maintenance/Maintenance";
import RegisterSimple from "../Pages/Others/Authentication/RegisterSimple/RegisterSimple";
import RegisterWithBgImage from "../Pages/Others/Authentication/RegisterWithBgImage/RegisterWithBgImage";
import RegisterWithImageTwo from "../Pages/Others/Authentication/RegisterWithImageTwo/RegisterWithImageTwo";
import UnlockUser from "../Pages/Others/Authentication/UnlockUser/UnlockUser";
import ComingSoonSimple from "../Pages/Others/ComingSoon/ComingSoonSimple/ComingSoonSimple";
import ComingSoonWithBgImage from "../Pages/Others/ComingSoon/ComingSoonWithBgImage/ComingSoonWithBgImage";
import ComingWithBgVideo from "../Pages/Others/ComingSoon/ComingWithBgVideo/ComingWithBgVideo";


import ForgetPassword from "../Pages/Authentication/ForgetPassword";
import Pin from "../Pages/Authentication/Pin";
import ResetPassword from "../Pages/Authentication/ResetPassword";



export const authRoutes = [
 //Error


//  ComingSoon
 { path: `/pages/comingsoon/comingsimple`, Component: <ComingSoonSimple/> },
 { path: `/pages/comingsoon/comingwithbgvideo`, Component: <ComingWithBgVideo/> },
 { path: `/pages/comingsoon/comingwithbgimage`, Component: <ComingSoonWithBgImage/> },
 

//  // Auth Pages
 { path: `/pages/authentication/loginsimple`, Component: <LoginSimple /> },
 { path: `/pages/authentication/loginwithbgimage`, Component: <LoginWithBackGroundImage /> },
 { path: `/pages/authentication/loginwithimagetwo`, Component: <LoginWithImageTwo /> },
 { path: `/pages/authentication/loginwithvalidation`, Component: <LoginWithValidation /> },
 { path: `/pages/authentication/loginwithtooltip`, Component: <LoginWithTooltip /> },
 { path: `/pages/authentication/loginwithsweetalert`, Component: <LoginWithSweetAlert /> },
 { path: `/pages/authentication/registersimple`, Component: <RegisterSimple /> },
 { path: `/pages/authentication/registerwithbgimage`, Component: <RegisterWithBgImage /> },
 { path: `/pages/authentication/registerwithimagetwo`, Component: <RegisterWithImageTwo /> },
 { path: `/pages/authentication/unlockuser`, Component:<UnlockUser />},
 { path: `/pages/authentication/maintenance`, Component: <Maintenance />},


 { path: `/authentication/pin`, Component: <Pin />},
 { path: `/authentication/forgetpassword`, Component:<ForgetPassword />},
 { path: `/authentication/resetpassword`, Component: <ResetPassword />},

];