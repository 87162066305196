// ** React Imports
import { Fragment } from 'react'

// ** Custom Components

import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
// ** Table Import
import Activeuser from './Activeuserlist'

const Userlog = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Active User list' title='Active User list' />
        <div className='card-datatable app-user-list '>
          <Activeuser />
        </div>
    </Fragment>
  )
}

export default Userlog
