// ** Custom Components
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";

import React, { Fragment, useState } from "react";

import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import Vendor from "./VendorAdmin";
import Team from "./Team";
import Vendoruser from "./Vendor";

const VendorIndex: React.FC = () => {
  // Define the initial state outside the component
  let initialActiveTab: string;
  if (localStorage.UserType === "MOS") {
    initialActiveTab = '1';
  } else {
    initialActiveTab = '2';
  }

  // Inside your component
  const [activeTab, setActiveTab] = useState<string>(initialActiveTab);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Vendor Management' title='Vendor Management' />
      <Card>
        <CardBody>
          <div className="my-nav">
            <Nav tabs className="nav-fill">
              {localStorage.UserType === "MOS" && (
                <NavItem className="tabcont">
                  <NavLink
                    className={classnames("nav-link", { active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Vendors
                  </NavLink>
                </NavItem>
              )}
              <NavItem className="tabcont">
                {localStorage.UserType === "MOS" && (
                  <NavLink
                    className={classnames("nav-link", { active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Teams
                  </NavLink>
                )}
              </NavItem>
              <NavItem className="tabcont">
                {localStorage.UserType === "MOS" && (
                  <NavLink
                    className={classnames("nav-link", { active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    Team  Users
                  </NavLink>
                )}
              </NavItem>
            </Nav>
          </div>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Vendor />
              </TabPane>
              <TabPane tabId="2">
                <Team />
              </TabPane>
              <TabPane tabId="3">
                <Vendoruser />
              </TabPane>
            </TabContent>
          </CardBody>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default VendorIndex;
