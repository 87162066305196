import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import webservice from '../../../../Service/webservice';
import { AesDecrypt } from '../../../../Service/crypto';
import {AesEncrypt } from '../../../../Service/crypto'
interface Document {
  CompanyName?: string;
  Website?: string;
  Country?: string;
  UserName?:string;
}


interface GetDataParams {
  UserType?:string;
  q?: string;
  perPage?: number;
  page?: number;
  status?: string | null;
  sort?: string;
  sortColumn?: keyof Document;
}


interface GetDataResponse {
  params: GetDataParams;
  data: Document[];
  allData: Document[];
  totalPages: number;
  no_Data: string | [];
}



const paginateArray = (array: Document[], perPage: number, page: number): Document[] =>
  array.slice((page - 1) * perPage, page * perPage);

export const getData = createAsyncThunk<GetDataResponse, GetDataParams>(
  'ClientAdmin/getData',
  async (params) => {
    // const Id = UserType === 'Client' ? localStorage.getItem("ClientId") : localStorage.getItem("id");

    const Id = localStorage.id || '';
    const UserType = localStorage.UserType;
    // const Id = UserType === 'MOS' ? localStorage.id : localStorage.VendorId;
    let dataParams: Record<string, any> = {};

    const { q = '', perPage = 10, page = 1, sort, sortColumn } = params;

    dataParams['UserType'] = UserType;
    dataParams['UserID'] = Id;
    dataParams['perPage'] = perPage;
    dataParams['page'] = page;
    dataParams['sort'] = sort;
    dataParams['sortColumn'] = sortColumn;
    dataParams['q'] = q;

    // Convert dataParams to a JSON string before encryption
    const dataParamsString = JSON.stringify(dataParams);
    const EncParams = AesEncrypt(dataParamsString);

    try {
      const response = await fetch(webservice + 'api/ClientManagement/GetClient', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      const jsonResponse = await response.json();
      const Response = AesDecrypt(jsonResponse)
      const Decryptdata = Response.response_data




      if (Decryptdata.length === 0 || Decryptdata === 'There is no Record Found') {
        return {
          params,
          data: [],
          allData: [],
          totalPages: 0,
          no_Data: 'There is no Record Found',
        };
      } else {
        const totalCount = Response.count;
        const res = Response.response_data;

        return {
          params,
          data: res.length <= perPage ? res : paginateArray(res, perPage, page),
          allData: res,
          totalPages: totalCount,
          no_Data: [],
        };
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
)

export const Get_permission = createAsyncThunk(
  'Clientmanagement/Get_permission',
  async (data: any, { dispatch, getState }) => {
    const response = await fetch(webservice + 'api/Get_permission', {
      method: 'POST',
      body: JSON.stringify({ data }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => response.json());
    return response;
  }
);



export const get_vendorname = createAsyncThunk('Clientmanagement/vendorname', async () => {
  const response = await fetch(webservice + 'api/clientmanagement/vendorname', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
  .then((response1) => response1.json())
  
  const TempData = AesDecrypt(response)
  return TempData;
})


// ---------------------------------------------------------------------------------------------------------------------------------------

interface UserDocument {
  firstName: string;
  lastName: string;
  emailId: string;
  UserName: string;
  UserType: string;
  Role: string;
  ActiveStatus: string;
  CompanyName: string;
}
interface GetUserDataParams {
  Id?:string;
  UserType?:string;
  q?: string;
  perPage?: number;
  page?: number;
  status?: string | null;
  sort?: string;
  sortColumn?: keyof UserDocument; // Updated to keyof Document
}
interface GetUserDataResponse {
  params: GetUserDataParams;
  data: UserDocument[];
  allData: UserDocument[];
  totalPages: number;
  no_Data: string | [];
}


// Helper function for pagination
const paginateUserArray = (array: UserDocument[], perPage: number, page: number): UserDocument[] =>
  array.slice((page - 1) * perPage, page * perPage);

export const getUserData = createAsyncThunk<GetUserDataResponse, GetUserDataParams>(
  'DocumentUpload/getData',
  async (params) => {
    const UserType = localStorage.getItem("UserType") || '';
    const Id = UserType === 'Client' ? localStorage.getItem("ClientId") : localStorage.getItem("id");
    
    let dataParams: Record<string, any> = {};

    const { q = '', perPage = 10, page = 1, sort, sortColumn } = params;

    dataParams['UserType'] = UserType;
    dataParams['UserID'] = Id;
    dataParams['perPage'] = perPage;
    dataParams['page'] = page;
    dataParams['sort'] = sort;
    dataParams['sortColumn'] = sortColumn;
    dataParams['q'] = q;

    // Convert dataParams to a JSON string before encryption
    const dataParamsString = JSON.stringify(dataParams);
    const EncParams = AesEncrypt(dataParamsString);

    try {
      const response = await fetch(webservice + 'api/ClientManagement/getClientUsers', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      const jsonResponse = await response.json();
      const Response = AesDecrypt(jsonResponse)
      const Decryptdata = Response.response_data

      
      
      
      if (Decryptdata.length === 0 || Decryptdata === 'There is no Record Found') {
        return {
          params,
          data: [],
          allData: [],
          totalPages: 0,
          no_Data: 'There is no Record Found',
        };
      } else {
        const totalCount = Response.count;
        const res = Response.response_data;

        return {
          params,
          data: res.length <= perPage ? res : paginateUserArray(res, perPage, page),
          allData: res,
          totalPages: totalCount,
          no_Data: [],
        };
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
)



interface ClientAdminState {
  data: Document[];
  total: number;
  params: GetDataParams;
  allData: Document[];
  no_Data: string | [];
}

const initialStates: ClientAdminState = {
  data: [],
  total: 0,
  params: {},
  allData: [],
  no_Data: '',
};

export const ClientAdminSlice = createSlice({
  name: 'ClientAdmin',
  initialState:initialStates,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action: PayloadAction<GetDataResponse>) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalPages;
      state.params = action.payload.params;
      state.no_Data = action.payload.no_Data;
    });
  },
});


interface ClientManagementState {
  data: UserDocument[];
  total: number;
  params: GetUserDataParams;
  allData: UserDocument[];
  no_Data: string | [];
}

const initialState: ClientManagementState = {
  data: [],
  total: 0,
  params: {},
  allData: [],
  no_Data: '',
};

export const ClientManagementSlice = createSlice({
  name: 'ClientManagement',
  initialState:initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, action: PayloadAction<GetUserDataResponse>) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalPages;
      state.params = action.payload.params;
      state.no_Data = action.payload.no_Data;
    });
  },
});

export const clientAdminReducer = ClientAdminSlice.reducer;
export const clientManagementReducer = ClientManagementSlice.reducer;
