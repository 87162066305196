import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, CardBody, CardHeader, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { Plus, X } from 'react-feather';
import webservice from '../../../Service/webservice';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';

import Swal from 'sweetalert2';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
const Addpayor: React.FC = () => {
  const [formSubmit, setFormSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const statusOptions = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'Inactive' }
  ];
  const Username = localStorage.getItem('UserName') || '';

  const AddPayorData = async (params: any) => {
 
    
    const Refid = uuidv4();
    const paramsS = params.params;
    const paramsObj = { ...paramsS, Refid };
    const EncParams = AesEncrypt(JSON.stringify(paramsObj));

    const ref = await fetch(webservice + 'api/Payor/addpayor', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(ref);
  };

  interface FormValues {
    Userid: string;
    PayorIDs: any;
    UserName: string;
    location:string;
    Features: any;
    Mobile: any;
    Activestatus: any;

  }

  const defaultValues: FormValues = {
    Userid: '',
    PayorIDs: [''],
    UserName: '',
    location:'',
    Features: [''],
    Mobile: [''],
    Activestatus: statusOptions[0]
  }

  const AddAuditLogData = async (data: any) => {
    
    data.Userid = localStorage.id;
    const ID = data.Refid;
    const EncParams = AesEncrypt(JSON.stringify({ Data: data, ID }));
    const response = await fetch(webservice + 'api/Payor/addAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

  const handleSubmit = async (data: any) => {

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    const RequiredData: { [key: string]: any } = {
      Userid: data.Userid,
      PayorIDs: data.PayorIDs,
      Username: data.UserName,
      location: data.location,
      Features: data.Features,
      Mobile: data.Mobile,
      Activestatus: data.Activestatus.value
    };

    if (Object.values(RequiredData).every(field => field && field.length > 0)) {
      try {
        // Log audit data before submission
        const auditLogData = Object.entries(RequiredData)
          .filter(([field, value]) => value && value !== '')
          .map(([field, value]) => ({
            field,
            value,
          }));
        const Data = { auditLogData, Username };
        const refResponse = await AddPayorData({ params: RequiredData });
        const refData = JSON.parse(refResponse);
        const Refid = refData.success ? refData.Refid : refData.message;

        // Add audit log entry after successful submission
        await AddAuditLogData({ ...Data, Refid, action: "Payor added" });

        Swal.fire({
          title: 'Payor Management',
          icon: refData.success ? 'success' : 'error',
          text: refData.success ? 'Payor Added Successfully!' : Refid,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        setIsSubmitting(false);
        if (refData.success) {
          navigate(`/payor`);
        }
      } catch (error) {
        console.error('Error adding payor:', error);
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };
  const validationSchema = Yup.object().shape({
    Userid: Yup.string().required('Payer ID is required'),
    UserName: Yup.string().required('Insurance Name is required'),
    // Email: Yup.string().email('Invalid email address').required('Email is required'),
    PayorIDs: Yup.array().of(Yup.string().required('AltPayer ID is required')),
    Features: Yup.array().of(Yup.string().required('Feature is required')),
    Mobile: Yup.array().of(Yup.string().required('Mobile no is required')),
    Activestatus: Yup.object().shape({
      value: Yup.string().required('Status is required')
    }).required('Status is required')
  });
  const handleNumbers = (event:any) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  return (
    <>
      <Breadcrumbs mainTitle='Payer Management' parent='Payer Management' title='Payer Management' to='/payor' />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
            <h3>Add Payer</h3>
          </CardHeader>
          <CardBody className='py-2 my-25'>
            <Formik
              initialValues={defaultValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
                setFormSubmit(true);
              }}
            >
              {({ setFieldValue }) => (
                <Form className={`needs-validation custom-input ${formSubmit ? 'was-validated' : ''}`} noValidate>
                  <Row className="g-3">
                    <Col md={4} xs={12}>
                      <Label>Payer Id<span style={{ color: 'red' }}>*</span></Label>
                      <Field name="Userid" type="text" className="form-control" placeholder="" required />
                      <ErrorMessage name="Userid" component="span" className="text-danger" />
                    </Col>
                    <Col md={4} xs={12}>
                      <Label>Insurance Name<span style={{ color: 'red' }}>*</span></Label>
                      <Field name="UserName" type="text" className="form-control" placeholder="" required />
                      <ErrorMessage name="UserName" component="span" className="text-danger" />
                    </Col>
                   
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label>Status <span style={{ color: 'red' }}>*</span></Label>
                        <Select
                          name="Activestatus"
                          options={statusOptions}
                          defaultValue={statusOptions[0]}
                          onChange={option => setFieldValue('Activestatus', option)}
                          className="react-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage name="Activestatus" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>

                    <Row>
                      <Col md={3} xs={12}>
                        <Label>Location</Label>
                        <Field name="location" type="text" className="form-control" placeholder="" required />
                        {/* <div className="invalid-feedback">{EmailError}</div> */}
                        {/* <ErrorMessage name="location" component="span" className="text-danger" /> */}
                      </Col>

                      <Col md={3} xs={12}>
                        <FieldArray name="Mobile">
                          {({ push, remove, form }) => (
                            <>
                              {form.values.Mobile.map((feature: string, index: number) => (
                                <Row key={index} className="mb-3">
                                  <Col md={12} xs={12}>
                                    <Label>Mobile No <span style={{ color: 'red' }}>*</span></Label>
                                    <div className="d-flex align-items-center">
                                      <Field name={`Mobile[${index}]`} type="text" className="form-control" onInput={handleNumbers} placeholder=""  />
                                      <Plus size={16} color="green" className="ms-2 cursor-pointer" onClick={() => push('')} />
                                      {form.values.Mobile.length > 1 && (
                                        <X size={16} color="red" className="ms-2 cursor-pointer" onClick={() => remove(index)} />
                                      )}
                                    </div>
                                    <ErrorMessage name={`Mobile[${index}]`} component="span" className="text-danger" />
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Col>

                      <Col md={3} xs={12}>
                        <FieldArray name="PayorIDs">
                          {({ push, remove, form }) => (
                            <>
                              {form.values.PayorIDs.map((payorID: string, index: number) => (
                                <Row key={index} className="mb-3">
                                  <Col md={12} xs={12}>
                                    <Label>Alt Payer Id<span style={{ color: 'red' }}>*</span></Label>
                                    <div className="d-flex align-items-center">
                                      <Field name={`PayorIDs[${index}]`} type="text" className="form-control" placeholder=""  />
                                      <Plus size={16} color="green" className="ms-2 cursor-pointer" onClick={() => push('')} />
                                      {form.values.PayorIDs.length > 1 && (
                                        <X size={16} color="red" className="ms-2 cursor-pointer" onClick={() => remove(index)} />
                                      )}
                                    </div>
                                    <ErrorMessage name={`PayorIDs[${index}]`} component="span" className="text-danger" />
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Col>

                      <Col md={3} xs={12}>
                        <FieldArray name="Features">
                          {({ push, remove, form }) => (
                            <>
                              {form.values.Features.map((feature: string, index: number) => (
                                <Row key={index} className="mb-3">
                                  <Col md={12} xs={12}>
                                    <Label>Features<span style={{ color: 'red' }}>*</span></Label>
                                    <div className="d-flex align-items-center">
                                      <Field name={`Features[${index}]`} type="text" className="form-control" placeholder=""  />
                                      <Plus size={16} color="green" className=" cursor-pointer" onClick={() => push('')} />
                                      {form.values.Features.length > 1 && (
                                        <X size={16} color="red" className="ms-2 cursor-pointer" onClick={() => remove(index)} />
                                      )}
                                    </div>
                                    <ErrorMessage name={`Features[${index}]`} component="span" className="text-danger" />
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

               
                    <Col>
                      <div className="text-end" style={{ marginTop: "100px" }}>
                        <button type="submit" className="btn btn-success me-3">Submit</button>
                        <Link to='/payor'>
                          <button type="button" className="btn btn-danger">Cancel</button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default Addpayor;
