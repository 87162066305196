import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, CardBody, CardHeader, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { Formik, Form, Field, FieldArray, FormikHelpers, ErrorMessage } from 'formik';
import { Plus, X } from 'react-feather';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';

import Swal from 'sweetalert2';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import webservice from '../../../Service/webservice';
import * as Yup from 'yup';

const Editinsurance: React.FC = () => {
  const [formSubmit, setFormSubmit] = useState(false);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    Payername: '',
    PayerID: '',
    
  });
  const [id, setId] = useState<string>(''); // State to hold the id
  const [refId, setRefId] = useState('');

  const Username = localStorage.getItem('UserName') || '';

  const updatedata = async (data: any, id: string) => {
    const EncParams = AesEncrypt(JSON.stringify({ data, id }));
    const res = await fetch(webservice + 'api/Insurance/edit', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return res;
  };

  const handleSubmit = async (data: any, { setSubmitting }: FormikHelpers<any>) => {
    if (formSubmit) {
      return;
    }
    setFormSubmit(true);

    let updatedFields: any[] = [];

    for (const key in data) {
      if (initialValues && initialValues[key as keyof typeof initialValues] !== data[key as keyof typeof initialValues]) {
        const beforeValue = initialValues[key as keyof typeof initialValues];
        const afterValue = data[key as keyof typeof initialValues];

        if (beforeValue !== null && afterValue !== null) {
          updatedFields.push({
            field: key,
            before: beforeValue,
            after: afterValue,
          });
        }
      }
    }

   // Transform Activestatus to send only the value
    const response = await updatedata(data, id);
    const decryptedResponse = JSON.parse(AesDecrypt(response));

    if (decryptedResponse.success) {
      await updateAuditlog({ action: 'update', data: updatedFields, refId, Username });

      Swal.fire({
        title: 'Payer Mapping Management',
        icon: 'success',
        text: 'Payer Mapping Update Successfully!',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });
      navigate(`/insurance`);
    } else {
      Swal.fire({
        title: 'Payer Mapping Management',
        icon: 'error',
        text: 'Failed to update Payer Mapping!',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    }
    setFormSubmit(false);
    setSubmitting(false);
  };

  const updateAuditlog = async (data: any) => {
    data.Userid = localStorage.id;
    data.ParentUserId = localStorage.ParentUserId;
    const EncParams = AesEncrypt(JSON.stringify(data));

    const response = await fetch(webservice + 'api/Insurance/editaudit', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

  const getInsuranceData = async (id: string) => {
    const EncParams = AesEncrypt(id);
    const response = await fetch(webservice + 'api/Insurance/getinsurancedata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    
    return TempData;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = localStorage.getItem('InsuranceEdit');
      if (data != null) {
        const res = await getInsuranceData(data);
        if (res.length > 0) {
          setId(res[0].id);
          setRefId(res[0].RefId); // Set the id state
          setInitialValues({
            Payername: res[0].Payername,
            PayerID: res[0].PayerID,
          });
        }
      }
    };

    fetchData();
  }, []);

  const handleNumbers = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }
  const validationSchema = Yup.object().shape({
    PayerID: Yup.string().required('Payer ID is required'),
    Payername: Yup.string().required('Payer Name is required'),
    // Email: Yup.string().email('Invalid email address').required('Email is required'),
    
  });
  return (
    <>
      <Breadcrumbs mainTitle='Payer Mapping Management' parent='Payer Mapping Management' title='Payer Mapping Management' to='/insurance' />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
            <h3>Edit Payer Mapping</h3>
          </CardHeader>
          <CardBody className='py-2 my-25'>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                handleSubmit(values, actions);
              }}
            >
              
                <Form className={`needs-validation custom-input ${formSubmit ? 'was-validated' : ''}`} noValidate>
                  <Row className="g-3">
                    <Col md={4} xs={12}>
                      <Label>Payer Id<span style={{ color: 'red' }}>*</span></Label>
                      <Field name="PayerID" type="text" className="form-control" placeholder="" required />
                      <ErrorMessage name="PayerID" component="span" className="text-danger" />
                    </Col>
                    <Col md={4} xs={12}>
                      <Label>Payer Name<span style={{ color: 'red' }}>*</span></Label>
                      <Field name="Payername" type="text" className="form-control" placeholder="" required />
                      <ErrorMessage name="Payername" component="span" className="text-danger" />
                    </Col>
                    <Col>
                      <div className="text-end" style={{ marginTop: "100px" }}>
                        <button type="submit" className="btn btn-success me-3">Submit</button>
                        <Link to='/insurance'>
                          <button type="button" className="btn btn-danger">Cancel</button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
    
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default Editinsurance;
