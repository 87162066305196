import { useState } from 'react'
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../ReaduxToolkit/Hooks';
import { sendMessageAsync, createNewChat, updateChat, setSelectedUser } from '../../../../ReaduxToolkit/Reducer/ChatSlice';
import { Send } from 'react-feather';
import axios from "axios";
import { setAllMembers, setChats } from "../../../../ReaduxToolkit/Reducer/ChatSlice";

import webservice from '../../../../Service/webservice';

interface AllMembersType {
  id: any;
  name: string;
  thumb: string;
  status: string;
  lastSeenDate: string;
  online?: boolean;
  typing?: boolean;
  time: string;
  reply: string;
}

const SendChat = () => {
  const [messageInput, setMessageInput] = useState("");
  const { allMembers, chats, selectedUser, currentUser } = useAppSelector((state) => state.chatData);
  const dispatch = useAppDispatch();

  const getChatData = async () => {
    const currentUserId = selectedUser.id;
    const oneSelect = allMembers.find((x: AllMembersType) => x.id === currentUserId);

    try {
      await axios.get(webservice + 'api/Chat/getUsersData').then((resp) => {
        dispatch(setChats(resp.data));
        dispatch(setSelectedUser(oneSelect));
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleMessageChange = (message: string) => {
    setMessageInput(message);
  };



  const handleMessagePress = () => {
    var container = document.getElementsByClassName("chat-history")[0];
    setTimeout(function () {
      container?.scrollBy({ top: 200, behavior: "smooth" });
    }, 310);

    let currentUserId = currentUser.id;
    let selectedUserId = selectedUser.id;

    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const time = hours + ':' + minutes + ' ' + ampm;

    const newMessage = {
      sender: currentUserId,
      time: time,
      text: messageInput,
    };

    let chat = chats.find(
      (chat: any) => chat.users.includes(currentUserId) && chat.users.includes(selectedUserId)
    );

    if (messageInput.length > 0) {
      if (chat && chat.messages.length === 0) {
        const updatedChat = {
          ...chat,
          messages: newMessage,
        };

        dispatch(createNewChat(updatedChat)).then((res) => {
          if (res.payload) {
            const updatedChats = chats.map((existingChat: any) =>
              existingChat.id === chat.id
                ? { ...existingChat, id: res.payload }
                : existingChat
            );

            dispatch(sendMessageAsync({ currentUserId, selectedUserId, messageInput, chats: updatedChats }));
            setMessageInput("");
            getChatData();
          }
        });
      } else if (chat) {
        const updatedChat = {
          ...chat,
          messages: newMessage,
        };

        dispatch(updateChat(updatedChat)).then((res1) => {
          if (res1.payload) {
            const updatedChats = chats.map((existingChat: any) =>
              existingChat.id === chat.id
                ? { ...existingChat, id: res1.payload }
                : existingChat
            );

            dispatch(sendMessageAsync({ currentUserId, selectedUserId, messageInput, chats: updatedChats }));
            setMessageInput("");
            getChatData();
          }
        });
      }
    }
  };


  return (
    <div className="chat-message clearfix pe-5">
      <Row>
        <Col xl="12" className="d-flex">
          <InputGroup className="text-box">
            <Input type="text" className="input-txt-bx" placeholder="Type a message......" value={messageInput} onChange={(e) => handleMessageChange(e.target.value)} />
            <Button className="input-group-text" color="primary" onClick={handleMessagePress}><Send size={16} /></Button>
          </InputGroup>
        </Col>
      </Row>
    </div>
  )
}

export default SendChat