import { useEffect } from "react";
import { Link } from "react-router-dom";
import { dynamicImage } from "../../Service";
import SidebarMain, { PropsTypes } from "./SidebarMain";
import { useAppSelector } from "../../ReaduxToolkit/Hooks";
import { AesDecrypt } from "../../Service/crypto";
import webservice from "../../Service/webservice";
import { Image } from "../../AbstractElements";

const Sidebar = ({ setOpen, open, onCloseSidebar }: PropsTypes) => {
    const { toggleSidebar } = useAppSelector((state) => state.layout);

    useEffect(() => {
        const Image = localStorage.id;
        if (Image) { 
            fetch(webservice + "api/profileUpload/image", {
                method: "POST",
                body: JSON.stringify({ Image }), 
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
            .then((result) => result.json())
            .then((res) => {
                const resp = AesDecrypt(res);
                localStorage.setItem("Avatar", resp.Avatar);
            })
            .catch((error) => {
                console.error('Error fetching profile image:', error);
            });
        }
    }, []);

    return (
        <div className={`sidebar-wrapper ${open ? "sidebar-default" : ""} ${toggleSidebar ? "close_icon" : ""}`} >
        <div>
            <div >
                <Link to={`/dashboard`}>
                    <Image style={{ width: "100%" ,marginTop:"30%"}}
                            src={dynamicImage("logo/logo.png")} alt="Logo"
                        />
                    </Link>
                    <div className="back-btn">
                        <i className="fa fa-angle-left"></i>
                    </div>
                </div>
                <SidebarMain setOpen={setOpen} open={open} onCloseSidebar={onCloseSidebar} />
            </div>
        </div>
    );
};

export default Sidebar;
