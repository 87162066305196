import { UL } from "../../../AbstractElements"
import SearchInput from "../SearchInput/SearchInput"
import UserProfile from "../UserProfile/UserProfile"

const RightHeader = () => {
  return (
    <div className="nav-right col-auto pull-right right-header p-0 ms-auto">
      <UL className="nav-menus flex-row simple-list">
        <SearchInput />
        <UserProfile />
      </UL>
    </div>
  )
}

export default RightHeader