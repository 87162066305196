const ProfileName = () => {
  const Username = localStorage.UserName;
  return (
    <div>
      <h3 style={{marginTop:"3%"}}>{Username}</h3>
    </div>
  );
};

export default ProfileName;
