import React, { useEffect, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { AesEncrypt } from '../../Service/crypto';
import  webservice from '../../Service/webservice'
import { useDispatch } from 'react-redux';
interface FormData {
  pin: string;
}

const defaultValues: FormData = {
  pin: "",
};
interface NavigatorConnection extends Navigator {
  connection?: {
    effectiveType?: string;
    downlink?: number;
  };
}


const Pin = () => {
  const navigate = useNavigate();
  const [showconfirmPassword, setShowconfirmPassword] = useState<boolean>(false);
  const { handleSubmit, control, setError, formState: { errors } } = useForm<FormData>({ defaultValues });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [networkSpeed, setNetworkSpeed] = useState<string | null>(null);
  const [ipData, setIpData] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [osName, setOsName] = useState("");
  const dispatch = useDispatch();


// Getting ip address start
useEffect(() => {
  const getIp = () => {
    fetch('https://api.ipregistry.co/?key=cimfhddjjvc4qu5h')
      .then((response) => response.json())
      .then((data) => {
        setIpData(data.ip);
        setBrowserName(data.user_agent.name);
        setOsName(data.user_agent.os.name);
        const Time =data.time_zone.id
        localStorage.setItem("timezone", Time);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  };
  getIp();
}, []);

// Setting user network speed start
useEffect(() => {
  const calculateNetworkSpeed = () => {
    const navigatorConnection = navigator as NavigatorConnection;
    if (navigatorConnection.connection) {
      const connection = navigatorConnection.connection;
      if (connection.effectiveType) {
        // You might want to use this information as well
      }
      if (connection.downlink) {
        const downlinkSpeed = (connection.downlink * 8).toFixed(2);
        setNetworkSpeed(downlinkSpeed + " Mbps");
      }
    }
  };
  calculateNetworkSpeed();
}, []);

const userloghistory = async (UserLoginHistory: any) => {

  
  const EncParams = AesEncrypt(JSON.stringify({ UserLoginHistory }));
  const response = await fetch(webservice + 'api/UserlogHistory/Userloginhistory_add', {
    method: 'POST',
    body: JSON.stringify({ EncParams }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  });
  return await response.json();
};


const Useractivelist = async (Useractivelist: any) => {

  const EncParams = AesEncrypt(JSON.stringify({ Useractivelist }));
  const response = await fetch(webservice + 'api/ActiveUserList/Useractivelist_add', {
    method: 'POST',
    body: JSON.stringify({ EncParams }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  });
  return await response.json();
};


const userId = localStorage.id;
const usertype = localStorage.UserType;
const userName = localStorage.UserName;

const onSubmit: SubmitHandler<FormData> = async (data) => {
  const storedPin = localStorage.getItem('Pin');

  if (data.pin !== storedPin) {
    setError("pin", { type: "manual", message: "Incorrect Pin" });
  } else {
    const username = userName;
    const userid = userId;
    const Usertype = usertype;
    const UserIPAddress = ipData;
    const userBrowser = browserName;
    const userOsName = osName;
    const usernetspeed = networkSpeed;

    try {
      const result = await userloghistory({
        username,
        Usertype,
        userid,
        UserIPAddress,
        userBrowser,
        userOsName,
        usernetspeed,
      });
      const data = await Useractivelist({
        username,
        Usertype,
        userid,
        UserIPAddress,
        userBrowser,
        userOsName,
        usernetspeed,
      });
    
      // Combine result and data into a single object
      const combinedPayload = {
        logHistory: result,
        activeList: data,
      };
    
      dispatch({ type: 'user/loghistory/fulfilled', payload: combinedPayload });
      localStorage.setItem('login', JSON.stringify(true));
      localStorage.removeItem('Pin');
      navigate(`/dashboard`);
    } catch (error) {
      console.error("Error logging user history:", error);
    }    
  }
};

  return (
    <div className="page-wrapper">
      <Container fluid className="p-0">
        <Row>
          <Col xs={12}>
            <div className="login-card login-dark">
              <div>
                <div className="login-main">
                  <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <Label check>
                      Enter your Pin:<span style={{ color: 'red' }}> * </span>
                    </Label>
                    <Controller
                      name="pin"
                      control={control}
                      render={({ field }) => (
                        <InputGroup>
                          <Input
                            type={showPassword ? "text" : "password"}
                            required
                            placeholder="*******"
                            invalid={errors.pin && true}
                            {...field}
                          />
                          <InputGroupText onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <EyeOff /> : <Eye />}
                          </InputGroupText>
                        </InputGroup>
                      )}
                      rules={{ required: 'Please enter your Pin' }}
                    />
                         {!errors.pin && (
                      <div className="show-hide">
                        <span onClick={() => setShowconfirmPassword(!showconfirmPassword)} className={!showconfirmPassword ? "show" : ""}/>
                      </div>)}
                      {errors.pin &&  <div className="text-danger">{errors.pin.message}</div>}
                      <div className="text-end mt-3">
                        <Button color="primary" className="btn-block w-100">Submit</Button>
                      </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Pin;
