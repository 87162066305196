import { Image, LI, UL } from "../../../../../AbstractElements";
import { useAppDispatch, useAppSelector } from "../../../../../ReaduxToolkit/Hooks";
import { changeChat, createNewChatAsync } from "../../../../../ReaduxToolkit/Reducer/ChatSlice";
import { dynamicImage } from "../../../../../Service";
import CurrentUser from "./CurrentUser";
import SearchChatList from "./SearchChatList";

import A from '../../../../../assets/Avatar/a.png';
import B from '../../../../../assets/Avatar/b.png';
import C from '../../../../../assets/Avatar/c.png';
import D from '../../../../../assets/Avatar/d.png';
import E from '../../../../../assets/Avatar/e.png';
import F from '../../../../../assets/Avatar/f.png';
import G from '../../../../../assets/Avatar/g.png';
import H from '../../../../../assets/Avatar/h.png';
import I from '../../../../../assets/Avatar/i.png';
import J from '../../../../../assets/Avatar/j.png';
import K from '../../../../../assets/Avatar/k.png';
import L from '../../../../../assets/Avatar/l.png';
import M from '../../../../../assets/Avatar/m.png';
import N from '../../../../../assets/Avatar/n.png';
import O from '../../../../../assets/Avatar/o.png';
import P from '../../../../../assets/Avatar/p.png';
import Q from '../../../../../assets/Avatar/q.png';
import R from '../../../../../assets/Avatar/r.png';
import S from '../../../../../assets/Avatar/s.png';
import T from '../../../../../assets/Avatar/t.png';
import U from '../../../../../assets/Avatar/u.png';
import V from '../../../../../assets/Avatar/v.png';
import W from '../../../../../assets/Avatar/w.png';
import X from '../../../../../assets/Avatar/x.png';
import Y from '../../../../../assets/Avatar/y.png';
import Z from '../../../../../assets/Avatar/z.png';


const avatarImages: { [key: string]: string } = {
  A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Y, Z
};


interface Message {
  sender: any;
  time: string;
  text: string;
}
 interface ChatProp {
  id: any;
  users: any[];
  lastMessageTime: string;
  messages: Message[];
}


const ChatStatus = () => {
  const { members, currentUser, selectedUser, chats } = useAppSelector((state) => state.chatData);
  const dispatch = useAppDispatch();
  var activeChat = currentUser.id;
  if (selectedUser != null) {activeChat = selectedUser.id};

  
  const changeChatClick = (selectedUserId: any) => {
    const currentUserId = currentUser.id;
    const currentChat = chats.find((x: ChatProp) => x.users.includes(currentUser.id) && x.users.includes(selectedUserId));
  
    if (currentChat) {
      dispatch(changeChat(selectedUserId));
    } else {
      dispatch(createNewChatAsync({currentUserId, selectedUserId, chats }));
    }
  };

  const ChatImg = (item: any) => {
    if (item.thumb) {
      return item.thumb;
    } else if (item.name) {
      const firstLetter = item.name.charAt(0).toUpperCase();
      if (avatarImages[firstLetter]) {
        return avatarImages[firstLetter];
      }
    }
    return ''; 
  };

  return (
    <div className="chat-box">
      <div className="chat-left-aside">
        <CurrentUser />
        <div className="people-list">
          <SearchChatList />
          {members && members.length > 0 ? (
            <UL className="simple-list"  style={{ overflowY: "auto", maxHeight: "60vh" }}>
              {members
                .filter((x: any) => x.id !== currentUser.id)
                .map((item: any, id: any) => (
                  <LI key={id} className={`clearfix ${activeChat === item.id ? "active" : ""} pointer`} onClick={() => changeChatClick(item.id)}>
                    <div className="d-flex"> 
                      <Image src={ChatImg(item)} className="rounded-circle user-image me-3" alt={"user"}  />
                      <div className={`status-circle ${item.online === true ? "online" : "offline"}`}></div>
                      <div className="about flex-grow-1">
                        <div className="name">{item.name}</div>
                        {/* <div className="status">{item.status}</div> */}
                      </div>
                    </div>
                  </LI>
                ))}
            </UL>
          ) : (
            "search not found"
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatStatus;
