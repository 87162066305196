// ** Custom Components
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";

import React, { Fragment, useState } from "react";

import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";


import Client from "./ClientAdmin";
import Clientuser from "./ClientManagement";

const ClientIndex: React.FC = () => {
  // Define the initial state outside the component
  let initialActiveTab: string;
  if (localStorage.UserType === "MOS") {
    initialActiveTab = '1';
  } else {
    initialActiveTab = '2';
  }

  // Inside your component
  const [activeTab, setActiveTab] = useState<string>(initialActiveTab);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Client Management'  title='Client Management'/>
      <Card>
        <CardBody>
          <div className="my-nav">
            <Nav tabs className="custom-nav-tabs">
              {localStorage.UserType === "MOS" && (
                <NavItem className="tabcont">
                  <NavLink
                    className={classnames("nav-link-custom", { active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Customer Details
                  </NavLink>
                </NavItem>
              )}
              <NavItem className="tabcont">
                {localStorage.UserType === "MOS" && (
                  <NavLink
                    className={classnames("nav-link-custom", { active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Client Users
                  </NavLink>
                )}
                {/* {localStorage.UserType !== "MOS" && (
                  <NavLink
                    className={classnames("nav-link-custom", { active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    User
                  </NavLink>
                )} */}
              </NavItem>
            </Nav>
          </div>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Client />
              </TabPane>
              <TabPane tabId="2">
                <Clientuser />
              </TabPane>
            </TabContent>
          </CardBody>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ClientIndex;
