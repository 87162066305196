import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Container, Input, Label } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import WindowsService from '../../../Service/WindowsService';
import WebService from '../../../Service/webservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Editor } from '@ckeditor/ckeditor5-core';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { asBlob } from 'html-docx-js-typescript';
import { useDispatch } from 'react-redux';
import Select, { SingleValue } from 'react-select';

interface IClientOption {
    label: string;
    value: string;
}

const NewTemplateCreation: React.FC = () => {
    const [editorData, setEditorData] = useState<string>('');
    const [decryptedData, setDecryptedData] = useState<any[]>([]);
    const [expandedSection, setExpandedSection] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [Clients, setClients] = useState<any[]>([]);
    const [selectedClientId, setSelectedClientId] = useState<string>('');

    const [selectedClientName, setSelectedClientName] = useState<string>('');

    const [selectedClient, setSelectedClient] = useState<any>(null);

    const [fileNameError, setFileNameError] = useState<string>('');
    const [clientError, setClientError] = useState<string>('');
    const editorRef = useRef<Editor | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [usertype, setUsertype] = useState<string | null>(localStorage.getItem('UserType'));
    const [vendorbasedclientOptions, setvendorbasedclientOptions] = useState<IClientOption[]>([]);
    const UserName = localStorage.UserName;
    const UserId = localStorage.id;


    

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (usertype !== 'MOS') {
            setSelectedClientName(localStorage.getItem('ClientName') || '');
            setSelectedClientId(localStorage.getItem('ClientId') || '');
        }
    }, [usertype]);

    const handleTemplatePartChange = (newContent: any) => {
        setEditorData(newContent);
    };

    const fetchData = async () => {
        const EncParams = AesEncrypt(UserId)
        try {
            const response = await fetch(WebService + "api/TemplateCreation/get_patient_details", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({ EncParams }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            const decryptedResult = AesDecrypt(result);
            if (decryptedResult.message === 'There is no Record Found') {
                setDecryptedData([]);
            } else {
                setDecryptedData(Object.entries(decryptedResult[0]));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const get_ClientRoleId = async () => {
        const response = await fetch(WebService + 'api/clientmanagement/get_client', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());
        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            const response = await get_ClientRoleId();
            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data._id,
            }));
            setClients(res);
        };
        fetchRoleId();
    }, [dispatch]);


    const get_companynamevendorbased = async () => {
        const EncParams = AesEncrypt(JSON.stringify({ id: localStorage.VendorId }));

        const response = await fetch(WebService + 'api/TemplateCreation/vendorbasedclientOptions', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };
    useEffect(() => {
        const vendorbasedfetchRoleId = async () => {
            const response = await get_companynamevendorbased();
            
            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data.id,
            }));
            setvendorbasedclientOptions(res);
        };

        vendorbasedfetchRoleId();
    }, []);




    const handleSubmit = async () => {
        let isValid = true;

        if (!fileName.trim()) {
            setFileNameError('Please enter a file name');
            isValid = false;
        } else {
            setFileNameError('');
        }

        if (usertype === 'MOS' && !selectedClient) {
            setClientError('Please select a client');
            isValid = false;
        } else {
            setClientError('');
        }
        if (!isValid) return;
        const hasExtension = fileName.endsWith(".docx");
        const Template_Name = hasExtension ? fileName : fileName + ".docx";

        asBlob(editorData, {}).then((data: any) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const formData = new FormData();
            formData.append("fileName", Template_Name);
            formData.append("username", UserName);
            formData.append("userId", UserId);
            if (usertype === 'MOS') {
                formData.append("clientName", selectedClient.label );
                formData.append("clientId", selectedClient.value);
            } else if (usertype === 'Vendor') {
                formData.append("clientName", selectedClient);
                formData.append("clientId",selectedClientId);
            } else {
                formData.append("clientName", selectedClientName);
                formData.append("clientId", selectedClientId);
            }
            formData.append("file", blob, "template.docx");
            setIsLoading(true); // Set loading state to true
            axios.post(WindowsService + "api/TemplateCreation/save_word", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },

            })
                .then((response) => {
                    const auditLogData = [
                        {
                            field: "File Name",
                            value: Template_Name,
                        },
                        {
                            field: "User Name",
                            value: localStorage.UserName,
                        },
                        ...(localStorage.UserType === "Client"
                            ? [{
                                field: "Client Name",
                                value: selectedClientName,
                            },]
                            : []),
                        ...(localStorage.UserType === "MOS"
                            ? [{
                                field: "Client Name",
                                value: selectedClient.label,
                            },]
                            : []),
                    ];
                    const Username = localStorage.UserName;
                    const Refid = response.data
                    const Data = { auditLogData, Username, Refid };

                    // const EncParams = AesEncrypt(JSON.stringify(Data));

                    axios.post(WebService + "api/TemplateCreation/addauditlog", {
                        method: 'POST',
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        body: Data, // No need for parentheses here
                    })
                        .then((response) => {
                          
                        });
                    setIsLoading(false); // Set loading state to false
                    // if (response.data === "File uploaded successfully.") {
                    Swal.fire({
                        title: "Insurance Verification Platform",
                        html: '<span style="color: green;">Template Created Successfully!</span>',
                        icon: "success",
                        customClass: {},
                        showClass: {
                            popup: "animate__animated animate__flipInX",
                        },
                        buttonsStyling: true,
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.value) {
                            navigate("/templateCreation");
                        }
                    });
                    // }
                })
                .catch((error) => {
                    setIsLoading(false); // Ensure loading state is turned off in case of an error
                    console.error('Error uploading file:', error);
                });
        });
    }

    const toggleSection = (sectionKey: string) => {
        if (expandedSection === sectionKey) {
            setExpandedSection(null);
        } else {
            setExpandedSection(sectionKey);
        }
    };

    const renderFieldNames = (sectionValue: any) => {
        const uniqueKeys = new Set<string>();
        const renderKeys = (keys: string[]) => {
            return keys.map((key) => {
                if (!uniqueKeys.has(key)) {
                    uniqueKeys.add(key);
                    return (
                        <li key={key}
                            draggable
                            className="section-title"
                            onDragStart={(e) => handleDragStart(e, key)}
                        >
                            <div className="field-name">
                                &#8226; {key}
                            </div>
                        </li>
                    );
                }
                return null;
            });
        };

        return (
            <ul>
                {sectionValue.map((item: any, index: number) => {
                    // Convert item object values to a concatenated string
                    const concatenatedString = Object.values(item).join('');
                    // Pass concatenated string as an array with one element to renderKeys
                    return (
                        <li key={index}>
                            <strong>{renderKeys([concatenatedString])}</strong>
                        </li>
                    );
                })}
            </ul>
        );
    };


    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);
        } else {
        
            setSelectedClient('');
            setSelectedClientId('');
        
        }
    }


    const handleDragStart = (event: React.DragEvent, data: string) => {
        const formattedData = `${data}:$${data}$`;
        event.dataTransfer.setData('text/plain', formattedData);
    };

    const handleDrop = (event: DragEvent, editor: Editor) => {
        event.preventDefault();
        const data = event.dataTransfer?.getData('text/plain');
        if (data) {
            const viewFragment = editor.data.processor.toView(data);
            const modelFragment = editor.data.toModel(viewFragment);
            editor.model.insertContent(modelFragment, editor.model.document.selection);
        }
    };

    const handleDragOver = (event: DragEvent) => {
        event.preventDefault();
    };

    const filterData = (data: any[], query: string) => {
        if (!query) return data;
        return data.filter(([key, value]) =>
            key.toLowerCase().includes(query.toLowerCase()) ||
            (typeof value === 'object' &&
                Object.keys(value).some((field) => field.toLowerCase().includes(query.toLowerCase())))
        );
    };

    const renderDataCard = () => {
        const filteredData = filterData(decryptedData, searchQuery).filter(([key]) => key !== '_id');

        return (
            <>
                <Breadcrumbs mainTitle='Create New Template' parent='Template Creation' title='Create New Template' to={`/templateCreation`} />
                <Container fluid>
                    <style>
                        {`
                        .ck-editor__editable {
                            min-height: 640px !important;
                            padding: 10px;
                        }
                        .ck-editor-card {
                            margin: 20px;
                            padding: 20px;
                            width: 100%;
                        }
                        .section-title {
                            cursor: pointer;
                            font-weight: bold;
                        }
                        .field-name {
                            margin-left: 20px;
                        }
                        .half-width {
                            width: 60%;
                        }
                        .flex-container {
                            display: flex;
                            width: 100%;
                        }
                        .scrollable-editor {
                            height: 730px; /* Adjust the height as needed */
                            overflow-y: auto;
                        }
                        .ck-editor__editable_inline {
                            height: 100%; /* Ensure the editor content takes the full height of its container */
                        }
                        .card-body ul {
                            padding-left: 20px; /* Moves array data slightly to the right */
                        }
                        .card-body li {
                            margin-bottom: 10px; /* Adds space between each value */
                        }
                        .loading-overlay {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: fixed;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.5);
                            z-index: 10000;
                        }
                        .circle {
                            width: 20px;
                            height: 20px;
                            margin: 5px;
                            border-radius: 50%;
                            background-color: #000;
                            animation: bounce 0.6s infinite alternate;
                        }
                        @keyframes bounce {
                            to {
                                transform: translateY(-50%);
                            }
                        }
                    `}
                    </style>
                    <Row>
                        <Col sm={5}>
                            {isLoading && (
                                <div >
                                    <div className="loading-overlay">
                                        <div id="page">
                                            <div id="container">
                                                <div id="ring"></div>
                                                <div id="ring"></div>
                                                <div id="ring"></div>
                                                <div id="ring"></div>
                                                <div id="h3" style={{ color: "white" }}>Loading...</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Card style={{ minHeight: '726px', boxShadow: '0 4px 8px rgba(0,0,0,0.3)', borderRadius: '8px', marginTop: '20px' }}>
                            <br />
                                <Row>
                                    <Col xs="12">
                                        <div className="d-flex justify-content-start mb-3">
                                            <Link to='/templateCreation'>
                                                <Button color="primary" style={{marginLeft:"10%"}} >
                                                    Back
                                                </Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '25px' }}>
                                    <Col md={7}>
                                        <Input
                                            type="text"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            style={{ maxWidth: '66%', marginLeft: "6%" }}  // Ensure it takes full width of the column
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Link to='/templateCreation/FieldMaster'>
                                            <Button color="primary" style={{ width: '100%' }}>
                                                Manage label
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <CardHeader>
                                    <h1><center>Drag & Drop the below fields</center></h1>
                                </CardHeader>
                                <CardBody className="card-body" style={{ marginLeft: "18%", height: "400px", overflowY: "auto" }}>
                                    {filteredData.map(([sectionKey, sectionValue]: [string, any], index: number) => (
                                        <React.Fragment key={index}>
                                            <CardTitle style={{ fontSize: '20px' }} tag="h4" className="section-title" onClick={() => toggleSection(sectionKey)}>
                                                <FontAwesomeIcon icon={expandedSection === sectionKey ? faCaretDown : faCaretRight} />
                                                &nbsp;
                                                {sectionKey}
                                            </CardTitle>
                                            &nbsp;
                                            {expandedSection === sectionKey && renderFieldNames(sectionValue)}
                                        </React.Fragment>
                                    ))}
                                </CardBody>
                                {localStorage.UserType === 'MOS' && (
                                    <Row>
                                        <Col md={5} style={{ marginLeft: "4%" }}>
                                            <Label check>Select Client<span style={{ color: 'red' }}> * </span></Label>
                                            <Select
                                                name="Client"
                                                options={Clients}
                                                onChange={(option: any) => { setSelectedClient(option); }}
                                                className="react-select"
                                                classNamePrefix="select"
                                            />
                                            {clientError && <div style={{ color: 'red', marginTop: '5px' }}>{clientError}</div>}
                                        </Col>
                                        <Col md={5}>
                                            <Label>File Name<span style={{ color: 'red' }}> * </span></Label>
                                            <Input
                                                type="text"
                                                placeholder="File Name"
                                                value={fileName}
                                                onChange={(e) => setFileName(e.target.value)}
                                                style={{ marginTop: "1%" }}
                                                onBlur={() => {
                                                    if (!fileName.trim()) {
                                                        setFileNameError('Please enter a file name');
                                                    } else {
                                                        setFileNameError('');
                                                    }
                                                }}
                                            />
                                            {fileNameError && <div style={{ color: 'red', marginTop: '5px' }}>{fileNameError}</div>}
                                        </Col>
                                        <Col md={2}></Col>

                                        
                                    </Row>
                                )}
                              
                                {localStorage.UserType === 'Vendor' && (
                                    <Row>
                                        <Col md={5} style={{ marginLeft: "4%" }}>
                                            <Label className="form-label" style={{ fontSize: "110%" }}>
                                                Select Client <span style={{ color: "red" }}> * </span>
                                            </Label>
                                            <Select
                                                isClearable
                                                options={vendorbasedclientOptions}
                                                // value={clientOptions.find((e)=>e.label===selectedClient)}
                                                classNamePrefix="select"
                                                placeholder=" Client Names "
                                                className="react-select"

                                                // {...field}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        width: "100%",
                                                        borderColor: "black",
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textAlign: "center",
                                                    }),
                                                }}
                                                value={selectedClient ? { value: selectedClientId, label: selectedClient } : ""}
                                                onChange={(selectedOption) => {
                                                    // field.onChange(selectedOption);
                                                    handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                }}
                                            />
                                            {clientError && <div style={{ color: 'red', marginTop: '5px' }}>{clientError}</div>}
                                        </Col>
                                        <Col md={5}>
                                            <Label>File Name<span style={{ color: 'red' }}> * </span></Label>
                                            <Input
                                                type="text"
                                                placeholder="File Name"
                                                value={fileName}
                                                onChange={(e) => setFileName(e.target.value)}
                                                style={{ marginTop: "1%" }}
                                                onBlur={() => {
                                                    if (!fileName.trim()) {
                                                        setFileNameError('Please enter a file name');
                                                    } else {
                                                        setFileNameError('');
                                                    }
                                                }}
                                            />
                                            {fileNameError && <div style={{ color: 'red', marginTop: '5px' }}>{fileNameError}</div>}
                                        </Col>

                                    </Row>
                                )}
                                

                                {localStorage.UserType !== 'MOS' && localStorage.UserType !== 'Vendor' && (
                                    <Row>
                                        <Col md={5}>
                                            <Label>File Name<span style={{ color: 'red' }}> * </span></Label>
                                            <Input
                                                type="text"
                                                placeholder="File Name"
                                                value={fileName}
                                                onChange={(e) => setFileName(e.target.value)}
                                                style={{ marginTop: "1%" }}
                                                onBlur={() => {
                                                    if (!fileName.trim()) {
                                                        setFileNameError('Please enter a file name');
                                                    } else {
                                                        setFileNameError('');
                                                    }
                                                }}
                                            />
                                            {fileNameError && <div style={{ color: 'red', marginTop: '5px' }}>{fileNameError}</div>}
                                        </Col>
                                        <Col md={6}></Col>
                                    </Row>
                                )}

                                
                                <br />
                                <br />
                                <br />
                                <Button
                                    onClick={handleSubmit}
                                    style={{ maxWidth: '25%', minHeight: '35px', position: 'relative', left: '72%', bottom: '32px' }}>
                                    Submit</Button>
                            </Card>
                        </Col>
                        <Col sm={7}>
                            <Card className="ck-editor-card scrollable-editor">
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={editorData}
                                    onChange={(event: any, editor: any) => {
                                        const newContent = editor.getData();
                                        handleTemplatePartChange(newContent);
                                    }}
                                    onReady={(editor: Editor) => {
                                        editorRef.current = editor;
                                        const viewDocument = editor.editing.view.document;
                                        viewDocument.on('dragover', (event: any) => {
                                            if (event.domEvent instanceof DragEvent) {
                                                handleDragOver(event.domEvent);
                                            }
                                        });
                                        viewDocument.on('drop', (event: any) => {
                                            if (event.domEvent instanceof DragEvent) {
                                                handleDrop(event.domEvent, editor);
                                            }
                                        });
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };
    return renderDataCard();
};

export default NewTemplateCreation;
