import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { H4, P } from '../../AbstractElements';
import { ForgotYourPassword, Signin } from '../../utils/Constant';
import { Link, useNavigate } from 'react-router-dom';
import MySwal from 'sweetalert2';
import { AesEncrypt } from '../../Service/crypto'; // Make sure to import this correctly
import webservice  from '../../Service/webservice'; // Make sure to import this correctly


type FormData = {
  loginEmail: string;
};

const ForgetPassword = () => {
  const { handleSubmit, control, setError, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      loginEmail: '',
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (Object.values(data).every((field) => field.length > 0)) {
      const EncParams = AesEncrypt(JSON.stringify(data));
      try {
        const response = await fetch(webservice+"api/Authentication/forgotPassword", {
          method: 'POST',
          body: JSON.stringify({ EncParams }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });
        const data1 = await response.json();
        if (data1 === "Reset password instructions have been sent to your email.") {
          return MySwal.fire({
            title: 'Insurance Verification Platform ',
            text: 'We just sent you an email with your username and a link to reset your password.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/login`);
            }
          });
        } else if (data1 === "Email not found") {
          setError("loginEmail", {
            type: 'manual',
            message: 'Please enter your registered email address.',
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      for (const key in data) {
        if (data[key as keyof FormData].length === 0) {
          setError(key as keyof FormData, {
            type: 'manual',
            message: 'Please enter your registered email address.',
          });
        }
      }
    }
  };

  return (
    <div className="page-wrapper">
      <Container fluid className="p-0">
        <Row>
          <Col xs={12}>
            <div className="login-card login-dark">
              <div>
                <div className="login-main">
                  <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                    <H4 className="text-center mt-3">{ForgotYourPassword}</H4>
                    <br />
                    <FormGroup>
                      <Label className="col-form-label">Enter your email :</Label>
                      <Controller
                        name="loginEmail"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="email"
                            required
                            placeholder="Enter Your Email"
                            invalid={errors.loginEmail && true}
                            {...field}
                          />
                        )}
                        rules={{ required: 'Please enter your email' }}
                      />
                      {errors.loginEmail &&  <div className="text-danger">{errors.loginEmail.message}</div>}
                      <div className="text-end mt-3">
                        <Button color="primary" className="btn-block w-100" type="submit">
                          Submit
                        </Button>
                      </div>
                    </FormGroup>
                    <P className="mt-4 mb-0 text-center">
                      {"Already have a password?"}
                      <Link className="ms-2" to={`/login`}>{Signin}</Link>
                    </P>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForgetPassword;
