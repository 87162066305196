import { useEffect, useState } from 'react';
import { Image } from '../../../../../AbstractElements'

import A from '../../../../../assets/Avatar/a.png';
import B from '../../../../../assets/Avatar/b.png';
import C from '../../../../../assets/Avatar/c.png';
import D from '../../../../../assets/Avatar/d.png';
import E from '../../../../../assets/Avatar/e.png';
import F from '../../../../../assets/Avatar/f.png';
import G from '../../../../../assets/Avatar/g.png';
import H from '../../../../../assets/Avatar/h.png';
import I from '../../../../../assets/Avatar/i.png';
import J from '../../../../../assets/Avatar/j.png';
import K from '../../../../../assets/Avatar/k.png';
import L from '../../../../../assets/Avatar/l.png';
import M from '../../../../../assets/Avatar/m.png';
import N from '../../../../../assets/Avatar/n.png';
import O from '../../../../../assets/Avatar/o.png';
import P from '../../../../../assets/Avatar/p.png';
import Q from '../../../../../assets/Avatar/q.png';
import R from '../../../../../assets/Avatar/r.png';
import S from '../../../../../assets/Avatar/s.png';
import T from '../../../../../assets/Avatar/t.png';
import U from '../../../../../assets/Avatar/u.png';
import V from '../../../../../assets/Avatar/v.png';
import W from '../../../../../assets/Avatar/w.png';
import X from '../../../../../assets/Avatar/x.png';
import Y from '../../../../../assets/Avatar/y.png';
import Z from '../../../../../assets/Avatar/z.png';


const avatarImages: { [key: string]: string } = {
  A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Y, Z
};


const CurrentUser = () => {

  const [avatar, setAvatar] = useState('');
  const Profile = localStorage.Avatar;
  const Username = localStorage.UserName;



  useEffect(() => {
    if (Profile) {
      setAvatar(Profile);
    } else if (Username) {
      const firstLetter = Username.charAt(0).toUpperCase();
      if (avatarImages[firstLetter]) {
        setAvatar(avatarImages[firstLetter]);
      }
    }
  }, [Profile, Username]);
  
  return (
    <div className="d-flex">
      <Image
        className="rounded-circle user-image"
        src={avatar}
        alt="user"
      />
      <div className="about">
        <div className="name f-w-600">{localStorage.UserName}</div>
        <div className="status">{'Status...'}</div>
      </div>
    </div>
  )
}

export default CurrentUser