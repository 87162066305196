import { Col, Row } from "reactstrap";
import { H5 } from "../../../../../AbstractElements";
// import { Email } from "../../../../../utils/Constant";
const Email = localStorage.emailId;
const ContactUs = localStorage.MobileNo;

const ProfileMail = () => {
  return (
    <Col sm={12} xl={4} className="order-sm-1 order-xl-0">
      <Row className="g-3">
        <Col md={6}>
          <div className="ttl-info text-start">
            <H5>
              <i className="fa fa-envelope" />
              &nbsp;&nbsp;&nbsp;{Email}
            </H5>
          </div>
        </Col>
        {/* <Col md={5}>
          <div className="ttl-info text-start">
            <H5>
            <i className="fa fa-phone" />
              &nbsp;&nbsp;&nbsp;{ContactUs}
            </H5>
          </div>
        </Col> */}
      </Row>
    </Col>
  );
};

export default ProfileMail;
