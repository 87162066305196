import React from 'react';
import { Link } from "react-router-dom";
import { LI, UL } from "../../../AbstractElements";
import { profilesMessage } from "../../../Data/Layout/Header/ProfilesData";
import FeatherIcons from "../../../utils/CommonSvgIcon/FeatherIcons";

const ProfileBox = () => {
  return (
    <UL className="profile-dropdown onhover-show-div">
      {profilesMessage.map((data, index) => (
        <LI key={index}>
          {data.link ? (
            <Link to={data.link}>
              {data.icon && <FeatherIcons iconName={data.icon} />} {/* Render FeatherIcons only if data.icon is defined */}
              <span>{data.name}</span>
            </Link>
          ) : (
            <>
              {data.component ? (
                <div>
                  {data.icon && <FeatherIcons iconName={data.icon} />} {/* Render FeatherIcons only if data.icon is defined */}
                  {data.component}
                </div>
              ) : (
                <div>
                  {data.icon && <FeatherIcons iconName={data.icon} />} {/* Render FeatherIcons only if data.icon is defined */}
                  <span>{data.name}</span>
                </div>
              )}
            </>
          )}
        </LI>
      ))}
    </UL>
  );
};

export default ProfileBox;
