import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import webservice from '../../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../../Service/crypto';

interface Document {
    Menu_Label?: string;
    Menu_type?: string;
    Menu_icon?: string;
    Menu_key?: string;
    Menu_order?: string;
    Menu_show?: string;
    Menu_url?: string;
}

interface GetDataParams {
    UserType?: string;
    q?: string;
    perPage?: number;
    page?: number;
    status?: string | null;
    sort?: string;
    sortColumn?: keyof Document; // Updated to keyof Document
}

interface GetDataResponse {
    params: GetDataParams;
    data: Document[];
    allData: Document[];
    totalPages: number;
    no_Data: string | [];
}

const paginateArray = (array: Document[], perPage: number, page: number): Document[] =>
    array.slice((page - 1) * perPage, page * perPage);

export const getData = createAsyncThunk<GetDataResponse, GetDataParams>(
    'History/getData',
    async (params) => {
        const UserType = localStorage.UserType;
        const Id = localStorage.id;

        let dataParams: Record<string, any> = {};

        const { q = '', perPage = 10, page = 1, sort, sortColumn } = params;

        dataParams['UserType'] = UserType;
        dataParams['UserID'] = Id;
        dataParams['perPage'] = perPage;
        dataParams['page'] = page;
        dataParams['sort'] = sort;
        dataParams['sortColumn'] = sortColumn;
        dataParams['q'] = q;

        // Convert dataParams to a JSON string before encryption
        const dataParamsString = JSON.stringify(dataParams);
        const EncParams = AesEncrypt(dataParamsString);

        try {
            const response = await fetch(webservice + 'api/UserlogHistory/userlog', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });

            const jsonResponse = await response.json();
            const Response = AesDecrypt(jsonResponse);
            const Decryptdata = Response.response_data;

            if (Decryptdata.length === 0 || Decryptdata === 'There is no Record Found') {
                return {
                    params,
                    data: [],
                    allData: [],
                    totalPages: 0,
                    no_Data: 'There is no Record Found',
                };
            } else {
                const totalCount = Response.count;
                const res = Response.response_data;

                return {
                    params,
                    data: res.length <= perPage ? res : paginateArray(res, perPage, page),
                    allData: res,
                    totalPages: totalCount,
                    no_Data: [],
                };
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
);

export const Get_permission = createAsyncThunk(
    'Clientmanagement/Get_permission',
    async (data: any, { dispatch, getState }) => {
        const response = await fetch(webservice + 'api/Get_permission', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());
        return response;
    }
);


interface AppMenuState {
    data: Document[];
    total: number;
    params: GetDataParams;
    allData: Document[];
    no_Data: string | [];
}

const initialState: AppMenuState = {
    data: [],
    total: 0,
    params: {},
    allData: [],
    no_Data: '',
};

export const HistorySlice = createSlice({
    name: 'History',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getData.fulfilled, (state, action: PayloadAction<GetDataResponse>) => {
            state.data = action.payload.data;
            state.allData = action.payload.allData;
            state.total = action.payload.totalPages;
            state.params = action.payload.params;
            state.no_Data = action.payload.no_Data;
        });
    },
});

export default HistorySlice.reducer;
