import React from 'react';
import LogoutPage from "../../../Pages/Authentication/Logout";

// Define profilesMessageType interface
export interface ProfilesMessageType {
    name?: string;
    icon?: "User" | "Mail" | "FileText" | "Settings";
    link?: string;
    component?: React.ReactNode;
}

// Define the profilesMessage array
export const profilesMessage: ProfilesMessageType[] = [
    {
        name: "Account",
        icon: "User",
        link: `/users/usersprofile`,
    },
    {
        name: "Change Password",
        icon: "Settings",
        link: `/users/changepassword`,
    },
    {
        component: React.createElement(LogoutPage),
    },
];
