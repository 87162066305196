import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import webservice from '../../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../../Service/crypto';
import moment from 'moment';
import { useParams } from 'react-router-dom';

interface Document {
  filename?: string;
  client_name?: string;
  template?: string;
  uploaded_date?: string;
  UserName?:string;
}


interface GetDataParams {
  id?:string;
  usertype?:string;
  q?: string;
  perPage?: number;
  page?: number;
  status?: string | null;
  sort?: string;
  sortColumn?: keyof Document;
}

interface GetDataResponse {
  params: GetDataParams;
  data: Document[];
  allData: Document[];
  totalPages: number;
  no_Data: string | [];
}

const paginateArray = (array: Document[], perPage: number, page: number): Document[] =>
  array.slice((page - 1) * perPage, page * perPage);

export const getData = createAsyncThunk<GetDataResponse, GetDataParams>(
  'BulkUpload/getData',
  async (params) => {
    const UserType = localStorage.UserType;
    let Id: string | null = '';

    if (UserType === "MOS") {
      Id = localStorage.id;
    } else if (UserType === "Vendor") {
      Id = localStorage.VendorId;
    } else if (UserType === "Client") {
      Id = localStorage.ClientId;
    }


    let dataParams: Record<string, any> = {};

    const { q = '', perPage = 10, page = 1, sort, sortColumn } = params;

    dataParams['UserType'] = UserType;
    dataParams['UserID'] = Id;
    dataParams['perPage'] = perPage;
    dataParams['page'] = page;
    dataParams['sort'] = sort;
    dataParams['sortColumn'] = sortColumn;
    dataParams['q'] = q;

    // Convert dataParams to a JSON string before encryption
    const dataParamsString = JSON.stringify(dataParams);
    const EncParams = AesEncrypt(dataParamsString);

    try {
      const response = await fetch(webservice + 'api/BulkUpload/bulkdata', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      const jsonResponse = await response.json();
      const Response = AesDecrypt(jsonResponse);
      const Decryptdata = Response.response_data;

      if (Decryptdata.length === 0 || Decryptdata === 'There is no Record Found') {
        return {
          params,
          data: [],
          allData: [],
          totalPages: 0,
          no_Data: 'There is no Record Found',
        };
      } else {
        const totalCount = Response.count;
        const res = Response.response_data;

        return {
          params,
          data: res.length <= perPage ? res : paginateArray(res, perPage, page),
          allData: res,
          totalPages: totalCount,
          no_Data: [],
        };
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const get_vendorname = createAsyncThunk('BulkUpload/vendorname', async () => {
  const response = await fetch(webservice + 'api/ManualEntries/vendorname', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
  .then((response1) => response1.json())
  
  const TempData = AesDecrypt(response)
  return TempData;
})


export const Get_permission = createAsyncThunk(
  'Patient/Get_permission',
  async (data: any, { dispatch, getState }) => {
    const response = await fetch(webservice + 'api/Get_permission', {
      method: 'POST',
      body: JSON.stringify({ data }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => response.json());
    return response;
  }
);


// ---------------------------------------------------------------------------------------------------------------------------------------

interface ManualDataDocument {
  Patientname: string;
  PatientDOB: string;
  Memberid: string;
  client_name: string;
  Provider_NPI: string;
  Payer_ID: string;
  uploaded_date: string;
  template: string;
  // Created_date: string;
}
interface GetManualDataParams {
  Id?:string;
  UserType?:string;
  q?: string;
  perPage?: number;
  page?: number;
  status?: string | null;
  sort?: string;
  sortColumn?: keyof ManualDataDocument; 
}
interface GetManualDataResponse {
  params: GetManualDataParams;
  data: ManualDataDocument[];
  allData: ManualDataDocument[];
  totalPages: number;
  no_Data: string | [];
}


const paginateUserArray = (array: ManualDataDocument[], perPage: number, page: number): ManualDataDocument[] =>
  array.slice((page - 1) * perPage, page * perPage);

export const getManualData = createAsyncThunk<GetManualDataResponse, GetManualDataParams>(
  'BulkUpload/getManualData',
  async (params) => {
    const UserType = localStorage.UserType;
    let Id: string | null = '';

    if (UserType === "MOS") {
      Id = localStorage.id;
    } else if (UserType === "Vendor") {
      Id = localStorage.VendorId;
    } else if (UserType === "Client") {
      Id = localStorage.ClientId;
    }


    let dataParams: Record<string, any> = {};

    const { q = '', perPage = 10, page = 1, sort, sortColumn } = params;

    dataParams['UserType'] = UserType;
    dataParams['UserID'] = Id;
    dataParams['perPage'] = perPage;
    dataParams['page'] = page;
    dataParams['sort'] = sort;
    dataParams['sortColumn'] = sortColumn;
    dataParams['q'] = q;

    // Convert dataParams to a JSON string before encryption
    const dataParamsString = JSON.stringify(dataParams);
    const EncParams = AesEncrypt(dataParamsString);

    try {
      const response = await fetch(webservice + 'api/ClientManagement/getmanualData', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      const jsonResponse = await response.json();
      const Response = AesDecrypt(jsonResponse);
      const Decryptdata = Response.response_data;
      
      if (Decryptdata.length === 0 || Decryptdata === 'There is no Record Found') {
        return {
          params,
          data: [],
          allData: [],
          totalPages: 0,
          no_Data: 'There is no Record Found',
        };
      } else {
        const totalCount = Response.count;
        const res = Response.response_data;

        return {
          params,
          data: res.length <= perPage ? res : paginateUserArray(res, perPage, page),
          allData: res,
          totalPages: totalCount,
          no_Data: [],
        };
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

// -------------------------------------------------------------
interface PatientDocument {
  Patientname: string;
  PatientDOB: string;
  Memberid: number;
  client_name: string;
  Provider_ID: number;
  Payer_ID: number;
  Data_Fetched: string;
}

interface GetPatientDataParams {
  id?:string;
  q?: string;
  perPage?: number;
  page?: number;
  status?: string | null;
  sort?: string;
  sortColumn?: keyof PatientDocument; 
}

interface GetPatientDataResponse {
  params: GetPatientDataParams;
  data: PatientDocument[];
  allData: PatientDocument[];
  totalPages: number;
  no_Data: string | [];
}


const paginatepatientArray = (array: PatientDocument[], perPage: number, page: number): PatientDocument[] =>
  array.slice((page - 1) * perPage, page * perPage);

export const getPatientData = createAsyncThunk<GetPatientDataResponse, GetPatientDataParams>(
  'BulkUpload/getPatientData',
  async (params) => {
    const { id, q = '', perPage = 10, page = 1, sort, sortColumn } = params;

    let dataParams: Record<string, any> = {};
    dataParams['UserID'] = id;
    dataParams['perPage'] = perPage;
    dataParams['page'] = page;
    dataParams['sort'] = sort;
    dataParams['sortColumn'] = sortColumn;
    dataParams['q'] = q;

    const dataParamsString = JSON.stringify(dataParams);
    const EncParams = AesEncrypt(dataParamsString);

    try {
      const response = await fetch(webservice + 'api/getPatientDetails', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      const jsonResponse = await response.json();
      const Response = AesDecrypt(jsonResponse);
      const Decryptdata = Response.response_data;
      
      if (Decryptdata.length === 0 || Decryptdata === 'There is no Record Found') {
        return {
          params,
          data: [],
          allData: [],
          totalPages: 0,
          no_Data: 'There is no Record Found',
        };
      } else {
        const totalCount = Response.count;
        const res = Response.response_data;

        return {
          params,
          data: res.length <= perPage ? res : paginatepatientArray(res, perPage, page),
          allData: res,
          totalPages: totalCount,
          no_Data: [],
        };
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

// ----------------------------------------------------------------

interface BulkUploadState {
  data: Document[];
  total: number;
  params: GetDataParams;
  allData: Document[];
  no_Data: string | [];
}

const initialStates: BulkUploadState = {
  data: [],
  total: 0,
  params: {},
  allData: [],
  no_Data: '',
};

export const BulkUploadSlice = createSlice({
  name: 'BulkUpload',
  initialState:initialStates,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action: PayloadAction<GetDataResponse>) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalPages;
      state.params = action.payload.params;
      state.no_Data = action.payload.no_Data;
    });
  },
});

// ---------------------------------------------------------------
interface ManualEntriesState {
  data: ManualDataDocument[];
  total: number;
  params: GetManualDataParams;
  allData: ManualDataDocument[];
  no_Data: string | [];
}

const initialState: ManualEntriesState = {
  data: [],
  total: 0,
  params: {},
  allData: [],
  no_Data: '',
};

export const ManualEntriesSlice = createSlice({
  name: 'ManualEntries',
  initialState:initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getManualData.fulfilled, (state, action: PayloadAction<GetManualDataResponse>) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalPages;
      state.params = action.payload.params;
      state.no_Data = action.payload.no_Data;
    });
  },
});

// ---------------------------------------------------------------------------

interface PatientState {
  data: PatientDocument[];
  total: number;
  params: GetPatientDataParams;
  allData: PatientDocument[];
  no_Data: string | [];
}

const initialState1: PatientState = {
  data: [],
  total: 0,
  params: {},
  allData: [],
  no_Data: '',
};

export const PatientSlice = createSlice({
  name: 'Patient',
  initialState:initialState1,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPatientData.fulfilled, (state, action: PayloadAction<GetPatientDataResponse>) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalPages;
      state.params = action.payload.params;
      state.no_Data = action.payload.no_Data;
    });
  },
});
// ---------------------------------------
export const bulkUploadReducer = BulkUploadSlice.reducer;
export const manualEntriesReducer = ManualEntriesSlice.reducer;
export const patientReducer = PatientSlice.reducer;
