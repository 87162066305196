import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, Button, CardBody, CardHeader, FormGroup, InputGroup, InputGroupText, Input } from 'reactstrap';
import webservice from '../../../Service/webservice';
import * as Yup from 'yup';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { Eye, EyeOff } from 'react-feather';
import { useDispatch } from 'react-redux';

interface FormValues {
  FirstName: string;
  // MiddleName?: string;
  LastName: string;
  MobileNo?: string;
  EmailId: string;
  UserName: string;
  ActiveStatus: string;
  Role: string;
  Permission: any;
  // Pin: string;
  // Password: string;

}
interface Option {
  label: string;
  value: string;
  key?: string;
  Type?: string;
}

// const defaultValues: FormValues = {
//   FirstName: '',
//   MiddleName: '',
//   LastName: '',
//   MobileNo: '',
//   EmailId: '',
//   UserName: '',
//   ActiveStatus: '',
//   Role: '',
//   Permission: '',
//   Password: '',
//   Pin: '',
//   ParentUsername: '',
//   ParentUserType: '',
//   ParentRole: '',
//   ParentUserId: '',
// };

const statusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
];

// const MandatoryFields = ['FirstName', 'LastName', 'EmailId', 'UserName', 'password', 'ActiveStatus', 'ParentUsername'];

interface CheckboxSelections {
  [key: string]: boolean;
}

interface Action {
  MenuKey: string;
  MenuLabel: string;
}

interface SubMenu {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
}

interface MenuItem {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
  Menu: SubMenu[];
  RefID: number;
  ParentID: number;
}

interface PermissionData {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
  Menu: SubMenu[];
  RefID: number;
  ParentID: number;
}

type MenuAction = {
  RefID: number;
  MenuLabel: string;
  Tooltip: string;
  MenuUrl: string;
  MenuKey: string;
};

type MenuItems = {
  RefID: number;
  MenuLabel: string;
  MenuUrl: string;
  MenuKey: string;
  MenuType: string;
  ParentID: number;
  MenuActions: MenuAction[];
  Menu: MenuItem[];
};

type StaticData = {
  Menu: MenuItems[];
};

const fieldMapping: { [key: string]: string } = {
  FirstName: "First Name",
  // MiddleName: "Middle Name",
  LastName: "Last Name",
  MobileNo: "Mobile No",
  EmailId: "Email Id",
  Role: "User Type",
  // Pin: "Pin",
  // Password: "Password",
  UserName: "User Name",
  ActiveStatus: "Active Status",
};

const EditClient: React.FC = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // const [showPassword, setShowPassword] = useState<boolean>(false);
  const [checkboxSelections, setCheckboxSelections] = useState<CheckboxSelections>({});
  const [isAllMenuSelected, setIsAllMenuSelected] = useState<boolean>(false);
  const [permissionData, setPermissionData] = useState<PermissionData[]>([]);
  const [initialAudit, setInitialAudit] = useState<any>({})
  const [initialValues, setInitialValues] = useState<FormValues>({
    FirstName: '',
    // MiddleName: '',
    LastName: '',
    MobileNo: '',
    EmailId: '',
    UserName: '',
    ActiveStatus: '',
    Role: '',
    Permission: '',
    // Pin: '',
    // Password: '',
  });

  const [selectedRole, setSelectedRole] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [RoleId, setRoleId] = useState<[]>([]);
  const [ParentId, setParentId] = useState<string>('');
  const [typeOptions, setTypeOptions] = useState<Option[]>([]);
  const [Role, setRole] = useState<string>(localStorage.Role);
  const [UserType, setUserType] = useState<string>(localStorage.UserType);
  const [refId, setRefId] = useState<string>('');
  const [Id, setId] = useState<string>('');
  const Username = localStorage.getItem('UserName') || '';
  const [adminoption, setClient] = useState<Option[]>([]);
  const dispatch = useDispatch();

  const FORM_VALIDATION = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    UserName: Yup.string().required("User Name is required"),
    // Pin: Yup.string().required("Please enter a valid Pin"),
    EmailId: Yup.string().email("Invalid email format").required("Email is required"),
    ActiveStatus: Yup.string().oneOf(statusOptions.map(option => option.value), "Please select a valid Status").required("Please select a valid Status"),
    Role: Yup.string().oneOf(typeOptions.map((option: any) => option.value), "Please select a valid Role").required("Please select a valid Role"),
    //  : Yup.string().oneOf(adminoption.map((option: any) => option.value), "Please select a client").required("Please select a client"),
  });


  const fetchData = async () => {
    setIsAllMenuSelected(false)
    const response = await getPermissionMenuData();
    const PermissionTemp = response.Menu;

    if (ParentId === '') {
      setPermissionData(PermissionTemp);
    }
    // setPermissionData(PermissionTemp);

    if (RoleId && typeof RoleId === 'string' && RoleId !== '') {
      const response = await getPermissionCheckboxKeys(RoleId);

      const CheckboxKey = response[0];
      const CheckboxKeys = CheckboxKey ? CheckboxKey.Permission : [];

      const updatedSelections: Record<string, boolean> = {};

      const processMenu = (menu: any) => {
        const menuKey = menu.MenuKey;
        updatedSelections[menuKey] = CheckboxKeys.includes(menuKey);
        if (menu.MenuActions && menu.MenuActions.length > 0) {
          menu.MenuActions.forEach((action: any) => {
            const menuActionKey = `${menuKey}_${action.MenuLabel.toUpperCase()}`;
            updatedSelections[menuActionKey] = CheckboxKeys.includes(menuActionKey);
          });
        }
        if (menu.Menu && menu.Menu.length > 0) {
          menu.Menu.forEach((submenu: any) => {
            processMenu(submenu);
          });
        }
      };
      PermissionTemp.forEach((permissionItem: any) => {
        processMenu(permissionItem);
      });

      CheckboxKeys.forEach((key: any) => {
        if (!(key in updatedSelections)) {
          updatedSelections[key] = true;
        }
      });

      Object.keys(updatedSelections).forEach((key) => {
        if (
          key.endsWith('_VIEW') ||
          key.endsWith('_EDIT') ||
          key.endsWith('_DELETE') ||
          key.endsWith('_ADD')
        ) {
          const baseKey = key.slice(0, key.lastIndexOf('_'));
          if (updatedSelections[key] && !updatedSelections[baseKey]) {
            updatedSelections[baseKey] = true;
          }
        }
      });

      const isAllSelected = Object.values(updatedSelections).every((value) => value === true);

      setIsAllMenuSelected(isAllSelected);
      setCheckboxSelections(updatedSelections);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchRole = async () => {
      const get_Role = async (data: any) => {
        const EncParams = AesEncrypt(JSON.stringify(data));
        const response = await fetch(webservice + 'api/clientmanagement/get_userType', {
          method: 'POST',
          body: JSON.stringify({ EncParams }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
      };

      const response = await get_Role({Role,UserType});
      const Roles = response.map((role: any) => ({
        label: role.Role,
        value: role.Role,
        key: role._id,
        Type: role.user_type,
      }));
      setTypeOptions(Roles);
    };

    fetchRole();
  }, [Role]);


  useEffect(() => {
    const fetchRoleId = async () => {
      const response = await get_TeamRoleId();
      const res = response.map((data: any) => ({
        label: data.CompanyName,
        value: data.CompanyName,
        key: data._id,
      }));
      setClient(res);
    };

    fetchRoleId();
  }, [dispatch]);

  const get_TeamRoleId = async () => {
    const response = await fetch(webservice + 'api/clientmanagement/get_client', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => response.json());

    const TempData = AesDecrypt(response);
    return TempData;
  };


  const getPermissionMenuData = async () => {
    const response = await fetch(webservice + 'api/clientmanagement/Permision', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const TempData = AesDecrypt(res)
        const convertDBDataToStaticFormat = (dbData: any): StaticData => {
          const staticData: StaticData = {
            Menu: [],
          };

          const menuMap: { [key: number]: any } = {};
          dbData.forEach((item: any) => {
            menuMap[item.RefId] = {
              RefID: item.RefId,
              MenuLabel: item.Menu_Label,
              MenuUrl: item.Menu_url,
              MenuKey: item.Menu_key,
              MenuType: item.Menu_type,
              ParentID: item.ParentID,
              MenuActions: item.Menu_Action.map((action: any, index: number) => ({
                RefID: index + 21,
                MenuLabel: action.charAt(0).toUpperCase() + action.slice(1),
                MenuUrl: '',
                MenuKey: `${item.Menu_key}_${action.toUpperCase()}`
              })),
              Menu: []
            };
          });

          // Sort the items based on Menu_order
          const sortedDBData = dbData.sort((a: any, b: any) => parseInt(a.Menu_order) - parseInt(b.Menu_order));

          sortedDBData.forEach((item: any) => {
            if (item.ParentID === 0 && item.Menu_type === "Menu") {
              staticData.Menu.push(menuMap[item.RefId]);
            } else if (menuMap[item.ParentID] && item.Menu_type === "Submenu") {
              // Sort submenu items based on Menu_order
              menuMap[item.ParentID].Menu.push(menuMap[item.RefId]);
              menuMap[item.ParentID].Menu.sort((a: any, b: any) => parseInt(a.Menu_order) - parseInt(b.Menu_order));
            }
          });
          return staticData;
        };
        const staticData = convertDBDataToStaticFormat(TempData);
        return staticData
      })
    return response;
  }

  const getPermissionCheckboxKeys = async (data: any) => {
    const EncParams = AesEncrypt(JSON.stringify(data));
    const response = await fetch(webservice + 'api/clientmanagement/getPermissionCheckboxKeys', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData;
  }

  const handleRoleChange = (selectedOption: any) => {
    if (selectedOption) {
      setRoleId(selectedOption.key)
      setSelectedRole(selectedOption.value);
      setSelectedType(selectedOption.Type);
      fetchData()
    } else {
      setSelectedRole('')
      setSelectedType('');
      setRoleId([])
      setIsAllMenuSelected(false);
      setCheckboxSelections({})
    }
  };


  const getUserData = async (id: string) => {
    const EncParams = AesEncrypt(JSON.stringify(id));
    const response = await fetch(webservice + 'api/ClientManagement/GetUserClientData', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    
    return TempData;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = localStorage.getItem('ClientEdit');
      if (data != null) {
        const res = await getUserData(data);
        if (res.length > 0) {
          setId(res[0].id);
          setRefId(res[0].RefId);
          updateDetails(res);
        }
      }
    };

    fetchData();
  }, []);

  const updateDetails = async (data: any[]) => {
    setInitialValues({
      FirstName: data[0].FirstName,
      // MiddleName: data[0].MiddleName,
      LastName: data[0].LastName,
      MobileNo: data[0].MobileNo,
      EmailId: data[0].EmailId,
      UserName: data[0].UserName,
      Role: data[0].Role,
      // Pin: data[0].Pin,
      // Password: data[0].Password,
      ActiveStatus: data[0].ActiveStatus,
      Permission: data[0].Permission,
    });

    setInitialAudit({
      FirstName: data[0].FirstName,
      // MiddleName: data[0].MiddleName,
      LastName: data[0].LastName,
      MobileNo: data[0].MobileNo,
      EmailId: data[0].EmailId,
      UserName: data[0].UserName,
      Role: data[0].Role,
      // Pin: data[0].Pin,
      // Password: data[0].Password,
      ActiveStatus: data[0].ActiveStatus,
      Permission: data[0].Permission,

    });

    setRoleId(data[0].RoleId)
    setSelectedType(data[0].UserType)
    const currentUser = data[0];
    const hasDifferentValues = Object.keys(initialValues).some((key) => {
      return currentUser[key as keyof FormValues] !== initialValues[key as keyof FormValues];
    });
  
    if (hasDifferentValues) {
      const userPermissions = data[0].Permission;
      const response = await getPermissionMenuData();
      const PermissionTemp = response.Menu;

      setPermissionData(PermissionTemp);
  
      const allPossibleKeys: string[] = userPermissions.reduce((keys: string[], permissionItem: any) => {
        const menuActionsKeys = permissionItem.MenuActions.map(
          (menuAction: any) => `${permissionItem.MenuKey}_${menuAction.toUpperCase()}`
        );
        return [...keys, permissionItem.MenuKey, ...menuActionsKeys];
      }, []);
  
      const updatedSelections: { [key: string]: boolean } = {};
  
      const processMenu = (menu: any) => {
        const menuKey = menu.MenuKey;
        updatedSelections[menuKey] = allPossibleKeys.includes(menuKey);
        if (menu.MenuActions && menu.MenuActions.length > 0) {
          menu.MenuActions.forEach((action: any) => {
            const menuActionKey = `${menuKey}_${action.MenuLabel.toUpperCase()}`;
            updatedSelections[menuActionKey] = allPossibleKeys.includes(menuActionKey);
          });
        }
        if (menu.Menu && menu.Menu.length > 0) {
          menu.Menu.forEach((submenu: any) => {
            processMenu(submenu);
          });
        }
      };
  
      PermissionTemp.forEach((permissionItem: any) => {
        processMenu(permissionItem);
      });
  
      allPossibleKeys.forEach((key) => {
        if (!(key in updatedSelections)) {
          updatedSelections[key] = true;
        }
      });
  
      const isAllSelected = Object.values(updatedSelections).every(value => value === true);
     
      setIsAllMenuSelected(isAllSelected);
      setCheckboxSelections(updatedSelections);
    }
  };


  const updatedata = async (data: FormValues, id: string) => {
 
    const EncParams = AesEncrypt(JSON.stringify({ data, id }));
    const res = await fetch(webservice + 'api/clientmanagement/EditClientUser', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(res);
  };


  const updateAuditlog = async (data: any) => {

    
    data.Userid = localStorage.id;
    data.ParentUserId = localStorage.ParentUserId;
    const EncParams = AesEncrypt(JSON.stringify(data));

    const response = await fetch(webservice + 'api/clientmanagement/EdituserAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(response);
  };

 
  const extractCheckedPermissions = (): any[] => {
    const checkedPermissions: any[] = [];

    permissionData.forEach((menuItem) => {
      const permissionItem = {
        RefId: menuItem.RefID,
        MenuLabel: menuItem.MenuLabel,
        MenuKey: menuItem.MenuKey,
        MenuActions: [] as string[],
      };

      menuItem.MenuActions.forEach((action) => {
        if (checkboxSelections[action.MenuKey]) {
          permissionItem.MenuActions.push(action.MenuLabel.toLowerCase());
        }
      });

      if (permissionItem.MenuActions.length > 0) {
        checkedPermissions.push(permissionItem);
      }

      menuItem.Menu.forEach((submenu: any) => {
        const submenuPermissionItem = {
          RefId: submenu.RefID,
          MenuLabel: submenu.MenuLabel,
          MenuKey: submenu.MenuKey,
          MenuActions: [] as string[],
        };

        submenu.MenuActions.forEach((action: any) => {
          if (checkboxSelections[action.MenuKey]) {
            submenuPermissionItem.MenuActions.push(action.MenuLabel.toLowerCase());
          }
        });

        if (submenuPermissionItem.MenuActions.length > 0) {
          checkedPermissions.push(submenuPermissionItem);
        }
      });
    });

    return checkedPermissions;
  };

  const handleSubmit = async (data: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    // if (isSubmitting) {
    //   return;
    // }
    // setIsSubmitting(true);

    const Permissions = extractCheckedPermissions();
    if (Permissions) {
      (data as any).Permission = Permissions
    }


    let updatedFields: any[] = [];

    for (const key in data) {
      const label = fieldMapping[key] || key;
      if (initialAudit && initialAudit[key as keyof FormValues] !== data[key as keyof FormValues]) {
        const beforeValue = initialAudit[key as keyof FormValues];
        const afterValue = data[key as keyof FormValues];
        if (key === 'ParentUserId') {
          continue;
      }
        if (key === 'Permission') {
          const beforePermission = (beforeValue as any[]).map(item => ({
            MenuLabel: item.MenuLabel,
            MenuActions: item.MenuActions.join(', ')
          }));

          const afterPermission = (afterValue as any[]).map(item => ({
            MenuLabel: item.MenuLabel,
            MenuActions: item.MenuActions.join(', ')
          }));

          if (JSON.stringify(beforePermission) === JSON.stringify(afterPermission)) {
            continue;
          }
        }

        updatedFields.push({
          field: label,
          before: beforeValue,
          after: afterValue,
        });
      }
    }

    if (RoleId) {
      (data as any).RoleId = RoleId
    }
    if (selectedType) {
      (data as any).UserType = selectedType
    }


    try {
      const ref = await updatedata(data, Id);
      if (ref === 'Username is already taken.') {
        Swal.fire({
          title: 'Warning',
          text: 'Username already exists.',
          icon: 'warning',
          customClass: {},
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: true,
          allowOutsideClick: false,
        });
      } else if (ref === 'email is already taken.') {
        Swal.fire({
          title: 'Warning',
          text: 'Email already exists.',
          icon: 'warning',
          customClass: {},
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: true,
          allowOutsideClick: false,
        });
      } else if (ref === 'Username and email are already taken.') {
        Swal.fire({
          title: 'Warning',
          text: 'Username and email already exist.',
          icon: 'warning',
          customClass: {},
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: true,
          allowOutsideClick: false,
        });
      } else if (ref === 'Edited Successfully') {
        const response = await updateAuditlog({ updatedFields, refId, Username });
        
        if (response === 'Updated Successfully') {
          Swal.fire({
            title: 'Insurance Verification Platform',
            html: '<span style="color: green;">User Updated successfully!</span>',
            icon: 'success',
            customClass: {},
            showClass: {
              popup: 'animate__animated animate__flipInX',
            },
            buttonsStyling: true,
            allowOutsideClick: false,
          }).then((result) => {
    
            if (result.value) {
              navigate('/client');
            }
          });
        }
      } else {
        throw new Error('Response data is missing or undefined');
      }
    } catch (error) {
      console.error('Error updating client:', error);
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'An error occurred while updating the client.',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleAllMenuSelect = (event: any) => {
    const isSelected = event.target.checked;
    setIsAllMenuSelected(isSelected);


    const updatedSelections = { ...checkboxSelections };
    Object.keys(updatedSelections).forEach((key) => {
      updatedSelections[key] = isSelected;
    });

    permissionData.forEach((menuItem) => {
      updatedSelections[menuItem.MenuKey] = isSelected;
      menuItem.MenuActions.forEach((action) => {
        updatedSelections[action.MenuKey] = isSelected;
      });

      menuItem.Menu.forEach((submenu) => {
        updatedSelections[submenu.MenuKey] = isSelected;
        submenu.MenuActions.forEach((action) => {
          updatedSelections[action.MenuKey] = isSelected;
        });
      });
    });

    setCheckboxSelections(updatedSelections);
  };

  const handleMenuSelect = (menuItem: MenuItem, action: Action, isSelected: boolean,) => {

    const updatedSelections = { ...checkboxSelections };
    updatedSelections[menuItem.MenuKey] = isSelected;
    updatedSelections[action.MenuKey] = isSelected;

    let isAllSelected = true;
    Object.keys(updatedSelections).forEach((key) => {
      if (key !== "SelectAll" && !updatedSelections[key]) {
        isAllSelected = false;
      }
    });

    setIsAllMenuSelected(isAllSelected);

    if (action.MenuKey === `${menuItem.MenuKey}_VIEW` && !isSelected) {
      menuItem.MenuActions.forEach((relatedAction) => {
        updatedSelections[relatedAction.MenuKey] = false;
      });
    }

    if (!isSelected) {
      menuItem.Menu.forEach((submenu) => {
        updatedSelections[submenu.MenuKey] = false;
        submenu.MenuActions.forEach((submenuAction) => {
          updatedSelections[submenuAction.MenuKey] = false;
        });
      });

      const allActionsUnchecked = menuItem.MenuActions.every(
        (submenuAction) => !updatedSelections[submenuAction.MenuKey]
      );
      if (allActionsUnchecked) {
        const viewActionKey = `${menuItem.MenuKey}_VIEW`;
        updatedSelections[viewActionKey] = false;
      }

      const parentMenuItem = permissionData.find(
        (item) => item.RefID === menuItem.ParentID
      );
      if (parentMenuItem) {
        const isAllSubmenusUnchecked = parentMenuItem.Menu.every((submenu) => {
          return (
            !updatedSelections[submenu.MenuKey] &&
            submenu.MenuActions.every(
              (submenuAction) => !updatedSelections[submenuAction.MenuKey]
            )
          );
        });
        if (isAllSubmenusUnchecked) {
          updatedSelections[parentMenuItem.MenuKey] = false;
          parentMenuItem.MenuActions.forEach((parentAction) => {
            updatedSelections[parentAction.MenuKey] = false;
          });
        }
      }
    }
    if (isSelected) {
      if (menuItem.ParentID !== 0) {
        const parentMenuItem = permissionData.find(
          (item) => item.RefID === menuItem.ParentID
        );
        if (parentMenuItem) {
          updatedSelections[parentMenuItem.MenuKey] = true;
          parentMenuItem.MenuActions.forEach((parentAction) => {
            updatedSelections[parentAction.MenuKey] = true;
          });
        }
      }
      if (action.MenuKey !== `${menuItem.MenuKey}_VIEW`) {
        const viewActionKey = `${menuItem.MenuKey}_VIEW`;
        updatedSelections[viewActionKey] = true;
      }
    }

    setCheckboxSelections(updatedSelections);
  };

  const renderMenuItems = (permissionData: MenuItem[]) => {
    return (
      <div className="MenuTree">
        <ul>
          {permissionData.map((menuItem: any) => (
            <li key={menuItem.MenuKey}>
              <div className="MenuItem">
                <div className="MenuLabel">{menuItem.MenuLabel}</div>
                <div className="MenuAction">
                  <div className="md-checkbox-inline">
                    {menuItem.MenuActions.map((action: any) => (
                      <div key={action.MenuKey} className="md-checkbox form-check-size rtl-input checkbox-checked">
                        <Input
                          type="checkbox"
                          id={`Menu_${action.MenuKey}`}
                          className="me-2 form-check-input"
                          checked={!!checkboxSelections[action.MenuKey]}
                          onChange={(e) =>
                            handleMenuSelect(
                              menuItem,
                              action,
                              e.target.checked,
                            )
                          }
                        />
                        <label htmlFor={`Menu_${action.MenuKey}`}>
                          <span></span>
                          <span className="check"></span>
                          <span className="box"></span>
                          {action.MenuLabel}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="separator"></div>
              {menuItem.Menu !== undefined && (
                <div className="SubMenu">
                  {renderMenuItems(menuItem.Menu)}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const getclientpermission = async (data: any): Promise<StaticData> => {
    const EncParams = AesEncrypt(data);
    try {
      const response = await fetch(webservice + 'api/clientmanagement/getClientpermission', {
        method: 'POST',
        body: JSON.stringify({ EncParams: EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      const res = await response.json();
      const TempData = AesDecrypt(res);
      const convertDBDataToStaticFormat = (dbData: any[]): StaticData => {
        const staticData: StaticData = {
          Menu: [],
        };
        const menuMap: { [key: number]: any } = {};

        dbData.forEach((item) => {
          menuMap[item.RefId] = {
            RefID: item.RefId,
            MenuLabel: item.Menu_Label,
            Tooltip: item.Tool_tip,
            MenuUrl: item.Menu_url,
            MenuKey: item.Menu_key,
            MenuType: item.Menu_type,
            ParentID: item.ParentID,
            MenuActions: item.Menu_Action.map((action: string, index: number) => ({
              RefID: index + 21,
              MenuLabel: action.charAt(0).toUpperCase() + action.slice(1),
              Tooltip: '',
              MenuUrl: '',
              MenuKey: `${item.Menu_key}_${action.toUpperCase()}`,
            })),
            Menu: [],
          };
        });

        const sortedDBData = dbData.sort((a: any, b: any) => parseInt(a.Menu_order) - parseInt(b.Menu_order));

        sortedDBData.forEach((item: any) => {
          if (item.ParentID === 0 && item.Menu_type === 'Menu') {
            staticData.Menu.push(menuMap[item.RefId]);
          } else if (menuMap[item.ParentID] && item.Menu_type === 'Submenu') {
            menuMap[item.ParentID].Menu.push(menuMap[item.RefId]);
            menuMap[item.ParentID].Menu.sort((a: any, b: any) => parseInt(a.Menu_order) - parseInt(b.Menu_order));
          }
        });

        return staticData;
      };

      const staticData = convertDBDataToStaticFormat(TempData);
      return staticData;
    } catch (error) {
      console.error('Error fetching Client permission:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (ParentId) {
      getclientpermission(ParentId)
        .then((res) => {
          if (res && res.Menu) {
            setPermissionData(res.Menu);
          } 
        })
        .catch((error) => {
          console.error('Error in useEffect:', error);
        });
    } 
  }, [ParentId]);









  const handleInput = (event: any) => {
    event.target.value = event.target.value.replace(/[^A-Za-z]/g, '');
  };
  const handleNumbers = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  return (
    <>
      <Breadcrumbs mainTitle='Edit Client User' parent='Client Management' title='Edit User' to={`/client`} />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
            <Col></Col>
          </CardHeader>
          <CardBody className='py-2 my-25'>
            <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={FORM_VALIDATION} onSubmit={handleSubmit}>
              {({values, setFieldValue }) => (
                <Form className="form-horizontal">
                  <Row>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> First Name  <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="FirstName" type="text" className="form-control" onInput={handleInput} placeholder="Enter the First Name" />
                        <ErrorMessage name="FirstName" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Last Name <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="LastName" type="text" className="form-control" onInput={handleInput} placeholder="Enter the Last Name" />
                        <ErrorMessage name="LastName" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> User Name <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="UserName" type="text" className="form-control" placeholder="Enter the User Name" />
                        <ErrorMessage name="UserName" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Role <span style={{ color: 'red' }}> * </span></Label>
                        <Select
                          name="Role"
                          options={typeOptions}
                          value={typeOptions.find(option => option.value === values.Role)}
                          onChange={(option: any) => { setFieldValue('Role', option ? option.value : ''); handleRoleChange(option); }}
                          className="react-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage name="Role" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    {/* {initialValues.ParentUserName && (
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check>
                            Client<span style={{ color: 'red' }}> * </span>
                          </Label>
                          <div className="form-control" style={{ backgroundColor: '#f9f9f9', cursor: 'not-allowed' }}>
                            {initialValues.ParentUserName}
                          </div>
                          <ErrorMessage name="ParentUsername" component="span" className="text-danger" />
                        </FormGroup>
                      </Col>
                    )} */}
                    {/* <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Vendor<span style={{ color: 'red' }}> * </span></Label>
                        <Select
                          name="ParentUsername"
                          options={adminoption}
                          value={adminoption.find(option => option.value === initialValues.ParentUsername)}
                          isDisabled={true}
                          // onChange={(option: any) => { setFieldValue('ParentUsername', option?.value); setFieldValue('ParentUserType', option?.Type); setFieldValue('ParentRole', option?.Role); setFieldValue('ParentUserId', option?.key); }}
                          className="react-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage name="ParentUsername" component="span" className="text-danger" />
                      </FormGroup>
                    </Col> */}
                    {/* <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Password <span style={{ color: 'red' }}> * </span></Label>
                        <InputGroup>
                          <Field name="Password" type={showPassword ? 'text' : 'password'} className="form-control" />
                          <InputGroupText className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <EyeOff size={14} /> : <Eye size={14} />}
                          </InputGroupText>
                        </InputGroup>
                        <ErrorMessage name="Password" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Pin <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="Pin" type="text" className="form-control" />
                        <ErrorMessage name="Pin" component="span" className="text-danger" />
                      </FormGroup>
                    </Col> */}
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Email Id<span style={{ color: 'red' }}> * </span></Label>
                        <Field name="EmailId" type="text" className="form-control" placeholder="Enter the email Id" />
                        <ErrorMessage name="EmailId" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Mobile No</Label>
                        <Field name="MobileNo" type="text" className="form-control" onInput={handleNumbers} placeholder="Enter the Mobile No." />
                        <ErrorMessage name="MobileNo" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Status</Label>
                        <Select
                          name="ActiveStatus"
                          options={statusOptions}
                          defaultValue={statusOptions[0]}
                          onChange={option => setFieldValue('ActiveStatus', option?.value)}
                          className="react-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage name="ActiveStatus" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='gy-2 pt-20'  >
                  {UserType === 'MOS' &&(
                    <Col  >
                      <div className="row">
                        <div className="col-md-8 UserPermissions">
                          <div className="MenuItem Title">
                            <div className="MenuLabel"> User Default Permission</div>
                            <div className="MenuAction">
                              <div className="md-checkbox-inline">
                                <div className="md-checkbox form-check-size rtl-input checkbox-checked">
                                  <Input
                                    type="checkbox"
                                    id="SelectAll"
                                    className="me-2 form-check-input"
                                    checked={isAllMenuSelected}
                                    onChange={handleAllMenuSelect}
                                  />
                                  <label htmlFor="SelectAll">
                                    <span></span>
                                    <span className="check"></span>
                                    <span className="box"></span>All
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div >
                          {renderMenuItems(permissionData)}
                        </div>
                      </div>
                    </Col>
                  )}
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Button type="submit" color="success" className="me-3">Submit</Button>
                        <Link to={`/client`}>
                          <Button color="danger">Cancel</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default EditClient;
