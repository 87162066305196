import { Card, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Appointments } from "../../../utils/Constant";
import { Button, CardBody } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { useCallback, useState } from 'react'

const Appointment = () => {

  interface DeleteRowData {
    appointment: string;
    id: number,
    job: string;
    companyName: string;
    dob: string;
    member_id: string,
    group: any;
    procedure_codes: string;
    maximum: string;
    dedactible: string;
    status: any;
  }


  const deleteRowData = [
    {
      appointment: "March 15th 11:00 AM",
      id: 1,
      job: "Accountant",
      companyName: "Google Inc.",
      dob: "2008/11/28",
      member_id: '56646353546',
      group: '6457687',
      procedure_codes: "D00011",
      maximum: '$1250',
      dedactible: '$40',
      status: "insured"
    },
    {
      appointment: "March 15th 11:10 AM",
      id: 2,
      job: "Chief Executive Officer (CEO)",
      companyName: "Tata Co.",
      dob: "2009/10/09",
      member_id: '56646353546',
      group: '5655675',
      procedure_codes: "D34011",
      maximum: '$900',
      dedactible: '$50',
      status: "insured"

    },
    {
      appointment: "March 15th 11:17 AM",
      id: 3,
      job: "Software Engineer",
      companyName: "Tata Co.",
      dob: "2012/10/13",
      member_id: '56646353546',
      group: '457857',
      procedure_codes: "D034034",
      maximum: '$150',
      dedactible: '$70',
      status: "inactive"

    },
    {
      appointment: "March 15th 11:20 AM",
      id: 4,
      job: "Software Engineer",
      companyName: "Tata Co.",
      dob: "2011/05/03",
      member_id: '56646353546',
      group: '235547',
      procedure_codes: "D24321",
      maximum: '$120',
      dedactible: '$50',
      status: "insured"

    },
    {
      appointment: "March 15th 12:20 PM",
      id: 5,
      job: "Pre-Sales Support",
      companyName: "New York",
      dob: "2011/12/12",
      member_id: '56646353546',
      group: '7685684',
      procedure_codes: "D01100",
      maximum: '$1050',
      dedactible: '$90',
      status: "insured"

    },
    {
      appointment: "March 15th 01:30 PM",
      id: 6,
      job: "Sales Assistant",
      companyName: "New York",
      dob: "2011/12/06",
      member_id: '56646353546',
      group: 'G-456706',
      procedure_codes: "D346545",
      maximum: '$400',
      dedactible: '$30',
      status: "insured"

    },

    {
      appointment: "March 15th 02:00 PM",
      id: 7,
      job: "Senior Javascript Developer",
      companyName: "Edinburgh",
      dob: "2012/03/29",
      member_id: '56646353546',
      group: '34667-67',
      procedure_codes: "D00454",
      maximum: '$1000',
      dedactible: '$75',
      status: "inactive"

    },

    {
      appointment: "March 15th 02:00 PM",
      id: 8,
      job: "Regional Director",
      companyName: "Google Co.",
      dob: "2008/10/16",
      member_id: '56646353546',
      group: '00000788',
      procedure_codes: "D046711",
      maximum: '$1050',
      dedactible: '$55',
      status: "insured"

    },

    {
      appointment: "March 15th 02:20 PM",
      id: 9,
      job: "Javascript Developer",
      companyName: "Google Co.",
      dob: "2009/09/15",
      member_id: '56646353546',
      group: '678677944',
      procedure_codes: "D034541",
      maximum: '$1050',
      dedactible: '$40',
      status: "insured"

    },

    {
      appointment: "March 15th 02:50 PM",
      id: 10,
      job: "Personnel Lead",
      companyName: "Edinburgh",
      dob: "2012/09/26",
      member_id: '56646353546',
      group: '6798678-78',
      procedure_codes: "D00321",
      maximum: '$1975',
      dedactible: '$80',
      status: "insured"

    },

    {
      appointment: "March 15th 03:30 PM",
      id: 11,
      job: "Customer Support",
      companyName: "New York",
      dob: "2011/01/25",
      member_id: '56646353546',
      group: '6790078678',
      procedure_codes: "D03011",
      maximum: '$598',
      dedactible: '$35',
      status: "inactive"

    },

    {
      appointment: "March 15th 04:10 PM",
      id: 12,
      job: "Sales Assistant",
      companyName: "Sidney",
      dob: "2010/09/20",
      member_id: '56646353546',
      group: '4646-6',
      procedure_codes: "D01011",
      maximum: '$1200',
      dedactible: '$34',
      status: "insured"
    },
  ]

  const [data, setData] = useState(deleteRowData);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map((r: DeleteRowData) => r.appointment)}?`)) {
      setToggleCleared(!toggleCleared);
      setData(data.filter((item) => selectedRows.filter((elem: DeleteRowData) => elem.id === item.id).length > 0 ? false : true));
      setSelectedRows("");
    }
  };


  const handleView = (rowData: DeleteRowData) => {
    // Your logic for handling the view action
    console.log("View button clicked", rowData);
  };

  const Columns = [
    {
      name: "Appointment",
      selector: (row: DeleteRowData) => (
        <div>
          {row.appointment.split(" ")[0]} {row.appointment.split(" ")[1]} <br /> {row.appointment.split(" ")[2]} {row.appointment.split(" ")[3]}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Patient",
      selector: (row: DeleteRowData) => row["job"],
      sortable: true,
    },
    {
      name: "Payer",
      selector: (row: DeleteRowData) => row["companyName"],
      sortable: true,
    },
    {
      name: "D.O.B",
      selector: (row: DeleteRowData) => row["dob"],
      sortable: true,
    },
    {
      name: "Member ID",
      selector: (row: DeleteRowData) => row["member_id"],
      sortable: true,
    },
    {
      name: "Group #",
      selector: (row: DeleteRowData) => row["group"],
      sortable: true,
    },
    {
      name: "Procedure Codes",
      selector: (row: DeleteRowData) => row["procedure_codes"],
      sortable: true,
    },
    {
      name: "Maximum",
      selector: (row: DeleteRowData) => row["maximum"],
      sortable: true,
    },
    {
      name: "Dedactible",
      selector: (row: DeleteRowData) => row["dedactible"],
      sortable: true,
    },
    {
      name: "Updated",
      selector: (row: DeleteRowData) => row["dob"],
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: DeleteRowData) => row["status"],
      sortable: true,
    },
    {
      name: "Estimate",
      selector: (row: DeleteRowData) => (
        <Button onClick={() => handleView(row)}> View </Button>
      ),
      sortable: true,
    },
  ];
  return (
    <>
      <Breadcrumbs mainTitle={Appointments} title={Appointments} />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={7}>
              </Col>
              <Col sm={5}>
                <Row>
                  <Col sm={4}>
                    <Button color="primary" className="mb-3">+  New Appointment</Button>
                  </Col>
                  <Col sm={4}>
                    <Button color="primary" className="mb-3"> Export Appointment</Button>
                  </Col>
                  <Col sm={4}>
                    <Button color="primary" className="mb-3">Print</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <DataTable data={data} columns={Columns} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleCleared} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Appointment;
