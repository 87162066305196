import { Card, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Apps, eligibility, WeAreComingSoon } from "../../../utils/Constant";
import { H2 } from "../../../AbstractElements";


const Eligibility = () => {

  
  return (
    <>
      <Breadcrumbs mainTitle={eligibility}  title={eligibility}/>
      <Container fluid>
        <Row>
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Container fluid className="p-0">
        <div className="comingsoon">
          <div className="comingsoon-inner text-center">
            <H2>{WeAreComingSoon}</H2>
            <div className="countdown" id="clockdiv">
            </div>
          </div>
        </div>
      </Container>
    </div>
        </Row>
      </Container>
    </>
  );
};

export default Eligibility;
