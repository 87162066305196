import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AesEncrypt } from '../../Service/crypto';
import webservice from '../../Service/webservice';
import { useDispatch } from 'react-redux';

interface NavigatorConnection extends Navigator {
  connection?: {
    effectiveType?: string;
    downlink?: number;
  };
}

const LogoutPage: React.FC = () => {
  const navigate = useNavigate();
  const [networkSpeed, setNetworkSpeed] = useState<string | null>(null);
  const [ipData, setIpData] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [osName, setOsName] = useState("");
  const dispatch = useDispatch();

  // Getting ip address start
  useEffect(() => {
    const getIp = () => {
      fetch('https://api.ipregistry.co/?key=cimfhddjjvc4qu5h')
        .then((response) => response.json())
        .then((data) => {
          setIpData(data.ip);
          setBrowserName(data.user_agent.name);
          setOsName(data.user_agent.os.name);
          const Time = data.time_zone.id;
          localStorage.setItem("timezone", Time);
        })
        .catch((error) => {
          console.error("Error fetching IP address:", error);
        });
    };
    getIp();
  }, []);

  // Setting user network speed start
  useEffect(() => {
    const calculateNetworkSpeed = () => {
      const navigatorConnection = navigator as NavigatorConnection;
      if (navigatorConnection.connection) {
        const connection = navigatorConnection.connection;
        if (connection.effectiveType) {
          // You might want to use this information as well
        }
        if (connection.downlink) {
          const downlinkSpeed = (connection.downlink * 8).toFixed(2);
          setNetworkSpeed(downlinkSpeed + " Mbps");
        }
      }
    };
    calculateNetworkSpeed();
  }, []);

  const userId = localStorage.id;
  const usertype = localStorage.UserType;
  const userName = localStorage.UserName;

    const userlogouthistory = async (UserLogoutinHistory: any) => { 
    const EncParams = AesEncrypt(JSON.stringify({ UserLogoutinHistory }));
    const response = await fetch(webservice + 'api/UserlogHistory/Userlogouthistory_add', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };


  const Useractivelist = async (Useractivelist: any) => {
    
    const EncParams = AesEncrypt(JSON.stringify({ Useractivelist }));
    const response = await fetch(webservice + 'api/ActiveUserList/Useractivelist_update', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

  const confirmLogout = async () => {
    Swal.fire({
      title: 'Confirm Logout',
      text: 'Are you sure you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const username = userName;
        const userid = userId;
        const Usertype = usertype;
        const UserIPAddress = ipData;
        const userBrowser = browserName;
        const userOsName = osName;
        const usernetspeed = networkSpeed;

        try {
          const result = await userlogouthistory({
            username,
            Usertype,
            userid,
            UserIPAddress,
            userBrowser,
            userOsName,
            usernetspeed,
          });
          const data = await Useractivelist({
            username,
            Usertype,
            userid,
            UserIPAddress,
            userBrowser,
            userOsName,
            usernetspeed,
          });
        
          // Combine result and data into a single object
          const combinedPayload = {
            logHistory: result,
            activeList: data,
          };
        
          dispatch({ type: 'user/loghistory/fulfilled', payload: combinedPayload });
          localStorage.setItem('login', JSON.stringify(true));
          localStorage.removeItem('Pin');
          navigate(`/login`);
        } catch (error) {
          console.error("Error logging user history:", error);
        }    
      }
    });
  };

  return (
    <button className="dropdown-item ai-icon" onClick={confirmLogout}>
      <svg
        id="icon-logout"
        xmlns="http://www.w3.org/2000/svg"
        className="text-black"
        width={18}
        height={18}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
        <polyline points="16 17 21 12 16 7" />
        <line x1={21} y1={12} x2={9} y2={12} />
      </svg>
      <span className="ms-2 text-black">Logout</span>
    </button>
  );
};

export default LogoutPage;
