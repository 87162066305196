import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import webservice from '../../Service/webservice';
import { AesDecrypt, AesEncrypt } from "../../Service/crypto";

export const createNewChat = createAsyncThunk('Chat/createNewChat', async (data:any) => {
  const EncParams = AesEncrypt(JSON.stringify(data));

  const response = await fetch(webservice + 'api/Chat/createNewChat', {
    method: 'POST',
    body: JSON.stringify({ EncParams }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
  .then((response1) => response1.json())
  
  const TempData = AesDecrypt(response)
  return TempData;
})
export const updateChat = createAsyncThunk('Chat/updateChat', async (data:any) => {
  const EncParams = AesEncrypt(JSON.stringify(data));

  const response = await fetch(webservice + 'api/Chat/updateChat', {
    method: 'POST',
    body: JSON.stringify({ EncParams }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
  .then((response1) => response1.json())
  
  const TempData = AesDecrypt(response)
  return TempData;
})


const initialState: any = {
  allMembers: [],
  members: [],
  chats: [],
  currentUser: [],
  selectedUser: [],
  toggleSideBar: false,
};

const ChatSlice = createSlice({
  name: "ChatSlice",
  initialState,
  reducers: {
    setToggleSideBar: (state) => {
      state.toggleSideBar = !state.toggleSideBar;
    },
    setAllMembers: (state, action) => {
      state.allMembers = action.payload;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    fetchChatMemberAsync: (state) => {
      if (state.allMembers.length > 0) {
        state.currentUser = state.allMembers[0];
        state.members = state.allMembers;
      }
    },
    fetchChatAsync: (state) => {
      if (state.chats.length > 0) {
        const currentUserId = state.currentUser.id;
        const chat = state.chats.filter((x: any) => x.users.includes(currentUserId));
        const selectedUserIds = state.chats.map((chat: any) =>
          chat.users.find((userId: any) => userId !== currentUserId)
        );
        const selectedUser = selectedUserIds.find((userId: any) => userId !== undefined);
        if (state.allMembers.length > 0) {
          state.chats = chat;
          state.selectedUser = state.allMembers.find((x: any) => x.id === selectedUser);
        }
      }
    },
    getMembersSuccess: (state, action) => {
      state.currentUser = action.payload[0];
      state.members = action.payload;
    },
    getChatsSuccess: (state, action) => {
      if (state.allMembers.length > 0) {
        state.chats = action.payload.chats;
      }
    },
    searchMember: (state, action) => {
      if (action.payload === "") {
        state.members = state.allMembers;
      } else {
        const keyword = action.payload.toLowerCase();
        const searchedMembers = state.allMembers.filter((member: any) =>
          member.name.toLowerCase().includes(keyword)
        );
        state.members = searchedMembers;
      }
    },
    changeChat: (state, action) => {

      const selectedUser = state.allMembers.find((x: any) => x.id === action.payload);
      if (selectedUser) {
        state.selectedUser = selectedUser;
      }
    },
    createNewChatAsync: (state, action) => {
      let conversation = {
        id: action.payload.selectedUserId,
        users: [action.payload.currentUserId, action.payload.selectedUserId],
        lastMessageTime: "-",
        messages: [],
      };
      state.chats.splice(0, 0, conversation);
      if (state.allMembers.length > 0) {
        let selectedUser = state.allMembers.find((x: any) => x.id === action.payload.selectedUserId);
        state.selectedUser = selectedUser;
      }
    },
    sendMessageAsync: (state, action) => {
      let chat = state.chats.find(
        (x: any) => x.users.includes(action.payload.currentUserId) && x.users.includes(action.payload.selectedUserId)
      );
      const now = new Date();
      let hours = now.getHours();
      const minutes = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; 
      const time = hours + ':' + minutes + ' ' + ampm;
      if (chat) {
        chat.messages.push({
          sender: action.payload.currentUserId,
          time: time,
          text: action.payload.messageInput,
          status: true,
        });
        chat.lastMessageTime = time;
        chat.online = true;
        let chats_data = state.chats.filter((x: any) => x.id !== chat.id);
        chats_data.splice(0, 0, chat);
        if (state.allMembers.length > 0) {
          let selectedUser = state.allMembers.find((x: any) => x.id === action.payload.selectedUserId);
          state.selectedUser = selectedUser;
        }
      }
    },
    replyByUserAsync: (state, action) => {
      let chat = state.chats.find(
        (x: any) => x.users.includes(action.payload.currentUserId) && x.users.includes(action.payload.selectedUserId)
      );
      const now = new Date();
      const time = now.getHours() + ":" + now.getMinutes();
      if (chat) {
        chat.messages.push({
          sender: action.payload.selectedUserId,
          time: time,
          text: action.payload.replyMessage,
          status: true,
        });
        chat.lastMessageTime = time;
        let chats_data = state.chats.filter((x: any) => x.id !== chat.id);
        chats_data.splice(0, 0, chat);
      }
    },
  },
});

export const {
  setToggleSideBar,
  setAllMembers,
  setChats,
  setSelectedUser,
  fetchChatMemberAsync,
  getMembersSuccess,
  getChatsSuccess,
  searchMember,
  changeChat,
  createNewChatAsync,
  fetchChatAsync,
  sendMessageAsync,
  replyByUserAsync,
} = ChatSlice.actions;

export default ChatSlice.reducer;
