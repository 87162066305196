import { Col, Container, Row } from 'reactstrap'
import SVG from '../../utils/CommonSvgIcon/SVG'
import { P } from '../../AbstractElements'
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const { pathname } = useLocation();
  const darkFooter = pathname === `/pagelayout/footerdark` ? "footer-dark" : "";
  const fixedFooter = pathname === `/pagelayout/footerfixed` ? "footer-fix" : "";
  return (
    <footer className={`footer ${darkFooter} ${fixedFooter}`}>
        {/* <Container fluid > */}
            {/* <Row>
                
                <Col md="6">
                    <P className="float-end mb-0">Hand crafted &amp; made with
                        <SVG iconId='heart' className="footer-icon" />
                    </P>
                </Col>
            </Row> */}
        {/* </Container> */}
        <Col md="6" className="footer-copyright">
                    <P className="mb-0">©  Outsource Strategies International. 2002 – 2024. All Rights Reserved. Design by Insurance Verification Platform.</P>
                </Col>
    </footer>
  )
}

export default Footer