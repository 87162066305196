import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Table, InputGroup, CardTitle } from 'reactstrap';
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Link } from 'react-router-dom';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import WebService from '../../../Service/webservice';
import { Edit, PlusCircle, Trash2 } from 'react-feather';
import { toast } from 'react-toastify';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FieldMaster: React.FC = () => {
    const [decryptedData, setDecryptedData] = useState<any>({});
    const [currentField, setCurrentField] = useState<any>(null);
    const [isCardModalOpen, setIsCardModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newField, setNewField] = useState<any>({ index: '', value: '' });
    const [query, setQuery] = useState<string>('');
    const [currentCategory, setCurrentCategory] = useState<string | null>(null);
    const [expandedCards, setExpandedCards] = useState<{ [key: string]: boolean }>({});
    const [expandAll, setExpandAll] = useState<boolean>(false);
    const UserId = localStorage.id;

    const fetchData = async () => {
        const EncParams = AesEncrypt(UserId);
        try {
            const response = await fetch(WebService + "api/TemplateCreation/get_patient_details", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({ EncParams }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            const decryptedResult = AesDecrypt(result);
            if (decryptedResult.message === 'There is no Record Found') {
                setDecryptedData({});
            } else {
                const filteredData = Object.fromEntries(
                    Object.entries(decryptedResult[0])
                        .filter(([key]) => key !== '_id')
                );
                setDecryptedData(filteredData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const toggleEditModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
    };

    const toggleCardModalOpen = () => {
        setIsCardModalOpen(!isCardModalOpen);
    };

    const toggleAddModal = () => {
        setIsAddModalOpen(!isAddModalOpen);
    };

    const handleAddCard = (category: string) => {
        setCurrentCategory(category);
        setNewField({ index: category, value: '' });
        toggleCardModalOpen();
    };

    const handleEdit = (category: string, index: string) => {
        setCurrentField({ category, index });
        setNewField({ index: index, value: decryptedData[category][index] });
        toggleEditModal();
    };

    const handleAdd = (category: string) => {
        setCurrentCategory(category);
        setNewField({ index: '', value: '' });
        toggleAddModal();
    };

    const handleDelete = async (category: string, index: string) => {
        const updatedCategoryData = { ...decryptedData[category] };
        delete updatedCategoryData[index];
        setDecryptedData({ ...decryptedData, [category]: updatedCategoryData });
        const EncParams = AesEncrypt(JSON.stringify({ category, index }));
        const response = await fetch(WebService + 'api/TemplateField/Delete', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        if (response === 200) {
            toast.success("Field Deleted Successfully");
        }
    };

    const handleDeleteCard = async (category: string) => {
        const updatedData = { ...decryptedData };
        delete updatedData[category];
        setDecryptedData(updatedData);
        const EncParams = AesEncrypt(JSON.stringify({ category }));
        const response = await fetch(WebService + 'api/TemplateField/DeleteCard', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        if (response === 200) {
            toast.success("Section Deleted Successfully");
        }
    };

    const handleSaveEdit = async () => {
        if (currentField) {
            const updatedCategoryData = { ...decryptedData[currentField.category], [newField.index]: newField.value };
            setDecryptedData({ ...decryptedData, [currentField.category]: updatedCategoryData });
        }
        toggleEditModal();

        const EncParams = AesEncrypt(JSON.stringify({ currentField, newField }));
        const response = await fetch(WebService + 'api/TemplateField/EditData', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        if (response === 200) {
            toast.success("Field Edited Successfully");
        }
    };

    const handleSaveAdd = async () => {
        if (newField.index && newField.value && currentCategory) {
            const updatedCategoryData = { ...decryptedData[currentCategory], [newField.index]: newField.value };
            setDecryptedData({ ...decryptedData, [currentCategory]: updatedCategoryData });
        }
        toggleAddModal();
        const EncParams = AesEncrypt(JSON.stringify({ currentCategory, newField }));
        const response = await fetch(WebService + 'api/TemplateField/AddData', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        if (response === 200) {
            toast.success("New Section Added Successfully");
        }
    };

    const handleSaveCard = async () => {
        if (newField.index && newField.value && currentCategory) {
            const updatedCategoryData = { ...decryptedData[currentCategory], [newField.index]: newField.value };
            setDecryptedData({ ...decryptedData, [currentCategory]: updatedCategoryData });
        }
        toggleCardModalOpen();
        const EncParams = AesEncrypt(JSON.stringify({ currentCategory, newField }));
        const response = await fetch(WebService + 'api/TemplateField/AddCardData', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        if (response === 200) {
            toast.success("New Field Added Successfully");
        }
    };

    const filterData = (data: any, query: string) => {
        if (!query) return data;
        const filteredData: any = {};
        for (const category in data) {
            filteredData[category] = {};
            for (const field in data[category]) {
                if (
                    field.toLowerCase().includes(query.toLowerCase()) ||
                    (typeof data[category][field] === 'string' && data[category][field].toLowerCase().includes(query.toLowerCase()))
                ) {
                    filteredData[category][field] = data[category][field];
                }
            }
            if (Object.keys(filteredData[category]).length === 0) {
                delete filteredData[category];
            }
        }
        return filteredData;
    };

    const toggleCard = (category: string) => {
        setExpandedCards((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    const toggleExpandAll = () => {
        const newExpandAll = !expandAll;
        setExpandAll(newExpandAll);
        const newExpandedCards = Object.keys(decryptedData).reduce((acc, category) => {
            acc[category] = newExpandAll;
            return acc;
        }, {} as { [key: string]: boolean });
        setExpandedCards(newExpandedCards);
    };

    const renderDataCard = () => {
        const filteredData = filterData(decryptedData, query);
        return (
            <>
                <Breadcrumbs mainTitle='Field Master' title='Field Master' />
                <Container fluid>
                    <Row>
                        <Col xs="4">
                            <div className="d-flex justify-content-start mb-3">
                                <Link to='/templateCreation/newTemplateCreation'>
                                    <Button color="primary">Back</Button>
                                </Link>
                                &nbsp;&nbsp;
                                <Button color="success" onClick={() => handleAdd('')} className="ml-2">Add New Section Field</Button>
                            </div>
                        </Col>
                        <Col xs="2">
                            <div className="d-flex justify-content-end mb-3">
                                <Button color="primary" onClick={toggleExpandAll} style={{ cursor: "pointer" }}>
                                    {expandAll ? 'Collapse All' : 'Expand All'}
                                </Button>
                            </div>
                        </Col>
                        <Col
                            lg='6'
                            className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-1'
                        >
                            <InputGroup className="input-group-search mb-2 zoom-on-hover">
                                <Input
                                    type="text"
                                    placeholder="🔍 Search fields..."
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    {Object.keys(filteredData).map((category, index) => (
                        <Card key={index} className="mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
                            <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }}>
                                <Row>
                                    <Col xs="10" onClick={() => toggleCard(category)}>
                                        <CardTitle tag="h3">{category}</CardTitle>
                                    </Col>
                                    <Col xs="1" className="ml-auto">
                                        <Button color="link" onClick={() => toggleCard(category)} className="float-right">
                                            {expandedCards[category] ? <FaChevronUp /> : <FaChevronDown />}
                                        </Button>
                                    </Col>
                                    <Col xs="1" className="ml-auto">
                                        <Trash2
                                            size={20}
                                            style={{ cursor: "pointer", color: "red", float: "right" }}
                                            onClick={() => handleDeleteCard(category)}
                                        />
                                        <PlusCircle
                                            size={20}
                                            color='white'
                                            style={{ cursor: "pointer", float: "right", marginRight: "1%" }}
                                            onClick={() => handleAddCard(category)}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>

                            {expandedCards[category] && (
                                <CardBody>
                                    <Table responsive>
                                        <tbody>
                                            {Object.entries(filteredData[category]).map(([field, value]) => (
                                                <tr key={field}>
                                                    <td>{JSON.stringify(value)}</td>
                                                    <td style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        <Edit size={20} style={{ cursor: "pointer", color: "#16c7db" }} onClick={() => handleEdit(category, field)} />
                                                        &nbsp;
                                                        &nbsp;
                                                        <Trash2 size={20} style={{ cursor: "pointer", color: "red" }} onClick={() => handleDelete(category, field)} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            )}
                        </Card>
                    ))}
                </Container>
                <Modal isOpen={isCardModalOpen} toggle={toggleCardModalOpen}>
                    <ModalHeader toggle={toggleCardModalOpen}>Add Field</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="addFieldValue">Field Value</Label>
                                <Input
                                    type="textarea"
                                    id="addFieldValue"
                                    value={newField.value}
                                    onChange={(e) => setNewField({ ...newField, value: e.target.value })}
                                />
                            </FormGroup>
                            <Button color="primary" onClick={handleSaveCard}>Save Changes</Button>
                        </Form>
                    </ModalBody>
                </Modal>
                <Modal isOpen={isEditModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit Field</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="editFieldValue">Field Value</Label>
                                <Input
                                    type="textarea"
                                    id="editFieldValue"
                                    value={newField.value}
                                    onChange={(e) => setNewField({ ...newField, value: e.target.value })}
                                />
                            </FormGroup>
                            <Button color="primary" onClick={handleSaveEdit}>Save Changes</Button>
                        </Form>
                    </ModalBody>
                </Modal>
                <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
                    <ModalHeader toggle={toggleAddModal}>Add Field</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="addFieldName">Section Name</Label>
                                <Input
                                    type="text"
                                    id="addFieldName"
                                    value={newField.index}
                                    onChange={(e) => setNewField({ ...newField, index: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="addFieldValue">Field Name</Label>
                                <Input
                                    type="textarea"
                                    id="addFieldValue"
                                    value={newField.value}
                                    onChange={(e) => setNewField({ ...newField, value: e.target.value })}
                                />
                            </FormGroup>
                            <Button color="primary" onClick={handleSaveAdd}>Add Field</Button>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    };

    return renderDataCard();
};

export default FieldMaster;
