import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { CreateYourPassword, Done, NewPassword, RememberPassword, RetypePassword } from '../../utils/Constant';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import MD5 from "crypto-js/md5";
import webservice from '../../Service/webservice';
import { AesEncrypt } from '../../Service/crypto';
import { Eye, EyeOff } from 'react-feather';

type FormData = {
  newPassword: string,
  confirmPassword: string
};

const ResetPassword = () => {
  const [showPassWord, setShowPassWord] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const { handleSubmit, control, setError, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      newPassword: '',
      confirmPassword: ''
    },
  });

  const location = useLocation();
  const [token, setToken] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    setToken(tokenParam ?? '');
  }, [location]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: 'Passwords do not match',
      });
      return;
    }

    try {
      const newPasswordHashed = MD5(data.newPassword).toString();
      const params = { token, newPassword: newPasswordHashed };
      const EncParams = AesEncrypt(JSON.stringify(params));

      const response = await fetch(`${webservice}api/Authentication/resetPassword`, {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      const data1 = await response.json();
      if (data1) {
        await Swal.fire({
          title: 'MedResponsive',
          text: 'Your Password Changed Successfully',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        });
        navigate('/login');
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      setError('confirmPassword', {
        type: 'manual',
        message: 'An error occurred while changing the password',
      });
    }
  };

  return (
    <div className="page-wrapper">
      <Container fluid className="p-0">
        <Row>
          <Col xs={12}>
            <div className="login-card login-dark">
              <div>
                <div className="login-main">
                  <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                    <h4>{CreateYourPassword}</h4>
                    <FormGroup>
                      <Label className="col-form-label">{NewPassword}</Label>
                      <InputGroup className="form-input position-relative">
                        <Controller
                          name="newPassword"
                          control={control}
                          render={({ field }) => (
                            <Input
                              type={showPassWord ? "text" : "password"}
                              placeholder="*********"
                              invalid={!!errors.newPassword}
                              {...field}
                            />
                          )}
                          rules={{ required: 'Please enter your new password' }}
                        />
                        <InputGroupText onClick={() => setShowPassWord(!showPassWord)}>
                          {showPassWord ? <EyeOff /> : <Eye />}
                        </InputGroupText>
                      </InputGroup>
                      {errors.newPassword && <div className="text-danger">{errors.newPassword.message}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{RetypePassword}</Label>
                      <InputGroup className="form-input position-relative">
                        <Controller
                          name="confirmPassword"
                          control={control}
                          render={({ field }) => (
                            <Input
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="*********"
                              invalid={!!errors.confirmPassword}
                              {...field}
                            />
                          )}
                          rules={{ required: 'Please retype your password' }}
                        />
                        <InputGroupText onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          {showConfirmPassword ? <EyeOff /> : <Eye />}
                        </InputGroupText>
                      </InputGroup>
                      {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword.message}</div>}
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <div className="checkbox p-0">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" htmlFor="checkbox1">{RememberPassword}</Label>
                      </div>
                      <Button color="primary" className="btn-block w-100" type="submit">{Done}</Button>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
