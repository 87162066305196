import { Container, Row } from "reactstrap";
import {Dashboard, WeAreComingSoon} from "../../../utils/Constant";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { H2, H5 } from "../../../AbstractElements";

const AddAppointment = () => { 
  return (
    <Container fluid className="dashboard_default">
      <Row className="widget-grid">
        <Breadcrumbs mainTitle='Add Appointment' parent='Patients' title='Add Appointment' to={`/patients`}/>
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Container fluid className="p-0">
        <div className="comingsoon">
          <div className="comingsoon-inner text-center">
            <H2>{WeAreComingSoon}</H2>
            <div className="countdown" id="clockdiv">
            </div>
          </div>
        </div>
      </Container>
    </div>
      </Row>
    </Container>
  );
};

export default AddAppointment;
