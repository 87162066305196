
import { ArrowLeftCircle, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Edit, Eye, Trash, Trash2 } from 'react-feather'

// ** Reactstrap Imports
import { Button, Input, CardBody, Label, CardHeader, Container, FormGroup, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import { Card, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Usertype } from "../../../utils/Constant";
import DataTable from 'react-data-table-component';
import { forwardRef, InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from "../../../Service/crypto";

import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from "yup";
import { v4 as uuidv4 } from 'uuid';
import { AnyARecord } from 'dns';
import ReactPaginate from 'react-paginate';
import { useAppDispatch } from '../../../ReaduxToolkit/Hooks';
import { getData,Get_permission } from './store'

interface DeleteRowData {
  user_type: string;
  id: string;
  Role: string;
  RefId: string;
  UserName: string;
}

interface Action {
  MenuKey: string;
  MenuLabel: string;
}

interface SubMenu {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
}

interface MenuItem {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
  Menu: SubMenu[];
  RefID: number;
  ParentID: number;
}

interface PermissionData {
  MenuKey: string;
  MenuLabel: string;
  MenuActions: Action[];
  Menu: SubMenu[];
  RefID: number;
  ParentID: number;
}

interface CheckboxSelections {
  [key: string]: boolean;
}


type FormValues = {
  Type?: string;
  Role?: string;
  Permission?: MenuItem[];
  AuditPermissions?: any;
};

const fieldMapping: { [key: string]: string } = {
  Role: "Role",
};

type MenuAction = {
  RefID: number;
  MenuLabel: string;
  Tooltip: string;
  MenuUrl: string;
  MenuKey: string;
};

type MenuItems = {
  RefID: number;
  MenuLabel: string;
  MenuUrl: string;
  MenuKey: string;
  MenuType: string;
  ParentID: number;
  MenuActions: MenuAction[];
  Menu: MenuItem[];
};

type StaticData = {
  Menu: MenuItems[];
};
const MandatoryFields = ["Role"]


const CustomHeader = ({ userType, dropdownOpen, handleDropdownSelect, renderAddButtons, toggleDropdown, menuActions, handleFilter, value, handlePerPage, rowsPerPage }: any) => {
  return (
    <div className='client-master-list-table-header w-100 py-2'>
      <Row>
        <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
          <div className='d-flex align-items-center me-4 '>
            <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
            <Input
              type='select'
              id='rows-per-page'
              value={rowsPerPage}
              onChange={handlePerPage}
              className='form-control ms-50 larger-select '
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
            </Input>
          </div>
          {menuActions.includes('add') && userType === 'MOS' && 'Super Admin' ? (
            <Col lg="6" className="d-flex" style={{ marginLeft: "2Pin %" }}>
              <div className="btn-group">
                <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle color="primary" caret>
                    Select Type
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-center">
                    <DropdownItem onClick={() => handleDropdownSelect('MOS')} >MOS</DropdownItem>
                    <DropdownItem onClick={() => handleDropdownSelect('Client')}>Client</DropdownItem>
                    <DropdownItem onClick={() => handleDropdownSelect('Vendor')}>Vendor</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          ) : (
            <Col sm={6} >
              {renderAddButtons()}
            </Col>
          )}
        </Col>
        <Col
          lg='6'
          className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
        >
          <InputGroup className="input-group-search mb-2 zoom-on-hover">
            <Input
              type="text"
              placeholder="🔍 Search...."
              value={value}
              onChange={(e) => handleFilter(e.target.value)}
              className="input-search"
            />
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

const UserType = () => {
  const [defaultValues, setdefaultValues] = useState<FormValues>({
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // ** Store vars
  const navigate = useNavigate()
  // ** States
  const Username = localStorage.UserName
  // const [menuActions, setMenuActions] = useState(['add', 'delete']);

  const [searchTerm, setSearchTerm] = useState<string>('');


  const [userTypeToAdd, setUserTypeToAdd] = useState<string>('');
  const [method, setMethod] = useState<string>('');
  const [permissionData, setPermissionData] = useState<PermissionData[]>([]);
  const [CardShow, setCardShow] = useState<boolean>(false);

  const [checkboxSelections, setCheckboxSelections] = useState<CheckboxSelections>({});
  const [isAllMenuSelected, setIsAllMenuSelected] = useState<boolean>(false);
  const [Id, setId] = useState<string | null>('');

  const [initialValues, setInitialValues] = useState<FormValues>({});
  const [scrollInnerModal, setscrollInnerModal] = useState<boolean>(false);
  const [filteredAuditData, setFilteredAuditData] = useState<any[]>([]);
  const [refId, setrefId] = useState<string | null>(null);
  const action = { fromUser: true };
  const [eventId, setEventId] = useState<DeleteRowData[]>([]);
  const [eventId1, setEventId1] = useState(0);
  const selectedRowsPerPage: { [key: number]: DeleteRowData[] } = {};


  const dispatch = useAppDispatch()
  const store = useSelector((state: any) => state.UserType)
  const [value, setValue] = useState<string>('');
  const [sort, setSort] = useState<string>('asc');
  const [sortColumn, setSortColumn] = useState<any>('id'); // Example initial sort column
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);


  const [menuActions, setMenuActions] = useState<string>('');

  useEffect(() => {
    if (localStorage.id !== null) {
      dispatch(Get_permission(localStorage.id)).then((response) => {
        const res1 = response.payload;
        const Action = res1[0].Permission.filter(
          (item:any) => item.MenuKey === "USER_TYPE"
        );
        setMenuActions(Action[0].MenuActions);
      });
    }
  }, [localStorage.id])

  useEffect(() => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );
  }, [dispatch, store.data.length, value, rowsPerPage, currentPage, sortColumn]);

  const handleReloadTable = () => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );
  };


  const handleFilter = (val: any) => {
    setValue(val)
    dispatch(
      getData({
        q: val,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn,
      })
    )
  }

  const handlePerPage = (e: any) => {
    setCurrentPage(1)
    dispatch(
      getData({
        q: value,
        perPage: parseInt(e.target.value),
        page: currentPage,
        sort,
        sortColumn,
      })
    )
    setRowsPerPage(parseInt(e.target.value))
  }



  const handlePagination = (page: any) => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: page.selected + 1,
        sort,
        sortColumn,
      })
    )
    setCurrentPage(page.selected + 1)
  }


  const dataToRender = () => {
    const filters: any = {
      q: value

    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      return store.allData.slice(0, rowsPerPage)
    }
  }

  const handleSort = (column: any, sortDirection: any) => {
    setSort(sortDirection)
    setSortColumn(column.sortField)
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort: sortDirection,
        sortColumn: column.sortField
      })
    )
  }

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage))

    return (
      <ReactPaginate
        previousLabel={<ChevronLeft size={16} />}
        nextLabel={<ChevronRight size={16} />}
        breakLabel="..."
        pageCount={count || 1}
        onPageChange={handlePagination}
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        containerClassName="pagination-container"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
    )
  }







  const openCard = (type: any, method: any) => {
    setUserTypeToAdd(type);
    setMethod(method);
    setCardShow(true);
  };

  const closeCard = () => {
    setUserTypeToAdd('');
    setCardShow(false);
  };

  const userType = localStorage.getItem('UserType'); // Retrieve user type from localStorage
  const Role = localStorage.getItem('Role'); // Retrieve user type from localStorage


  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  // Function to handle dropdown item selection
  const handleDropdownSelect = (type: any) => {


    if (type === 'MOS') {
      openCard('MOS', 'Add');
    } else if (type === 'Client' && (Role === "Admin" || "Super Admin")) {
      openCard('Client', 'Add');
    } else if (type === 'Vendor' && (Role === "Admin" || "Super Admin")) {
      openCard('Vendor', 'Add');
    }
    setDropdownOpen(false);
  };

  // Function to render add buttons based on user type for non-MOS users
  const renderAddButtons = () => {
    if (userType !== 'MOS') {
      return (
        <>
          {userType === 'Client' && (
            <Button color="primary" onClick={() => openCard('Client', 'Add')} className="me-2">Add Role</Button>
          )}
          {userType === 'Vendor' && (
            <Button color="primary" onClick={() => openCard('Vendor', 'Add')} className="me-2">Add Role</Button>
          )}
        </>
      );
    }
    return null;
  };

  // useEffect(() => {
  //   let permissionString = localStorage.getItem('Permission');
  //   if (!permissionString) {
  //     permissionString = JSON.stringify([]);
  //   }
  //   const Permission = JSON.parse(permissionString);
  //   const Action = Permission.filter((item: any) => item.MenuKey === 'USER_TYPE');
  //   setMenuActions(Action[0].MenuActions);
  // }, []);



  const getPermissionMenuData = async () => {
    const response = await fetch(webservice + 'api/usermanagement/Permision', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });

    const res = await response.json();


    const TempData = AesDecrypt(res);
    const convertDBDataToStaticFormat = (dbData: any): StaticData => {
      const staticData: StaticData = {
        Menu: [],
      };

      const menuMap: { [key: number]: any } = {};

      dbData.forEach((item: any) => {
        menuMap[item.RefId] = {
          RefID: item.RefId,
          MenuLabel: item.Menu_Label,
          MenuUrl: item.Menu_url,
          MenuKey: item.Menu_key,
          MenuType: item.Menu_type,
          ParentID: item.ParentID,
          MenuActions: item.Menu_Action.map((action: any, index: number) => ({
            RefID: index + 21,
            MenuLabel: action.charAt(0).toUpperCase() + action.slice(1),
            Tooltip: '',
            MenuUrl: '',
            MenuKey: `${item.Menu_key}_${action.toUpperCase()}`,
          })),
          Menu: [],
        };
      });

      // Sort the items based on Menu_order
      const sortedDBData = dbData.sort((a: any, b: any) => parseInt(a.Menu_order) - parseInt(b.Menu_order));

      sortedDBData.forEach((item: any) => {
        if (item.ParentID === 0 && item.Menu_type === 'Menu') {
          staticData.Menu.push(menuMap[item.RefId]);
        } else if (menuMap[item.ParentID] && item.Menu_type === 'Submenu') {
          // Sort submenu items based on Menu_order
          menuMap[item.ParentID].Menu.push(menuMap[item.RefId]);
          menuMap[item.ParentID].Menu.sort((a: any, b: any) => parseInt(a.Menu_order) - parseInt(b.Menu_order));
        }
      });
      return staticData;
    };

    const staticData = convertDBDataToStaticFormat(TempData);
    return staticData;
  };


  useEffect(() => {
    const fetchData = async () => {
      if (method === 'Add') {
        const response = await getPermissionMenuData();
        const PermissionTemp = response.Menu;
        setPermissionData(PermissionTemp);

        const initialSelections: { [key: string]: boolean } = {};
        PermissionTemp.forEach((menuItem: any) => {
          initialSelections[menuItem.MenuKey] = false;
          menuItem.MenuActions.forEach((action: any) => {
            initialSelections[action.MenuKey] = false;
          });

          menuItem.Menu.forEach((submenu: any) => {
            initialSelections[submenu.MenuKey] = false;
            submenu.MenuActions.forEach((action: any) => {
              initialSelections[action.MenuKey] = false;
            });
          });
        });
        setCheckboxSelections(initialSelections);
      }
    };

    fetchData();
  }, [dispatch, method]);





  const handleAllMenuSelect = (event: any) => {
    if (method === 'Add') {
      const isSelected = event.target.checked;
      setIsAllMenuSelected(isSelected);


      const updatedSelections: CheckboxSelections = { ...checkboxSelections };
      Object.keys(updatedSelections).forEach((key) => {
        updatedSelections[key] = isSelected;
      });

      permissionData.forEach((menuItem: any) => {
        updatedSelections[menuItem.MenuKey] = isSelected;
        menuItem.MenuActions.forEach((action: any) => {
          updatedSelections[action.MenuKey] = isSelected;
        });

        menuItem.Menu.forEach((submenu: any) => {
          updatedSelections[submenu.MenuKey] = isSelected;
          submenu.MenuActions.forEach((action: any) => {
            updatedSelections[action.MenuKey] = isSelected;
          });
        });
      });
      setCheckboxSelections(updatedSelections);
    } else if (method === 'Edit') {

      const isSelected = event.target.checked;
      setIsAllMenuSelected(isSelected);
      setCheckboxSelections(prevSelections => {
        const updatedSelections = { ...prevSelections };
        Object.keys(updatedSelections).forEach((key) => {
          updatedSelections[key] = isSelected;
        });
        const allSelected = Object.values(updatedSelections).every(value => value === true);
        setIsAllMenuSelected(allSelected);
        permissionData.forEach((menuItem: any) => {
          updatedSelections[menuItem.MenuKey] = isSelected;
          menuItem.MenuActions.forEach((action: any) => {
            updatedSelections[action.MenuKey] = isSelected;
          });

          menuItem.Menu.forEach((submenu: any) => {
            updatedSelections[submenu.MenuKey] = isSelected;
            submenu.MenuActions.forEach((action: any) => {
              updatedSelections[action.MenuKey] = isSelected;
            });
          });
        });
        return updatedSelections;
      });
    }
  };


  const handleMenuSelect = (menuItem: MenuItem, action: Action, isSelected: boolean, method: string) => {
    if (method === 'Add') {
      const updatedSelections: CheckboxSelections = { ...checkboxSelections };
      updatedSelections[menuItem.MenuKey] = isSelected;
      updatedSelections[action.MenuKey] = isSelected;

      let isAllSelected = true;
      Object.keys(updatedSelections).forEach((key) => {
        if (key !== "SelectAll" && !updatedSelections[key]) {
          isAllSelected = false;
        }
      });

      setIsAllMenuSelected(isAllSelected);

      if (action.MenuKey === `${menuItem.MenuKey}_VIEW` && !isSelected) {
        menuItem.MenuActions.forEach((relatedAction) => {
          updatedSelections[relatedAction.MenuKey] = false;
        });
      }

      if (!isSelected) {
        menuItem.Menu.forEach((submenu) => {
          updatedSelections[submenu.MenuKey] = false;
          submenu.MenuActions.forEach((submenuAction) => {
            updatedSelections[submenuAction.MenuKey] = false;
          });
        });

        const allActionsUnchecked = menuItem.MenuActions.every(
          (submenuAction) => !updatedSelections[submenuAction.MenuKey]
        );
        if (allActionsUnchecked) {
          const viewActionKey = `${menuItem.MenuKey}_VIEW`;
          updatedSelections[viewActionKey] = false;
        }

        const parentMenuItem = permissionData.find(
          (item: MenuItem) => item.RefID === menuItem.ParentID
        );
        if (parentMenuItem) {
          const isAllSubmenusUnchecked = parentMenuItem.Menu.every((submenu) => {
            return (
              !updatedSelections[submenu.MenuKey] &&
              submenu.MenuActions.every(
                (submenuAction) => !updatedSelections[submenuAction.MenuKey]
              )
            );
          });
          if (isAllSubmenusUnchecked) {
            updatedSelections[parentMenuItem.MenuKey] = false;
            parentMenuItem.MenuActions.forEach((parentAction) => {
              updatedSelections[parentAction.MenuKey] = false;
            });
          }
        }
      }
      if (isSelected) {
        if (menuItem.ParentID !== 0) {
          const parentMenuItem = permissionData.find(
            (item: MenuItem) => item.RefID === menuItem.ParentID
          );
          if (parentMenuItem) {
            updatedSelections[parentMenuItem.MenuKey] = true;
            parentMenuItem.MenuActions.forEach((parentAction) => {
              updatedSelections[parentAction.MenuKey] = true;
            });
          }
        }
        if (action.MenuKey !== `${menuItem.MenuKey}_VIEW`) {
          const viewActionKey = `${menuItem.MenuKey}_VIEW`;
          updatedSelections[viewActionKey] = true;
        }
      }

      setCheckboxSelections(updatedSelections);
    } else if (method === 'Edit') {
      setCheckboxSelections(prevSelections => {
        const updatedSelections = { ...prevSelections };
        updatedSelections[menuItem.MenuKey] = isSelected;
        updatedSelections[action.MenuKey] = isSelected;

        let isAllSelected = true;
        Object.keys(updatedSelections).forEach((key) => {
          if (key !== "SelectAll" && !updatedSelections[key]) {
            isAllSelected = false;
          }
        });

        setIsAllMenuSelected(isAllSelected);

        if (action.MenuKey === `${menuItem.MenuKey}_VIEW` && !isSelected) {
          menuItem.MenuActions.forEach((relatedAction) => {
            updatedSelections[relatedAction.MenuKey] = false;
          });
        }

        if (!isSelected) {
          menuItem.Menu.forEach((submenu) => {
            updatedSelections[submenu.MenuKey] = false;
            submenu.MenuActions.forEach((submenuAction) => {
              updatedSelections[submenuAction.MenuKey] = false;
            });
          });

          const allActionsUnchecked = menuItem.MenuActions.every(
            (submenuAction) => !updatedSelections[submenuAction.MenuKey]
          );
          if (allActionsUnchecked) {
            const viewActionKey = `${menuItem.MenuKey}_VIEW`;
            updatedSelections[viewActionKey] = false;
          }

          const parentMenuItem = permissionData.find(
            (item: MenuItem) => item.RefID === menuItem.ParentID
          );
          if (parentMenuItem) {
            const isAllSubmenusUnchecked = parentMenuItem.Menu.every((submenu) => {
              return (
                !updatedSelections[submenu.MenuKey] &&
                submenu.MenuActions.every(
                  (submenuAction) => !updatedSelections[submenuAction.MenuKey]
                )
              );
            });
            if (isAllSubmenusUnchecked) {
              updatedSelections[parentMenuItem.MenuKey] = false;
              parentMenuItem.MenuActions.forEach((parentAction) => {
                updatedSelections[parentAction.MenuKey] = false;
              });
            }
          }
        }

        if (isSelected) {
          if (menuItem.ParentID !== 0) {
            const parentMenuItem = permissionData.find(
              (item: MenuItem) => item.RefID === menuItem.ParentID
            );
            if (parentMenuItem) {
              updatedSelections[parentMenuItem.MenuKey] = true;
              parentMenuItem.MenuActions.forEach((parentAction) => {
                updatedSelections[parentAction.MenuKey] = true;
              });
            }
          }

          if (action.MenuKey !== `${menuItem.MenuKey}_VIEW`) {
            const viewActionKey = `${menuItem.MenuKey}_VIEW`;
            updatedSelections[viewActionKey] = true;
          }
        }

        return updatedSelections;
      });
    }
  };



  const renderMenuItems = (permissionData: MenuItem[]) => {
    return (
      <div className="MenuTree">
        <ul>
          {permissionData.map((menuItem: any) => (
            <li key={menuItem.MenuKey}>
              <div className="MenuItem">
                <div className="MenuLabel">{menuItem.MenuLabel}</div>
                <div className="MenuAction">
                  <div className="md-checkbox-inline">
                    {menuItem.MenuActions.map((action: any) => (
                      <div key={action.MenuKey} className="md-checkbox form-check-size rtl-input checkbox-checked">
                        <Input
                          type="checkbox"
                          id={`Menu_${action.MenuKey}`}
                          className="me-2 form-check-input"
                          checked={checkboxSelections[action.MenuKey]}
                          onChange={(e) =>
                            handleMenuSelect(
                              menuItem,
                              action,
                              e.target.checked,
                              method
                            )
                          }
                        />
                        <label htmlFor={`Menu_${action.MenuKey}`}>
                          <span></span>
                          <span className="check"></span>
                          <span className="box"></span>
                          {action.MenuLabel}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="separator"></div>
              {menuItem.Menu !== undefined && (
                <div className="SubMenu">
                  {renderMenuItems(menuItem.Menu)}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const extractCheckedPermissions = (): string[] => {
    const checkedPermissions: string[] = [];

    permissionData.forEach((menuItem) => {
      if (checkboxSelections[menuItem.MenuKey]) {
        checkedPermissions.push(menuItem.MenuKey.toUpperCase());
      }

      menuItem.MenuActions.forEach((action) => {
        if (checkboxSelections[action.MenuKey]) {
          checkedPermissions.push(`${action.MenuKey.toUpperCase()}`);
        }
      });

      menuItem.Menu.forEach((submenu) => {
        submenu.MenuActions.forEach((action) => {
          if (checkboxSelections[action.MenuKey]) {
            checkedPermissions.push(`${action.MenuKey.toUpperCase()}`);
          }
        });
      });
    });

    return checkedPermissions;
  };




  const extractCheckedPermissionsAudit = (): string[] => {
    const checkedPermissions: string[] = [];

    permissionData.forEach((menuItem: any) => {
      const permissionItem: any = {
        RefId: menuItem.RefID,
        MenuLabel: menuItem.MenuLabel,
        MenuKey: menuItem.MenuKey,
        MenuActions: [],
      };

      menuItem.MenuActions.forEach((action: any) => {
        if (checkboxSelections[action.MenuKey]) {
          permissionItem.MenuActions.push(action.MenuLabel.toLowerCase());
        }
      });



      if (permissionItem.MenuActions.length > 0) {
        checkedPermissions.push(permissionItem);
      }

      menuItem.Menu.forEach((submenu: any) => {
        const submenuPermissionItem: any = {
          RefId: submenu.RefID,
          MenuLabel: submenu.MenuLabel,
          MenuKey: submenu.MenuKey,
          MenuActions: [],
        };

        submenu.MenuActions.forEach((action: any) => {
          if (checkboxSelections[action.MenuKey]) {
            submenuPermissionItem.MenuActions.push(action.MenuLabel.toLowerCase());
          }
        });

        if (submenuPermissionItem.MenuActions.length > 0) {
          checkedPermissions.push(submenuPermissionItem);
        }
      });
    });

    return checkedPermissions;
  };


  const AddauditLogdata = async (Data: any) => {
    const ID = Data.Refid;
    Data.Userid = localStorage.id
    const EncParams = AesEncrypt(JSON.stringify({ Data, ID }));

    const response = await fetch(webservice + 'api/UserType/AddauditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    return response;
  }

  const addAddUsertype = async (data: any) => {
    const Refid = uuidv4();
    const paramsS = data.params;
    const params = { paramsS, Refid };

    const EncParams = AesEncrypt(JSON.stringify(params));

    const response = await fetch(webservice + 'api/UserType/add', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    const ref = AesDecrypt(response)
    return ref
  }


  const handleSubmit = async (data: FormValues) => {

    if (method === 'Edit') {
      onSubmit1(data)
    } else {

      const Permissions = extractCheckedPermissionsAudit();

      if (Permissions) {
        (data as any).Permission = Permissions
      }

      (data as any).Type = userTypeToAdd

      if (isSubmitting) {
        return;
      }
      // Set the flag to indicate submission is in progress
      setIsSubmitting(true);
      const RequiredData: { [key: string]: string | undefined } = {};
      MandatoryFields.forEach((item) => {
        RequiredData[item] = (data as any)[item];
      })

      if (Object.values(RequiredData).every(field => field && field.length > 0)) {

        const getDataValue = (field: keyof FormValues) => {
          if (data[field] === undefined) {
            return '';
          } else {
            return (data[field] as any).value || data[field];
          }
        };
        const auditLogData = Object.entries(data)
          .filter(([field, value]) => value && value !== "")
          .map(([field, value]) => {
            const item = {
              field: fieldMapping[field] || field,
              value: getDataValue(field as keyof FormValues),
            };
            if (field === 'Permission') {
              const permissionsData = Permissions.map((permission: any) => ({
                MenuLabel: permission.MenuLabel,
                MenuActions: permission.MenuActions.join(', '),
              }));
              item.value = permissionsData;
            }
            return item;
          })

        if ((data as any).Permission) {
          (data as any).AuditPermissions = (data as any).Permission
        }

        const Permission = extractCheckedPermissions();
        (data as any).Permission = Permission

        const Data = { auditLogData, Username };

        const res = await addAddUsertype({ params: data })
        if (res === 'Role already exists for this user type.') {
          setIsSubmitting(false);
          Swal.fire({
            title: 'warning',
            text: 'Role already exists for this user type.',
            icon: 'warning',
            customClass: {},
            showClass: {
              popup: 'animate__animated animate__shakeX',
            },
            buttonsStyling: true,
            allowOutsideClick: false,
          });
        } else {
          await AddauditLogdata({ ...Data, Refid: res })

          Swal.fire({
            title: "Insurance Verification",
            icon: 'success',
            text: 'User Type Added Successfully!',
            customClass: {
              confirmButton: 'btn btn-success'
            },
            allowOutsideClick: false
          }).then(function (result) {
            setIsSubmitting(false);
            if (result.value) {
              setCardShow(false)
              handleReloadTable()
            }
          });
        }


      } else {
        for (const key in RequiredData) {
          if (RequiredData[key] && RequiredData[key]!.length === 0) {

          }
        }
      }
    }
  };

  const getUserTypeData = async (data: any) => {
    const EncParams = AesEncrypt(JSON.stringify(data));
    const response = await fetch(webservice + 'api/UserType/editUserTypeData', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    const TempData = AesDecrypt(response)

    return TempData
  }


  const editRowData = async (row: any) => {

    if (row.user_type === 'MOS') {
      openCard('MOS', 'Edit')
    } else if (row.user_type === 'Client') {
      openCard('Client', 'Edit')
    } else if (row.user_type === 'Vendor') {
      openCard('Vendor', 'Edit')
    }


    const data = row.RefId
    if (data != undefined) {
      const res = await getUserTypeData(data)
      updateUsertypeDetails(res)
      setId(res[0]['id'])
      setrefId(res[0]['RefId'])

    }
  }

  const updateUsertypeDetails = async (data: any) => {
    const response = await getPermissionMenuData();

    const PermissionTemp = response.Menu;
    setPermissionData(PermissionTemp);


    const userPermissions = data[0].Permission;
    const updatedSelections: { [key: string]: boolean } = {};

    PermissionTemp.forEach((permissionItem: any) => {
      const menuKey = permissionItem.MenuKey;
      updatedSelections[menuKey] = userPermissions.includes(menuKey);
      if (permissionItem.MenuActions && permissionItem.MenuActions.length > 0) {
        permissionItem.MenuActions.forEach((action: any) => {
          const menuActionKey = `${menuKey}_${action.MenuLabel.toUpperCase()}`;
          updatedSelections[menuActionKey] = userPermissions.includes(menuActionKey);
        });
      }
    });

    userPermissions.forEach((key: any) => {
      if (!(key in updatedSelections)) {
        updatedSelections[key] = true;
      }
    });



    const isAllSelected = Object.values(updatedSelections).every(value => value === true);
    setIsAllMenuSelected(isAllSelected);
    setCheckboxSelections(updatedSelections);

    setdefaultValues({
      Role: data[0].Role,
    });

    setInitialValues({
      Type: data[0].user_type,
      Role: data[0].Role,
      Permission: data[0].AuditPermissions,
    });

  };


  const updateUsertype = async (data: any) => {
    const EncParams = AesEncrypt(JSON.stringify(data));
    const response = await fetch(webservice + 'api/UserType/update', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    const ref = AesDecrypt(response)
    return ref
  }


  const updateAuditlog = async (data: any) => {
    data.Userid = localStorage.id
    data.ParentUserId = localStorage.ParentUserId;
    if (data.ParentUserId === undefined) {
      data.ParentUserId = ''
    }
    const EncParams = AesEncrypt(JSON.stringify(data));
    const response = await fetch(webservice + 'api/UserType/updateAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    return response;
  }

  const onSubmit1 = async (data: FormValues) => {

    let updatedFields = []
    const Permissions = extractCheckedPermissionsAudit();
    if (Permissions) {
      (data as any).Permission = Permissions
    }

    (data as any).Type = userTypeToAdd
    if (isSubmitting) {
      return;
    }

    // Set the flag to indicate submission is in progress
    setIsSubmitting(true)

    if (Object.values(data).every(field => field && field.length > 0)) {
      for (const key in data) {
        const label = fieldMapping[key] || key;
        if (initialValues && initialValues[key as keyof FormValues] !== data[key as keyof FormValues]) {


          let beforeValue = initialValues[key as keyof FormValues];


          let afterValue = data[key as keyof FormValues];

          if (key === 'Permission') {
            beforeValue = (beforeValue as MenuItem[]).map((item: MenuItem) => ({
              MenuLabel: item.MenuLabel,
              // MenuActions: item.MenuActions.map((action) => action.MenuLabel).join(', '),
              MenuActions: item.MenuActions.join(', ')
            }));

            afterValue = (afterValue as MenuItem[]).map((item: MenuItem) => ({
              MenuLabel: item.MenuLabel,
              // MenuActions: item.MenuActions.map((action) => action.MenuLabel).join(', '),
              MenuActions: item.MenuActions.join(', ')
            }));
          }
          if (key === 'Permission' && JSON.stringify(beforeValue) === JSON.stringify(afterValue)) {
            continue;
          }


          updatedFields.push({
            field: label,
            before: beforeValue,
            after: afterValue,
          });
        }
      }


      (data as any).AuditPermissions = (data as any).Permission
      const Permission = extractCheckedPermissions();
      (data as any).Permission = Permission

      const res = await updateUsertype({ params: data, Id })

      if (res === 'Role already exists for this user type.') {
        setIsSubmitting(false);
        Swal.fire({
          title: 'warning',
          text: 'Role already exists for this user type.',
          icon: 'warning',
          customClass: {},
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: true,
          allowOutsideClick: false,
        });
      } else {
        await updateAuditlog({ updatedFields, refId, Username })

        Swal.fire({
          title: "Insurance Verification Platform ",
          icon: 'success',
          text: 'User Type Updated Successfully!',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          allowOutsideClick: false
        }).then((result) => {
          setIsSubmitting(false);

          if (result.value) {
            setCardShow(false)
            handleReloadTable()
          }
        });

      }


    } else {
      for (const key in data) {
        if (data[key as keyof FormValues] || data[key as keyof FormValues]!.length === 0) {

        }
      }
    }
  };

  const getAuditlogdata = async (data: any) => {

    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/UserType/getAuditlogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData
  }


  const fetchAuditData = async (refid: any) => {
    if (refid !== null) {
      const res = await getAuditlogdata(refid);
      setFilteredAuditData(res);
    }
  };


  const ViewAuditLog = async (row: any) => {
    const Username = localStorage.UserName;
    const Userid = localStorage.id;
    const RefId = row.RefId;
    const Name = row.Role;
    const Data = { Username, RefId, Name, Userid };
    const EncParams = AesEncrypt(JSON.stringify(Data));
    const response = await fetch(webservice + 'api/UserType/ViewAuditLog', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    return response;
  };

  const deleteUserType = async (ids: any) => {
    const EncParams = AesEncrypt(JSON.stringify(ids));
    await fetch(webservice + 'api/UserType/delete', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
  }


  const DeleteAuditLogdata = async (Data: any) => {

    Data.Userid = localStorage.id
    Data.ParentUserId = localStorage.ParentUserId
    const EncParams = AesEncrypt(JSON.stringify(Data))
    const response = await fetch(webservice + 'api/UserType/DeleteAuditLog', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    return response;
  }

  const DeleteRow = async (rows: DeleteRowData[] | DeleteRowData) => {
    const deleteRows = async () => {
      const rowCount = Array.isArray(rows) ? rows.length : 1;
      Swal.fire({
        title: "Insurance Verification Platform",
        text: `Are you sure you want to delete ${rowCount} ${rowCount > 1 ? 'Record' : 'Record'}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ms-1",
        },
        buttonsStyling: false,
        allowOutsideClick: false
      }).then(async function (result) {
        if (result.isConfirmed) {
          if (Array.isArray(rows)) {
            const ids = rows.map(row => row.id);
            const Data = rows.map(row => ({
              Username,
              RefId: row.RefId,
              Name: row.user_type
            }));
            await deleteUserType(ids);
            await DeleteAuditLogdata(Data);
          } else {
            const { id, UserName: Name, RefId } = rows;
            const Data = { Username, RefId, Name };
            await deleteUserType([id]);
            await DeleteAuditLogdata([Data]);
          }
          Swal.fire({
            icon: "success",
            title: "Insurance Verification Platform",
            text: "User Type has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
            allowOutsideClick: false
          });
          handleReloadTable();
          setEventId1(0)
          setEventId([])
        }
      });
    };
    deleteRows();
  };


  // const deleterow = async (row: any) => {


  //   const Name = row.user_type
  //   const Refid = row.RefId
  //   const Data = { Username, Refid, Name }
  //   const id = row.id
  //   const deleterow = async () => {

  //     Swal.fire({
  //       title: "Insurance Verification Platform ",
  //       text: "Are you sure do you want to delete?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       customClass: {
  //         confirmButton: "btn btn-primary",
  //         cancelButton: "btn btn-danger ms-1",
  //       },
  //       buttonsStyling: false,
  //       allowOutsideClick: false
  //     })
  //       .then(async function (result) {
  //         if (result.isConfirmed) {
  //           const res = await deleteUserType(id)
  //           await DeleteAuditLogdata(Data)
  //           Swal.fire({
  //             icon: "success",
  //             title: "Insurance Verification Platform ",
  //             text: "User Type has been deleted.",
  //             customClass: {
  //               confirmButton: "btn btn-success",
  //             },
  //             allowOutsideClick: false
  //           })
  //           fetchData()
  //         }
  //       });
  //   };

  //   deleterow();
  // };



  const FORM_VALIDATION = Yup.object().shape({
    Role: Yup.string().required("Role is required"),

  });

  const handleLinkClick = () => {
    setCardShow(false)
  };

  const title = `${method} ${userTypeToAdd} Type`;



  interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
  }
  // Handle individual row checkbox change
  const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
    ({ id, ...props }, ref) => (
      <div style={{  position: 'relative', left: '10px' }}>
        <input type='checkbox' id={id} ref={ref} {...props} />
      </div>
    )
  );


  // Handle individual row checkbox change

  const handleChangecheck = useCallback(
    ({ selectedRows }: any) => {


      const selectedEventIds = [];
      selectedRows.forEach((row: any) => {
        selectedEventIds.push(row.id);
      });
      setEventId(selectedRows)
      setEventId1(selectedEventIds.length)
      if (!action.fromUser)
        return;
      selectedRowsPerPage[currentPage] = selectedRows;
    },
    [currentPage, selectedRowsPerPage, action.fromUser]);

  const Columns = [
    {
      name: "User Type",
      selector: (row: DeleteRowData) => row.user_type,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row: DeleteRowData) => row.Role,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: DeleteRowData) => (
        <>
          <Eye
            size={17}
            id='positionLeft'
            color='#09d5ff'
            onClick={() => {
              setscrollInnerModal(true);
              // setRefid(row.RefId);
              fetchAuditData(row.RefId);
            }}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          {eventId1 <= 1 && (
            menuActions.includes("edit") && (
              <Edit
                size={17}
                id='editIcon'
                color='#FFA500'
                onClick={() => {
                  editRowData(row)
                  ViewAuditLog(row);
                }}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              />
            )
          )}

          {eventId1 === 0 && (
            menuActions.includes("delete") && (
              <Trash2
                size={17}
                id='deleteIcon'
                color='#FF0000'
                onClick={() => DeleteRow(row)}
                style={{ cursor: 'pointer' }}
              />
            )
          )}
        </>
      ),
    },
  ];


  const customStyles = {
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f0f0',

        },
      },
    },
    headCells: {
      style: {
        backgroundColor: '#355876',  // Change background color of headers
        color: 'white',  // Change text color of headers
        fontSize: '16px',  // Adjust font size of headers
      },
    },
  };

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, store.total);
  const CustomLoadingComponent = () => {
    return (
      <div className="">
        {store.no_Data === 'There is no Record Found' ? (
          <h4>
            <p>There are no records to display</p>
          </h4>
        ) : (
          <div className="custom-loader">
            <label className="custom-label">Please wait...</label>
            <div className="custom-loading"></div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>

      {method && userTypeToAdd && CardShow === true ? (
        <Breadcrumbs mainTitle={title} parent='App Menu' title={title} to={`/usertype`} onClickLink={handleLinkClick} />
      ) : (
        <Breadcrumbs mainTitle={Usertype} title={Usertype} />
      )}
      <Container fluid>
        <div>
          <Modal style={{ maxWidth: '1500px', maxHeight: '600px' }} isOpen={scrollInnerModal} toggle={() => { setscrollInnerModal(!scrollInnerModal) }} className='modal-dialog-centered modal-lg' backdrop="static">
            <ModalHeader toggle={() => setscrollInnerModal(!scrollInnerModal)}>User Type Audit Log</ModalHeader>
            <ModalBody>
              <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                      <th className="audit-tableHeaderStyle">Date & Time</th>
                      <th className="audit-tableHeaderStyle">Action</th>
                      <th className="audit-tableHeaderStyle">Data Set</th>
                      <th className="audit-tableHeaderStyle">Changed By</th>
                    </tr>
                  </thead>
                  <tbody style={{ alignItems: 'center' }}>
                    {filteredAuditData &&
                      filteredAuditData
                        .sort((a: any, b: any) => new Date(b.Modified).getTime() - new Date(a.Modified).getTime())
                        .map((item: any, index: number) => (
                          <tr key={index}>
                            <td className="audit-tableDataStyle">
                              {(() => {
                                const localDate = new Date(item.Modified);
                                return localDate.toLocaleString();
                              })()}
                            </td>
                            <td className="audit-tableDataStyle">{item.Action}</td>
                            <td className="audit-tableDataStyle">
                              {item.Action === 'VIEW' ? (
                                <span>{item.JsonData}</span>
                              ) : (
                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                  <thead>
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                      <th className="audit-tableHeaderStyle">Field</th>
                                      {item.Action === 'EDIT' && (
                                        <>
                                          <th className="audit-tableHeaderStyle">Before</th>
                                          <th className="audit-tableHeaderStyle">After</th>
                                        </>
                                      )}
                                      {item.Action === 'ADD' && (
                                        <th className="audit-tableHeaderStyle">Value</th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.JsonData
                                      .sort((a: any, b: any) => a.field.localeCompare(b.field))
                                      .map((dataItem: any, dataIndex: number) => (
                                        <tr key={dataIndex}>
                                          <td className="audit-tableDataStyle">{dataItem.field}</td>
                                          {item.Action === 'EDIT' && (
                                            <>
                                              <td className="audit-tableDataStyle">
                                                {dataItem.field === 'Permission' && Array.isArray(dataItem.before)
                                                  ? (
                                                    <table style={{ width: '100%' }}>
                                                      <thead >
                                                        <tr>
                                                          <th className="audit-tableHeaderStyle">Menu</th>
                                                          <th className="audit-tableHeaderStyle">Actions</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {dataItem.before.map((permission: any) => (
                                                          <tr key={permission.MenuLabel}>
                                                            <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                            <td className="audit-tableDataStyle">{permission.MenuActions}</td>
                                                          </tr>
                                                        ))}
                                                      </tbody>
                                                    </table>
                                                  )
                                                  : (dataItem.before && dataItem.before.value) || dataItem.before}
                                              </td>
                                              <td className="audit-tableDataStyle">
                                                {dataItem.field === 'Permission' && Array.isArray(dataItem.after)
                                                  ? (
                                                    <table style={{ width: '100%' }}>
                                                      <thead>
                                                        <tr>
                                                          <th className="audit-tableHeaderStyle">Menu</th>
                                                          <th className="audit-tableHeaderStyle">Actions</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody >
                                                        {dataItem.after.map((permission: any) => (
                                                          <tr key={permission.MenuLabel}>
                                                            <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                            <td className="audit-tableDataStyle">{permission.MenuActions}</td>
                                                          </tr>
                                                        ))}
                                                      </tbody>
                                                    </table>
                                                  )
                                                  : (dataItem.after && dataItem.after.value) || dataItem.after}
                                              </td>
                                            </>
                                          )}
                                          {item.Action === 'ADD' && (
                                            <td className="audit-tableDataStyle">
                                              {dataItem.field === 'Permission' && Array.isArray(dataItem.value)
                                                ? (
                                                  <table style={{ width: '100%' }} >
                                                    <thead>
                                                      <tr>
                                                        <th className="audit-tableHeaderStyle">Menu</th>
                                                        <th className="audit-tableHeaderStyle">Actions</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {dataItem.value.map((permission: any) => (
                                                        <tr key={permission.MenuLabel}>
                                                          <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                          <td className="audit-tableDataStyle">{permission.MenuActions}</td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                )
                                                : (dataItem.value && dataItem.value.value) || dataItem.value}
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              )}
                            </td>
                            <td className="audit-tableDataStyle">{item.UserName}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <div className='invoice-list-wrapper'>
          {CardShow !== true && (
            <>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <div className="dataTables_wrapper">
                      <DataTable
                        subHeader={true}
                        responsive={true}
                        onSort={handleSort}
                        data={dataToRender()}
                        columns={Columns}
                        paginationServer
                        customStyles={customStyles}
                        paginationPerPage={rowsPerPage}
                        paginationDefaultPage={currentPage}
                        selectableRows
                        selectableRowsHighlight
                        selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                        onSelectedRowsChange={handleChangecheck}
                        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                        noDataComponent={<CustomLoadingComponent />}
                        paginationComponent={CustomPagination}
                        subHeaderComponent={
                          <CustomHeader
                            userType={userType}
                            dropdownOpen={dropdownOpen}
                            handleDropdownSelect={handleDropdownSelect}
                            renderAddButtons={renderAddButtons}
                            toggleDropdown={toggleDropdown}
                            value={value}
                            menuActions={menuActions}
                            rowsPerPage={rowsPerPage}
                            handleFilter={handleFilter}
                            handlePerPage={handlePerPage}

                          />
                        }
                      />
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ paddingLeft: '2%' }}>
                          {store.total && (
                            <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                          )}
                        </div>
                        <div>
                          <CustomPagination />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div>
                {eventId1 > 0 && menuActions.includes('delete') && (
                  <div className="customizer-links open">
                    <Nav className=" flex-column nav-pills">
                      <a className="nav-link" onClick={() => DeleteRow(eventId)}>
                        <span>Delete</span>
                        <Trash2 className="trash-icon" size={20} />
                      </a>
                    </Nav>
                  </div>
                )}
              </div></>
          )}
        </div>
        {CardShow && (
          <Card>
            <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
              <Row>
                <Col md={4} xs={12}>
                  <Button onClick={() => setCardShow(false)} color='primary' className='align-middle'>
                    <ArrowLeftCircle />
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Col ></Col>
            <CardBody>
              <Formik initialValues={defaultValues} validationSchema={FORM_VALIDATION} onSubmit={handleSubmit} enableReinitialize>
                {({ }) => {
                  return (
                    <div>
                      <Form className="form-horizontal">
                        <Row>
                          <Col md={4} xs={12}>
                            <FormGroup>
                              <Label check> Role <span style={{ color: 'red' }}> * </span></Label>
                              <Field name="Role" type="text" className="form-control" placeholder={"Enter the Role"} />
                              <ErrorMessage name="Role" component="span" className="text-danger" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className='gy-2 pt-20'  >
                          <Col  >
                            <div className="row">
                              <div className="col-md-8 UserPermissions">
                                <div className="MenuItem Title">
                                  <div className="MenuLabel"> User Default Permission</div>
                                  <div className="MenuAction">
                                    <div className="md-checkbox-inline">
                                      <div className="md-checkbox form-check-size rtl-input checkbox-checked">
                                        <Input
                                          type="checkbox"
                                          id="SelectAll"
                                          className="me-2 form-check-input"
                                          checked={isAllMenuSelected}
                                          onChange={handleAllMenuSelect}
                                        />
                                        <label htmlFor="SelectAll">
                                          <span></span>
                                          <span className="check"></span>
                                          <span className="box"></span>All
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div >
                                {renderMenuItems(permissionData)}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                          <Col>
                            <div className="text-end">
                              <Button type="submit" color="success" className="me-3">  {method === 'Add' ? 'Add' : 'Update'} </Button>
                              <Link to={`/usertype`}>
                                <Button onClick={() => setCardShow(false)} color="danger">Cancel</Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )
                }}
              </Formik>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  )
}

export default UserType;
