import { Container, Row, Col, Card, CardBody, CardTitle, Input, Form, Button, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane  } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from "react";
import webservice from '../../../Service/webservice';
import { AesEncrypt, AesDecrypt } from "../../../Service/crypto";
import Swal from "sweetalert2";
import moment from "moment";
import classnames from 'classnames';

// Interfaces
interface PatientData {
  appointment: string;
  id: number;
  template_data_get_heading: TemplateData;
  insure_status: string;
  patient: {
    address: {
      street1: string;
      city: string;
      zip: string;
      state: string;
    };
    coverage: {
      effective_date: string;
      status: string;
    };
    metadata: {
      value: string;
      type: string;
    };
    dob: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    suffix_name: string;
    member_id: string;
    name: string;
  };
  payer: {
    claim_address: {
      city: string;
      state: string;
      street1: string;
      street2: string;
      zip: string;
    };
    id: string;
    name: string;
    phone: string;
  };
  plan: {
    group_number: string;
    group_name: string;
    name: string;
    number: string;
    state: string;
    type: string;
    metadata: {
      value: string;
      type: string;
      description: string;
      qualifier: string;
    };
  };
  subscriber: {
    dob: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    member_id: string;
    name: string;
    gender: string;
    address: {
      street1: string;
      city: string;
      zip: string;
      state: string;
    };
    coverage: {
      effective_date: string;
      status: string;
    };
    metadata: {
      value: string;
      type: string;
    };
  };
  coinsurance?: Coinsurance[];
  deductible?: Deductible[];
  maximums?: Maximums[];
  limitations?: Limitations[];
  updated_date: string;
}

interface Coinsurance {
  network: string;
  category: string;
  insurance_type: string;
  service_type: string;
  amount: string;
  percent: String;
  procedure_code: String;
  limitation: String;
  disclaimers: String;
  quantity: String;
  quantity_qualifier: String;
  limitation_applies_to: String;
  payer_specific_description: String;
  age_limit: {
    age_high_value: String
    age_low_value: String
  };
  metadata: {
    value: string;
    type: string;
  };
}

interface Deductible {
  network: string;
  category: string;
  coverage_level: string;
  plan_period: string;
  amount: number;
  insurance_type: String;
  service_type: String;
  percent: String;
  procedure_code: String;
  limitation: String;
  limitation_applies_to: String;
  disclaimers: String;
  quantity: String;
  quantity_qualifier: String;
  payer_specific_description: String;
  age_limit: {
    age_high_value: String
    age_low_value: String
  };
}

interface PlanData {
  plan: {
    group_number: string;
    group_name: string;
    name: string;
    number: string;
    state: string;
    type: string;
    metadata: {
      value: string;
      type: string;
      description: string;
      qualifier: string;
    };
  };
}

interface SubscriberData {
  subscriber: {
    dob: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    member_id: string;
    name: string;
    gender: string;
    address: {
      street1: string;
      city: string;
      zip: string;
      state: string;
    };
    coverage: {
      effective_date: string;
      status: string;
    };
    metadata: {
      value: string;
      type: string;
    };
  };
}

interface PayerData {
  payer: {
    claim_address: {
      city: string;
      state: string;
      street1: string;
      street2: string;
      zip: string;
    };
    id: string;
    name: string;
    phone: string;
  };
}

interface Maximums {
  network: string;
  category: string;
  plan_period: string;
  amount: number;
  coverage_level: string;
  insurance_type: String;
  service_type: String;
  percent: String;
  procedure_code: String;
  limitation: String;
  limitation_applies_to: String;
  disclaimers: String;
  quantity: String;
  quantity_qualifier: String;
  payer_specific_description: String;
  age_limit: {
    age_high_value: String
    age_low_value: String
  };
}

interface Limitations {
  network: string;
  category: string;
  insurance_type: String;
  service_type: String;
  percent: String;
  amount: String;
  services_remaining: number;
  limitation: String;
  limitation_applies_to: string[];
  disclaimers: String;
  quantity: String;
  quantity_qualifier: String;
  payer_specific_description: String;
  age_limit: {
    age_high_value: String
    age_low_value: String
  };
  service_dates: {
    procedure_code: String;
    service_date: String;
  }[]
}
interface TemplateData {
  [key: string]: string;
}
interface TemplateDetailsFormProps {
  template_data_get_heading: TemplateData; // Ensure this type is correct
  settemplateData: (updatedTemplateData: TemplateData) => void; // Ensure this type is correct
}

const EditableField = ({ label, value, onChange , labelClass }: { label: string, value: string,labelClass?: string; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) => (
  <div>
     <strong className={labelClass}>{label}:</strong>
    <Input type="text" value={value} onChange={onChange} />
  </div>
);

const PatientDetailsForm = ({ patientData, setPatientData }: { patientData: PatientData, setPatientData: (data: PatientData) => void }) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof PatientData['patient'] | keyof PatientData['patient']['address'] | keyof PatientData['patient']['coverage'], nestedField?: keyof PatientData['patient']['address'] | keyof PatientData['patient']['coverage'] | keyof PatientData['patient']['metadata']) => {
    const updatedData = { ...patientData };
    if (field === 'address' || field === 'coverage' || field === 'metadata') {
      if (nestedField) {
        if (!updatedData.patient[field]) {
          updatedData.patient[field] = {} as any;  // Ensure the nested object exists
        }
        (updatedData.patient[field] as any)[nestedField] = e.target.value;
      }
    } else {
      (updatedData.patient as any)[field] = e.target.value;
    }
    setPatientData(updatedData);
  };

  if (!patientData.patient.metadata) {
    patientData.patient.metadata = { value: '', type: '' };
  }

  const getLabelClass = (value: string | undefined | null) => {
    return value?.trim() ? "" : "highlight-empty";  // Apply the class if the value is empty or null/undefined
  };

  return (
    <div className="grid-container">
      <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
        <CardHeader style={{ backgroundColor: '#2c4358', color: 'white' }}>
          <CardTitle tag="h3">
            <strong>Patient Information</strong>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={3} xs={12}>
              <EditableField label="First Name" value={patientData.patient.first_name} onChange={(e) => handleChange(e, 'first_name')} labelClass={getLabelClass(patientData.patient.first_name)} />
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="Middle Name" value={patientData.patient.middle_name} onChange={(e) => handleChange(e, 'middle_name')} labelClass={getLabelClass(patientData.patient.middle_name)} />
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="Last Name" value={patientData.patient.last_name} onChange={(e) => handleChange(e, 'last_name')}  labelClass={getLabelClass(patientData.patient.last_name)}/>
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="Suffix name" value={patientData.patient.suffix_name} onChange={(e) => handleChange(e, 'suffix_name')} labelClass={getLabelClass(patientData.patient.suffix_name)} />
            </Col>
          </Row>
          <br />
          <Row>
          <Col md={3} xs={12}>
              <EditableField label="Date of Birth" value={patientData.patient.dob} onChange={(e) => handleChange(e, 'dob')} labelClass={getLabelClass(patientData.patient.dob)}/>
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="Member ID" value={patientData.patient.member_id} onChange={(e) => handleChange(e, 'member_id')} labelClass={getLabelClass(patientData.patient.member_id)} />
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="Street" value={patientData.patient.address.street1} onChange={(e) => handleChange(e, 'address', 'street1')} labelClass={getLabelClass(patientData.patient.address.street1)}/>
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="City" value={patientData.patient.address.city} onChange={(e) => handleChange(e, 'address', 'city')} labelClass={getLabelClass(patientData.patient.address.city)}/>
            </Col>
            
          </Row>
          <br />
          <Row>
          <Col md={3} xs={12}>
              <EditableField label="State" value={patientData.patient.address.state} onChange={(e) => handleChange(e, 'address', 'state')} labelClass={getLabelClass(patientData.patient.address.state)} />
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="Zip" value={patientData.patient.address.zip} onChange={(e) => handleChange(e, 'address', 'zip')} labelClass={getLabelClass(patientData.patient.address.zip)}/>
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="Coverage Effective Date" value={patientData.patient.coverage.effective_date} onChange={(e) => handleChange(e, 'coverage', 'effective_date')}  labelClass={getLabelClass(patientData.patient.coverage.effective_date)} />
            </Col>
            <Col md={3} xs={12}>
              <EditableField label="Coverage Status" value={patientData.patient.coverage.status} onChange={(e) => handleChange(e, 'coverage', 'status')} labelClass={getLabelClass(patientData.patient.coverage.status)}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

const PlanDetailsForm = ({ planData, setPlanData }: { planData: PlanData, setPlanData: (data: PlanData) => void }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof PlanData['plan'] | keyof PatientData['plan']['metadata'], nestedField?: keyof PatientData['plan']['metadata']) => {
    const updatedData = { ...planData };
    if (field === 'metadata' && nestedField) {
      if (!updatedData.plan.metadata) {
        updatedData.plan.metadata = { value: '', type: '',description: '',qualifier: '' };
      }
      (updatedData.plan.metadata as any)[nestedField] = e.target.value;
    } else {
      (updatedData.plan as any)[field] = e.target.value;
    }
    setPlanData(updatedData);
  };


  if (!planData.plan.metadata) {
    planData.plan.metadata = { value: '', type: '',description: '',qualifier: '' };
  }

  const getLabelClass = (value: string | undefined | null) => {
    return value?.trim() ? "" : "highlight-empty";  // Apply the class if the value is empty or null/undefined
  };
  
  return (
    <div className="card-container">
      <Card className="mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
        <CardHeader style={{ backgroundColor: '#2c4358', color: 'white' }}>
          <CardTitle tag="h3">
            <strong>Plan Information</strong>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="Name" value={planData.plan.name} onChange={(e) => handleChange(e, 'name')} labelClass={getLabelClass(planData.plan.name)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Plan Number" value={planData.plan.number} onChange={(e) => handleChange(e, 'number')} labelClass={getLabelClass(planData.plan.number)}/>
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Plan Type" value={planData.plan.type} onChange={(e) => handleChange(e, 'type')} labelClass={getLabelClass(planData.plan.type)} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="State" value={planData.plan.state} onChange={(e) => handleChange(e, 'state')}  labelClass={getLabelClass(planData.plan.state)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Group Number" value={planData.plan.group_number} onChange={(e) => handleChange(e, 'group_number')} labelClass={getLabelClass(planData.plan.group_number)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Group Name" value={planData.plan.group_name} onChange={(e) => handleChange(e, 'group_name')} labelClass={getLabelClass(planData.plan.group_name)}/>
            </Col>
          </Row>
          {/* <br /> */}
          {/* <Row>
            <Col md={4} xs={12}>
              <EditableField label="Meta Value" value={planData.plan.metadata.value} onChange={(e) => handleChange(e, 'metadata', 'value')} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Meta Type" value={planData.plan.metadata.type} onChange={(e) => handleChange(e, 'metadata', 'type')} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Meta description" value={planData.plan.metadata.description} onChange={(e) => handleChange(e, 'metadata', 'description')} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Meta qualifier" value={planData.plan.metadata.qualifier} onChange={(e) => handleChange(e, 'metadata', 'qualifier')} />
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </div>
  );
};

const PayerDetailsForm = ({ payerData, setPayerData }: { payerData: PayerData, setPayerData: (data: PayerData) => void }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof PayerData['payer'] | keyof PatientData['payer']['claim_address'], nestedField?: keyof PatientData['payer']['claim_address']) => {
    const updatedData = { ...payerData };
    if (field === 'claim_address') {
      if (nestedField) {
        (updatedData.payer[field] as any)[nestedField] = e.target.value;
      }
    } else {
      (updatedData.payer as any)[field] = e.target.value;
    } setPayerData(updatedData);
  };

  if (!payerData.payer.claim_address) {
    payerData.payer.claim_address = {
      city: '',
      state: '',
      street1: '',
      street2: '',
      zip: ''
    };
  }

   // Determine class for highlighting empty fields
   const getLabelClass = (value: string | undefined | null) => {
    return value?.trim() ? "" : "highlight-empty";  // Apply the class if the value is empty or null/undefined
  };

  return (
    <div className="card-container">
      <Card className="mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
        <CardHeader style={{ backgroundColor: '#2c4358', color: 'white' }}>
          <CardTitle tag="h3">
            <strong>Payer Information</strong>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="id" value={payerData.payer.id} onChange={(e) => handleChange(e, 'id')} labelClass={getLabelClass(payerData.payer.id)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Name" value={payerData.payer.name} onChange={(e) => handleChange(e, 'name')} labelClass={getLabelClass(payerData.payer.name)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Phone" value={payerData.payer.phone} onChange={(e) => handleChange(e, 'phone')} labelClass={getLabelClass(payerData.payer.phone)} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="City" value={payerData.payer.claim_address.city} onChange={(e) => handleChange(e, 'claim_address', 'city')}   labelClass={getLabelClass(payerData.payer.claim_address?.city || '')} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="State" value={payerData.payer.claim_address.state} onChange={(e) => handleChange(e, 'claim_address', 'state')} labelClass={getLabelClass(payerData.payer.claim_address?.state || '')} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Street1" value={payerData.payer.claim_address.street1} onChange={(e) => handleChange(e, 'claim_address', 'street1')} labelClass={getLabelClass(payerData.payer.claim_address?.street1 || '')} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="Street2" value={payerData.payer.claim_address.street2} onChange={(e) => handleChange(e, 'claim_address', 'street2')}  labelClass={getLabelClass(payerData.payer.claim_address?.street2 || '')}/>
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Zip" value={payerData.payer.claim_address.zip} onChange={(e) => handleChange(e, 'claim_address', 'zip')} labelClass={getLabelClass(payerData.payer.claim_address?.zip || '')} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};


const SubscriberDetailsForm = ({ subscriberData, setSubscriberData }: { subscriberData: SubscriberData, setSubscriberData: (data: SubscriberData) => void }) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof SubscriberData['subscriber'] | keyof PatientData['subscriber']['address'] | keyof PatientData['subscriber']['coverage'] | keyof PatientData['subscriber']['metadata'],
    nestedField?: keyof typeof subscriberData.subscriber.address | keyof typeof subscriberData.subscriber.coverage | keyof typeof subscriberData.subscriber.metadata
  ) => {
    const updatedData = { ...subscriberData };
    if ((field === 'address' || field === 'coverage' || field === 'metadata') && nestedField) {
      if (!updatedData.subscriber[field]) {
        updatedData.subscriber[field] = {} as any; // Ensure the nested object exists
      }
      (updatedData.subscriber[field] as any)[nestedField] = e.target.value;
    } else {
      (updatedData.subscriber as any)[field] = e.target.value;
    }
    setSubscriberData(updatedData);
  };

  if (!subscriberData.subscriber.metadata) {
    subscriberData.subscriber.metadata = { value: '', type: '' };
  }

  const getLabelClass = (value: string | undefined | null) => {
    return value?.trim() ? "" : "highlight-empty";  // Apply the class if the value is empty or null/undefined
  };

  return (
    <div className="card-container">
      <Card className="mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
        <CardHeader style={{ backgroundColor: '#2c4358', color: 'white' }}>
          <CardTitle tag="h3">
            <strong>subscriber Information</strong>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="First Name" value={subscriberData.subscriber.first_name} onChange={(e) => handleChange(e, 'first_name')} labelClass={getLabelClass(subscriberData.subscriber.first_name)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Last Name" value={subscriberData.subscriber.last_name} onChange={(e) => handleChange(e, 'last_name')} labelClass={getLabelClass(subscriberData.subscriber.last_name)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Date of Birth" value={subscriberData.subscriber.dob} onChange={(e) => handleChange(e, 'dob')} labelClass={getLabelClass(subscriberData.subscriber.dob)} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="Gender" value={subscriberData.subscriber.gender} onChange={(e) => handleChange(e, 'gender')} labelClass={getLabelClass(subscriberData.subscriber.gender)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Member ID" value={subscriberData.subscriber.member_id} onChange={(e) => handleChange(e, 'member_id')} labelClass={getLabelClass(subscriberData.subscriber.member_id)}/>
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Street" value={subscriberData.subscriber.address.street1} onChange={(e) => handleChange(e, 'address', 'street1')}  labelClass={getLabelClass(subscriberData.subscriber.address.street1)} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="City" value={subscriberData.subscriber.address.city} onChange={(e) => handleChange(e, 'address', 'city')} labelClass={getLabelClass(subscriberData.subscriber.address.city)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="State" value={subscriberData.subscriber.address.state} onChange={(e) => handleChange(e, 'address', 'state')} labelClass={getLabelClass(subscriberData.subscriber.address.state)} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Zip" value={subscriberData.subscriber.address.zip} onChange={(e) => handleChange(e, 'address', 'zip')} labelClass={getLabelClass(subscriberData.subscriber.address.zip)} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} xs={12}>
              <EditableField label="Coverage Status" value={subscriberData.subscriber.coverage.status} onChange={(e) => handleChange(e, 'coverage', 'status')} labelClass={getLabelClass(subscriberData.subscriber.coverage.status)} />
            </Col>
            {/* <Col md={4} xs={12}>
              <EditableField label="Meta Value" value={subscriberData.subscriber.metadata.value} onChange={(e) => handleChange(e, 'metadata', 'value')} />
            </Col>
            <Col md={4} xs={12}>
              <EditableField label="Meta Type" value={subscriberData.subscriber.metadata.type} onChange={(e) => handleChange(e, 'metadata', 'type')} />
            </Col> */}
            <Col md={4} xs={12}>
              <EditableField label="Coverage Effective Date" value={subscriberData.subscriber.coverage.effective_date} onChange={(e) => handleChange(e, 'coverage', 'effective_date')} labelClass={getLabelClass(subscriberData.subscriber.coverage.effective_date)} />
            </Col>
          </Row>
          {/* <br /> */}
          {/* <Row>
            <Col md={4} xs={12}>
              <EditableField label="Coverage Effective Date" value={subscriberData.subscriber.coverage.effective_date} onChange={(e) => handleChange(e, 'coverage', 'effective_date')} />
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </div>
  );
};



const TemplateDetailsForm: React.FC<TemplateDetailsFormProps> = ({
  template_data_get_heading,
  settemplateData,
}) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const updatedData = { ...template_data_get_heading };
    updatedData[field] = e.target.value;
    settemplateData(updatedData);
  };

  if (!template_data_get_heading) {
    return <div>No template data available.</div>;
  }
  const getLabelClass = (value: string | undefined | null) => {
    return value?.trim() ? "" : "highlight-empty";  // Apply the class if the value is empty or null/undefined
  };
  return (
    <div className="grid-container">
      <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
        <CardHeader style={{ backgroundColor: '#2c4358', color: 'white' }}>
          <CardTitle tag="h3">
            <strong>Template Information</strong>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {Object.keys(template_data_get_heading).map((key) => (
            <div key={key} className="form-group">
              <label htmlFor={key} className={getLabelClass(template_data_get_heading[key])}>
              {key.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())}:
              </label>
              <input
                type="text"
                style={{ width: '30%' }}
                id={key}
                className="form-control"
                value={template_data_get_heading[key] || ''}
                onChange={(e) => handleChange(e, key)}
              />
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  );
  
};











const EditPatientDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<PatientData[]>([]);
  const navigate = useNavigate();
  
  // const [timer, setTimer] = useState<string>("00:00:00");
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [totalEditTime, setTotalEditTime] = useState("00:00:00");
  const [editEstTime, setEditEstTime] = useState("");
  
  useEffect(() => {
    const storedStartTime = localStorage.getItem('editStartTime');
    if (storedStartTime) {
      const startTime = parseInt(storedStartTime, 10);
      startEditTimer(startTime);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const startEditTimer = (startTime?: number) => {
    const initialStartTime = startTime || new Date().getTime();
    localStorage.setItem('editStartTime', initialStartTime.toString());

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const duration = currentTime - initialStartTime;
      const formattedTime = moment.utc(duration).format('HH:mm:ss');
      setTotalEditTime(formattedTime);
      setEditEstTime(`${new Date().toISOString().slice(0, 10)} ${formattedTime}`); // Date and time
    }, 1000);

    setIntervalId(interval);
  };

  const stopEditTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    localStorage.removeItem('editStartTime');
    setTotalEditTime("00:00:00");
    setEditEstTime(""); // Reset EditEstTime if needed
  };

  const totalupdatetime = totalEditTime;
  const dateupdatetime = editEstTime;
  const Action = 'EDIT'

  const fetchData = useCallback(async () => {
    try {
      const EncParams = AesEncrypt(JSON.stringify({ id: id! }));
      const response = await fetch(webservice + 'api/Patient/GetEditPatientData', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      });
      const result = await response.json();
      const decryptedResult = AesDecrypt(result);
      if (decryptedResult === "There is no Record Found") {
        setData([]);
      } else {
        setData(decryptedResult);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
    // startEditTimer();
    
    return () => {
      stopEditTimer();
    };
  }, [fetchData]);



  const handleBothSaveActions = async () => {
    await timesave();
    await handleSave();
  }
  const handleCancel = async () => {
    await timesave();
    navigate(`/patient-eligibility/${id}`);
  };

  const handleback = async () => {
    await timesave();
    navigate(`/patient-eligibility/${id}`);
  };


  const Username = localStorage.UserName;
  const Id = id; // Ensure 'id' is defined and is the correct identifier

  const timesave = async () => {
    stopEditTimer();
    const EncParams = AesEncrypt(JSON.stringify({ data: Id, totalupdatetime, dateupdatetime,Username,Action }));
    const response = await fetch(webservice + 'api/Patient/worktime', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
  }
  

  const handleSave = async () => {
    stopEditTimer();
    try {
      // Ensure metadata is included in the data to be sent
      const dataToSave = data.map(patient => {
        if (!patient.patient.metadata) {
          patient.patient.metadata = { value: '', type: '' };  // Ensure metadata object exists
        }
        return patient;
      });

      const EncParams = AesEncrypt(JSON.stringify({ data: dataToSave, id}));
      const response = await fetch(webservice + 'api/Patient/UpdatePatientData', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      const responseData = await response.json();

      if (response.ok && responseData === "Updated Successfully") {
        Swal.fire({
          title: 'Insurance Verification Platform',
          icon: 'success',
          text: 'Patient Updated Successfully!',
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(() => {
          navigate(`/patient-eligibility/${id}`);
        });
      } else {
        Swal.fire({
          title: 'Insurance Verification Platform',
          icon: 'error',
          text: responseData.message || 'An error occurred',
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    } catch (error) {
      console.error('Error saving data:', error);
      Swal.fire({
        title: 'Insurance Verification Platform',
        icon: 'error',
        text: 'An error occurred while saving data.',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    }
  };

  
  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = (tab :any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Breadcrumbs mainTitle=' Edit Patient Info' parent="Patient" title="Edit Patient Info" to={`/patients`} />
      <Container fluid>
        <Row>
          <Col xs="22">
            <div className="d-flex justify-content-start mb-3">
              <Button color="primary" onClick={handleback} >
                Back
              </Button>
            </div>
          </Col>
          <Col xs="4">
          <Nav tabs className="custom-tabs">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' }, 'custom-tab')}
                onClick={() => { toggleTab('1'); }}
              >
                API View
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' }, 'custom-tab')}
                onClick={() => { toggleTab('2'); }}
              >
                Template View
              </NavLink>
            </NavItem>
          </Nav>
         
          </Col>
          
        </Row>
        <Row>
          <Col xs="12">
            <div className="d-flex justify-content-end mb-3">
              <div style={{ fontSize: '20px', color: 'red' }}>Edit Timer: {totalEditTime}</div>
            </div>
          </Col>
        </Row>
        <TabContent activeTab={activeTab} className="w-100">
            <TabPane tabId="1">
        <br />
        {data.length > 0 && (
          <Form>
            <Row>
              <Col sm="12">
                <PatientDetailsForm
                  patientData={data[0]}
                  setPatientData={(updatedPatientData) => {
                    const newData = [...data];
                    newData[0] = updatedPatientData;
                    setData(newData);
                  }}
                />
              </Col>
              <Col sm="12">
                <PlanDetailsForm
                  planData={data[0]}
                  setPlanData={(updatedPlanData) => {
                    const newData = [...data];
                    newData[0].plan = updatedPlanData.plan;
                    setData(newData);
                  }}
                />
              </Col>
              <Col sm="12">
                <PayerDetailsForm
                  payerData={data[0]}
                  setPayerData={(updatedPayerData) => {
                    const newData = [...data];
                    newData[0].payer = updatedPayerData.payer;
                    setData(newData);
                  }}
                />
              </Col>
              <Col sm="12">
                <SubscriberDetailsForm
                  subscriberData={data[0]}
                  setSubscriberData={(updatedSubscriberData) => {
                    const newData = [...data];
                    newData[0].subscriber = updatedSubscriberData.subscriber;
                    setData(newData);
                  }}
                />
              </Col>
            </Row>
          </Form>
        )}
</TabPane>
<TabPane tabId="2">
<Form>
  <Row>
    <Col sm="12">
   
    {data.length > 0 && data[0].template_data_get_heading ? (
  <TemplateDetailsForm
    template_data_get_heading={data[0].template_data_get_heading}
    settemplateData={(updatedTemplateData) => {
      setData(prevData => {
        const updatedData = [...prevData];
        updatedData[0] = {
          ...updatedData[0],
          template_data_get_heading: updatedTemplateData,
        };
        return updatedData;
      });
    }}
  />
) : (
  <div>No template data available.</div>
)}




    </Col>
  </Row>
</Form>

</TabPane>
          </TabContent>
        
        <Row>
          <Col xs="12">
            <div className="d-flex justify-content-center mb-3">
              <Button color="success" onClick={handleBothSaveActions} className="ml-2">
                Save
              </Button>
              &nbsp;
              &nbsp;
              <Button color="secondary" onClick={handleCancel} tag={Link} to='/patients' className="ml-2">
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditPatientDetails;
