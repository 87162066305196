import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, CardBody, CardHeader, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { Plus, X } from 'react-feather';
import webservice from '../../../Service/webservice';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import Swal from 'sweetalert2';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const Addinsurance: React.FC = () => {
  const [formSubmit, setFormSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const Username = localStorage.getItem('UserName') || '';

  const AddPayerData = async (params: any) => {
 
    
    const Refid = uuidv4();
    const paramsS = params.params;
    const paramsObj = { ...paramsS, Refid };
    const EncParams = AesEncrypt(JSON.stringify(paramsObj));

    const ref = await fetch(webservice + 'api/Insurance/add', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(ref);
  };

  interface FormValues {
    Payername: string;
    PayerID: any;
   
  }

  const defaultValues: FormValues = {
    Payername: '',
    PayerID: '',
    
  }

  const AddAuditLogData = async (data: any) => {
   
    data.Userid = localStorage.id;
    const ID = data.Refid;
    const EncParams = AesEncrypt(JSON.stringify({ Data: data, ID }));
    const response = await fetch(webservice + 'api/Insurance/addaudit', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

  const handleSubmit = async (data: any) => {

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    const RequiredData: { [key: string]: any } = {
      Payername: data.Payername,
      PayerID: data.PayerID,
    };

    if (Object.values(RequiredData).every(field => field && field.length > 0)) {
      try {
        // Log audit data before submission
        const auditLogData = Object.entries(RequiredData)
          .filter(([field, value]) => value && value !== '')
          .map(([field, value]) => ({
            field,
            value,
          }));
        const Data = { auditLogData, Username };
        const refResponse = await AddPayerData({ params: RequiredData });
        const refData = JSON.parse(refResponse);
        const Refid = refData.success ? refData.Refid : refData.message;

        // Add audit log entry after successful submission
        await AddAuditLogData({ ...Data, Refid, action: "Payer Mapping added" });

        Swal.fire({
          title: 'Payer Mapping Management',
          icon: refData.success ? 'success' : 'error',
          text: refData.success ? 'Payer Mapping Added Successfully!' : Refid,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        setIsSubmitting(false);
        if (refData.success) {
          navigate(`/insurance`);
        }
      } catch (error) {
        console.error('Error adding Payer Mapping:', error);
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };
  const validationSchema = Yup.object().shape({
   
    Payername: Yup.string().required('Payer Name is required'),
    // Email: Yup.string().email('Invalid email address').required('Email is required'),
    PayerID: Yup.string().required('PayerID is required'),
  });
  const handleNumbers = (event:any) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  return (
    <>
      <Breadcrumbs mainTitle='Payer Mapping Management' parent='Payer Mapping Management' title='Payer Mapping Management' to='/insurance' />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
            <h3>Add Payer Mapping</h3>
          </CardHeader>
          <CardBody className='py-2 my-25'>
            <Formik
              initialValues={defaultValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
                setFormSubmit(true);
              }}
            >
                <Form className={`needs-validation custom-input ${formSubmit ? 'was-validated' : ''}`} noValidate>
                  <Row className="g-3">
                    <Col md={4} xs={12}>
                      <Label>Payer Id<span style={{ color: 'red' }}>*</span></Label>
                      <Field name="PayerID" type="text" className="form-control" placeholder="" required />
                      <ErrorMessage name="PayerID" component="span" className="text-danger" />
                    </Col>
                    <Col md={4} xs={12}>
                      <Label>Payer Name<span style={{ color: 'red' }}>*</span></Label>
                      <Field name="Payername" type="text" className="form-control" placeholder="" required />
                      <ErrorMessage name="Payername" component="span" className="text-danger" />
                    </Col>
                    <Col>
                      <div className="text-end" style={{ marginTop: "100px" }}>
                        <button type="submit" className="btn btn-success me-3">Submit</button>
                        <Link to='/insurance'>
                          <button type="button" className="btn btn-danger">Cancel</button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
           
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default Addinsurance;
