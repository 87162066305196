import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import SVG from "../../utils/CommonSvgIcon/SVG";
import H3 from "../Headings/H3Element";
export interface propsTypes {
  mainTitle?: string;
  parent?: string;
  title: string;
  to?: any;
  onClickLink?: () => void;
}
const Breadcrumbs = ({ mainTitle, parent, title, to ,onClickLink }: propsTypes) => {
  
  return (
    <Col xs="12">
      <div className="page-title">
        <Row>
          <Col sm="6" className="ps-0">
            <H3>{mainTitle}</H3>
          </Col>
          <Col sm="6" className="pe-0">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={`/dashboard`}>
                  <SVG iconId="stroke-home" className="stroke-icon" />
                </Link>
              </BreadcrumbItem>
              {parent && to && (
                <BreadcrumbItem>
                  <Link onClick={onClickLink} to={to}>{parent}</Link>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem className="active">{title}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default Breadcrumbs;
