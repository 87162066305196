import { Card, Col, Container, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Button, CardBody } from 'reactstrap';
import { useEffect, useState } from 'react';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from "../../../Service/crypto";
import { ChevronsLeft, ChevronsRight, Edit, Eye, Trash2 } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";

const Onder = () => {
  interface DeleteRowData {
    id: string;
    Appname: string;
    Customkey: string;
    CustomerSecret: string;
    Requesturl: string;
    CallbackUrl: string;
    RefId: string;
  }

  const navigate = useNavigate();
  const [data, setData] = useState<DeleteRowData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [scrollInnerModal, setScrollInnerModal] = useState<boolean>(false);
  const [filteredAuditData, setFilteredAuditData] = useState<[]>([]);
  const [refid, setRefid] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const Username = localStorage.UserName;

  const fetchData = async () => {
    try {
      setLoading(true);
  
      const response = await fetch(webservice + 'api/Onder/getonder', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then((response) => response.json());
  
      const decryptedResult = AesDecrypt(response);
  
      if (decryptedResult === "There is no Record Found") {
        setData([]);
      } else {
        setData(decryptedResult);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false when fetching completes
    }
  };
  
  

  useEffect(() => {
    fetchData();
  }, []);

  const ViewAuditLog = async (row: any) => {
    const Username = localStorage.UserName;
    const Userid = localStorage.id;
    const RefId = row.RefId;
    const Name = row.Appname;
    const Data = { Username, RefId, Name, Userid };
    const EncParams = AesEncrypt(JSON.stringify(Data));
    const response = await fetch(webservice + 'api/Onder/ViewAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    return response;
  };

  const deleteClient = async (id: any) => {
    const EncParams = AesEncrypt(id);
    await fetch(webservice + 'api/Onder/deleteonder', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
  };

  const DeleteAuditLogdata = async (Data: any) => {
    Data.Userid = localStorage.id;
    Data.ParentUserId = localStorage.ParentUserId;
    const EncParams = AesEncrypt(JSON.stringify(Data));
    const response = await fetch(webservice + 'api/Onder/DeleteAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    return response;
  };

  const DeleteRow = async (row: any) => {
    const Name = row.UserName;
    const RefId = row.RefId;
    const Data = { Username, RefId, Name };
    const id = row.id;
    const deleteRow = async () => {
      Swal.fire({
        title: "Insurance Verification Platform",
        text: "Are you sure do you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ms-1",
        },
        buttonsStyling: false,
        allowOutsideClick: false
      })
        .then(async function (result) {
          if (result.isConfirmed) {
            await deleteClient(id);
            await DeleteAuditLogdata(Data);
            Swal.fire({
              icon: "success",
              title: "Insurance Verification Platform",
              text: "Client has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
              allowOutsideClick: false
            });
            fetchData();
          }
        });
    };
    deleteRow();
  };

  const getAuditlogdata = async (data: any) => {
    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/Onder/getAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    return TempData;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (refid !== null) {
        const res = await getAuditlogdata(refid);
        setFilteredAuditData(res);
      }
    };
    fetchData();
  }, [refid]);

  const filteredData = data.filter(row => {
    return Object.values(row).some(val =>
      typeof val === "string" && val.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const Columns = [
    {
      name: "App Name",
      selector: (row: DeleteRowData) => row.Appname,
      sortable: true,
    },
    {
      name: "Customkey",
      selector: (row: DeleteRowData) => row.Customkey,
      sortable: true,
    },
    {
      name: "CustomerSecret",
      selector: (row: DeleteRowData) => row.CustomerSecret
      ,
      sortable: true,
    },
    {
      name: "Requesturl",
      selector: (row: DeleteRowData) => row.Requesturl,
      sortable: true,
    },
    {
      name: "CallbackUrl",
      selector: (row: DeleteRowData) => row.CallbackUrl,
      sortable: true,
    },
    
    {
      name: "Action",
      cell: (row: DeleteRowData) => (
        <>
          <Eye
            size={17}
            id='positionLeft'
            color='#09d5ff'
            onClick={() => {
              setScrollInnerModal(true);
              setRefid(row.RefId);
            }}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          <Edit size={17}
            id='editIcon'
            color='#FFA500'
            onClick={() => {
              navigate(`/editonder`)
              localStorage.setItem("apiEdit", row.RefId)
              ViewAuditLog(row);
            }}
            style={{ cursor: 'pointer', marginRight: '10px' }} />
          <Trash2 size={17} id='deleteIcon' color='#FF0000' onClick={() => DeleteRow(row)} style={{ cursor: 'pointer' }} />
        </>
      ),
      button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f0f0', 
         
        },
      },
    },
    headCells: {
      style: {
        backgroundColor: '#355876',  // Change background color of headers
        color: 'white',  // Change text color of headers
        fontSize: '16px',  // Adjust font size of headers
      },
    },
  };

  return (
    <>
      <Breadcrumbs mainTitle='Onder Management' title='Onder Management' />
      <Container fluid>
        <div>
          <Modal style={{ maxWidth: '1500px', maxHeight: '600px' }} isOpen={scrollInnerModal} toggle={() => setScrollInnerModal(!scrollInnerModal)} className='modal-dialog-centered modal-lg'>
          <ModalHeader toggle={() => setScrollInnerModal(!scrollInnerModal)}>Onder Management Audit Log</ModalHeader>
          <ModalBody>
              <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                      <th className="audit-tableHeaderStyle">Date & Time</th>
                      <th className="audit-tableHeaderStyle">Action</th>
                      <th className="audit-tableHeaderStyle">Data Set</th>
                      <th className="audit-tableHeaderStyle">Changed By</th>
                    </tr>
                  </thead>
                  <tbody style={{ alignItems: 'center' }}>
                    {filteredAuditData &&
                      filteredAuditData
                        .sort((a: any, b: any) => new Date(b.Modified).getTime() - new Date(a.Modified).getTime())
                        .map((item: any, index: number) => (
                          <tr key={index}>
                            <td className="audit-tableDataStyle">
                              {(() => {
                                const localDate = new Date(item.Modified);
                                return localDate.toLocaleString();
                              })()}
                            </td>
                            <td className="audit-tableDataStyle">{item.Action}</td>
                            <td className="audit-tableDataStyle">
                              {item.Action === 'VIEW' ? (
                                <span>{item.JsonData}</span>
                              ) : (
                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                  <thead>
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                      <th className="audit-tableHeaderStyle">Field</th>
                                      {item.Action === 'EDIT' && (
                                        <>
                                          <th className="audit-tableHeaderStyle">Before</th>
                                          <th className="audit-tableHeaderStyle">After</th>
                                        </>
                                      )}
                                      {item.Action === 'ADD' && (
                                        <th className="audit-tableHeaderStyle">Value</th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.JsonData
                                      .sort((a: any, b: any) => a.field.localeCompare(b.field))
                                      .map((dataItem: any, dataIndex: number) => (
                                        <tr key={dataIndex}>
                                          <td className="audit-tableDataStyle">{dataItem.field}</td>
                                          {item.Action === 'EDIT' && (
                                            <>
                                              <td className="audit-tableDataStyle">
                                                {dataItem.field === 'Permission' && Array.isArray(dataItem.before)
                                                  ? (
                                                    <table style={{ width: '100%' }}>
                                                      <thead >
                                                        <tr>
                                                          <th className="audit-tableHeaderStyle">Menu</th>
                                                          <th className="audit-tableHeaderStyle">Actions</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {dataItem.before.map((permission: any) => (
                                                          <tr key={permission.MenuLabel}>
                                                            <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                            <td className="audit-tableDataStyle">{permission.MenuActions}</td>
                                                          </tr>
                                                        ))}
                                                      </tbody>
                                                    </table>
                                                  )
                                                  : (dataItem.before && dataItem.before.value) || dataItem.before}
                                              </td>
                                              <td className="audit-tableDataStyle">
                                                {dataItem.field === 'Permission' && Array.isArray(dataItem.after)
                                                  ? (
                                                    <table style={{ width: '100%' }}>
                                                      <thead>
                                                        <tr>
                                                          <th className="audit-tableHeaderStyle">Menu</th>
                                                          <th className="audit-tableHeaderStyle">Actions</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody >
                                                        {dataItem.after.map((permission: any) => (
                                                          <tr key={permission.MenuLabel}>
                                                            <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                            <td className="audit-tableDataStyle">{permission.MenuActions}</td>
                                                          </tr>
                                                        ))}
                                                      </tbody>
                                                    </table>
                                                  )
                                                  : (dataItem.after && dataItem.after.value) || dataItem.after}
                                              </td>
                                            </>
                                          )}
                                          {item.Action === 'ADD' && (
                                            <td className="audit-tableDataStyle">
                                              {dataItem.field === 'Permission' && Array.isArray(dataItem.value)
                                                ? (
                                                  <table style={{ width: '100%' }} >
                                                    <thead>
                                                      <tr>
                                                        <th className="audit-tableHeaderStyle">Menu</th>
                                                        <th className="audit-tableHeaderStyle">Actions</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {dataItem.value.map((permission: any) => (
                                                        <tr key={permission.MenuLabel}>
                                                          <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                          <td className="audit-tableDataStyle">{permission.MenuActions}</td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                )
                                                : (dataItem.value && dataItem.value.value) || dataItem.value}
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              )}
                            </td>
                            <td className="audit-tableDataStyle">{item.UserName}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={6}>
                <Row className="align-items-center">
                  <Col sm={8}>
                    <Link className="link" to={`/userManagement/addUser`} style={{ textDecoration: 'none' }}>
                      <Button color="primary" className="align-middle">+ Add Onderurl</Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <InputGroup className="input-group-search mb-2 zoom-on-hover">
                  <Input
                    type="text"
                    placeholder="🔍 Search...."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="input-search"
                  />
                </InputGroup>
              </Col>
            </Row>
            <Card >
              <CardBody>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    {loading ? (
                      <p><center>Loading...</center></p>
                    ) : (
                      <DataTable
                        data={filteredData}
                        columns={Columns}
                        striped
                        pagination
                        customStyles={customStyles}
                        paginationPerPage={rowsPerPage}
                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                          setCurrentPage(currentPage);
                          setRowsPerPage(currentRowsPerPage);
                        }}
                        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                      />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
  
};

export default Onder;
