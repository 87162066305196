import { useEffect, useRef, useState } from 'react';
import { Image, LI, UL } from '../../../../AbstractElements';
import { dynamicImage } from '../../../../Service';
import { useAppDispatch, useAppSelector } from '../../../../ReaduxToolkit/Hooks';

import { fetchChatMemberAsync, setChats, setSelectedUser } from '../../../../ReaduxToolkit/Reducer/ChatSlice';

import A from '../../../../assets/Avatar/a.png';
import B from '../../../../assets/Avatar/b.png';
import C from '../../../../assets/Avatar/c.png';
import D from '../../../../assets/Avatar/d.png';
import E from '../../../../assets/Avatar/e.png';
import F from '../../../../assets/Avatar/f.png';
import G from '../../../../assets/Avatar/g.png';
import H from '../../../../assets/Avatar/h.png';
import I from '../../../../assets/Avatar/i.png';
import J from '../../../../assets/Avatar/j.png';
import K from '../../../../assets/Avatar/k.png';
import L from '../../../../assets/Avatar/l.png';
import M from '../../../../assets/Avatar/m.png';
import N from '../../../../assets/Avatar/n.png';
import O from '../../../../assets/Avatar/o.png';
import P from '../../../../assets/Avatar/p.png';
import Q from '../../../../assets/Avatar/q.png';
import R from '../../../../assets/Avatar/r.png';
import S from '../../../../assets/Avatar/s.png';
import T from '../../../../assets/Avatar/t.png';
import U from '../../../../assets/Avatar/u.png';
import V from '../../../../assets/Avatar/v.png';
import W from '../../../../assets/Avatar/w.png';
import X from '../../../../assets/Avatar/x.png';
import Y from '../../../../assets/Avatar/y.png';
import Z from '../../../../assets/Avatar/z.png';


const avatarImages: { [key: string]: string } = {
  A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Y, Z
};

interface AllMembersType {
  id: any;
  name: string;
  thumb: string;
  status: string;
  lastSeenDate: string;
  online?: boolean;
  typing?: boolean;
  time: string;
  reply: string;
}


interface MessageType {
  sender: any;
  text: string;
  time: string;
}



const ChatMessage = () => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const [scroll, setScroll] = useState(0);

  const ChatImg = (item: AllMembersType) => {
    if (item.thumb) {
      return item.thumb;
    } else if (item.name) {
      const firstLetter = item.name.charAt(0).toUpperCase();
      if (avatarImages[firstLetter]) {
        return avatarImages[firstLetter];
      }
    }
    return '';
  };

  const { allMembers, chats, selectedUser, currentUser } = useAppSelector((state) => state.chatData);


  const dispatch = useAppDispatch();
  const currentUserId = selectedUser.id;

  const fetchChatAsync = () => {
    if (chats.length > 0) {


      const chat = chats.filter((x: any) => x.users.includes(localStorage.id));

      const selectedUserIds = chats.map((chat: any) =>
        chat.users.find((userId: any) => userId !== localStorage.id)
      );
      const selectedUser = selectedUserIds.find((userId: any) => userId !== undefined);

      const oneSelect = allMembers.find((x: AllMembersType) => x.id === selectedUser);

      if (allMembers.length > 0) {
        dispatch(setChats(chat));
        dispatch(setSelectedUser(oneSelect));
      }
      if (allMembers.length > 0) allMembers.find((x: any) => x.id === selectedUser);
    }
  };

  useEffect(() => {
    dispatch(fetchChatMemberAsync());
    fetchChatAsync();
    setScroll(1);
  }, [dispatch, allMembers.length, chats.length]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chats, scroll]);

  const selectedChat = (allMembers && chats && selectedUser) ? chats.find((x: any) => x.users.includes(currentUserId) && x.users.includes(selectedUser.id)) : null;


  return (
    <>
      {allMembers && chats && selectedUser ? (
        <div className="chat-history chat-msg-box custom-scrollbar">
          <UL className="simple-list">
            {selectedChat && selectedChat.messages.length > 0 ? (
              selectedChat.messages.map((item: MessageType, id: any) => {
                const participators: AllMembersType | any = allMembers.find((x: AllMembersType) => x.id === item.sender);

                return (
                  <LI key={id} className={` ${item.sender === currentUserId ? "" : "clearfix"}`} >
                    <div className={`message my-message ${item.sender === currentUserId ? "" : "pull-right other-message"}`}>
                      <Image alt={"user"} src={ChatImg(participators)} className={`rounded-circle ${item.sender === currentUserId ? 'float-start' : 'float-end'} chat-user-img img-30 h-auto`} />
                      <div className="message-data text-end">
                        <span className="message-data-time">{item.time}</span>
                      </div>
                      {item.text}
                    </div>
                  </LI>
                );
              })
            ) : (
              <div><Image className="img-fluid w-100 h-auto" src={''
                // dynamicImage("login/2.jpg")
              } alt="start conversion" /></div>
            )}
            <LI>
              <div ref={bottomRef}></div>
            </LI>
          </UL>
        </div>
      ) : (
        <div className="loading"></div>
      )}
    </>
  )
}

export default ChatMessage