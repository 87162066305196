import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, Button, CardBody, CardHeader } from 'reactstrap';
import { ArrowLeftCircle } from 'react-feather';
import { useDispatch } from 'react-redux';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import Swal from 'sweetalert2';
import { Appname, Callback, CustomError, Customer, Customerse, KeyError, Request, RequestError, Verified } from '../../../utils/Constant';

const Editonder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [res, setRes] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState({
    Appname: '',
    Customkey: '',
    Secret: '',
    Requesturl: '',
    Callurl: '',
  });
  const [refId, setRefId] = useState('');
  const [Id, setId] = useState<string>('');
  const Username = localStorage.getItem('UserName') || '';

  const updatedata = async (data: any, id: string) => {
    const EncParams = AesEncrypt(JSON.stringify({ data, id }));
    const res = await fetch(webservice + 'api/Onder/editonder', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return res;
  };

  const updateAuditlog = async (data: any) => {
    data.Userid = localStorage.id;
    data.ParentUserId = localStorage.ParentUserId;
    const EncParams = AesEncrypt(JSON.stringify(data));

    const response = await fetch(webservice + 'api/Onder/EditAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

  const getOndarData = async (id: string) => {
    const EncParams = AesEncrypt(id);
    const response = await fetch(webservice + 'api/Onder/GetonderData', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    return TempData;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = localStorage.getItem('apiEdit');
    

      if (data != null) {
        const res = await getOndarData(data);
        setRes(res);
        if (res.length > 0) {
          setId(res[0].id);
          setRefId(res[0].RefId);
          setInitialValues({
            Appname: res[0].Appname,
            Customkey: res[0].Customkey,
            Secret: res[0].CustomerSecret,
            Requesturl: res[0].Requesturl,
            Callurl: res[0].CallbackUrl,
          });
        }
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (data: any, { setSubmitting }: FormikHelpers<any>) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    let updatedFields: any[] = [];

    for (const key in data) {
      if (initialValues && initialValues[key as keyof typeof initialValues] !== data[key as keyof typeof initialValues]) {
        const beforeValue = initialValues[key as keyof typeof initialValues];
        const afterValue = data[key as keyof typeof initialValues];

        if (beforeValue !== null && afterValue !== null) {
          updatedFields.push({
            field: key,
            before: beforeValue,
            after: afterValue,
          });
        }
      }
    }

    try {
      const response = await updatedata(data, Id);
      const decryptedResponse = JSON.parse(AesDecrypt(response)); // Assuming you have a decryption method
      if (!decryptedResponse.success) {
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: decryptedResponse.message,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      } else {
        await updateAuditlog({ updatedFields, refId, Username });
        Swal.fire({
          title: 'Onderurl Update',
          icon: 'success',
          text: 'Onderurl updated successfully!',
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        navigate(`/onder`);
      }
    } catch (error) {
      console.error('Error updating Onderurl:', error);
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'An error occurred while updating the Onderurl.',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs mainTitle="Edit Onderurl" parent="Onderurl" title="Edit Onderurl" />
        <Card>
        <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
          <h3>Edit Onderurl</h3>
          </CardHeader>
          <CardBody>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
             
                <Form className={`needs-validation custom-input ${formSubmit ? 'was-validated' : ''}`} noValidate>
                <Row className="g-3">
                  <Col md={4} xs={12}>
                    <Label>{Appname}</Label>
                    <Field name="Appname" type="text" className="form-control" placeholder=""  />
                  </Col>
                  <Col md={4} xs={12}>
                    <Label>{Customer}<span style={{ color: 'red' }}> * </span></Label>
                    <Field name="Customkey" type="text" className="form-control" placeholder="" required />
                    <div className="invalid-feedback">{KeyError}</div>
                    <div className="valid-feedback">{Verified}</div>
                  </Col>
                  <Col md={4} xs={12}>
                    <Label>{Customerse}<span style={{ color: 'red' }}> * </span></Label>
                    <Field name="Secret" type="text" className="form-control" placeholder="" required />
                    <div className="invalid-feedback">{CustomError}</div>
                    <div className="valid-feedback">{Verified}</div>
                  </Col>
                  <Col md={4} xs={12}>
                    <Label>{Request}<span style={{ color: 'red' }}> * </span></Label>
                    <Field name="Requesturl" type="text" className="form-control" placeholder="" required />
                    <div className="invalid-feedback">{RequestError}</div>
                    <div className="valid-feedback">{Verified}</div>
                  </Col>
                  <Col md={4} xs={12}>
                    <Label>{Callback}</Label>
                    <Field name="Callurl" type="text" className="form-control" placeholder=""  />
                  </Col>

                  <Col>
                    <div className="text-end" style={{ marginTop: "100px" }}>
                      <button type="submit" className="btn btn-success me-3">Submit</button>
                      <Link to='/onder'>
                        <button type="button" className="btn btn-danger">Cancel</button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
             
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
}

export default Editonder;
