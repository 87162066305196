import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Label, CardBody, CardHeader, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { Formik, Form, Field, FieldArray, FormikHelpers, ErrorMessage } from 'formik';
import { Plus, X } from 'react-feather';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import Swal from 'sweetalert2';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import webservice from '../../../Service/webservice';
import * as Yup from 'yup';
const Editpayor: React.FC = () => {
  const [formSubmit, setFormSubmit] = useState(false);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    Userid: '',
    UserName: '',
    location: '',
    // Email: '',
    Activestatus: { value: 'Active', label: 'Active' }, // Default value
    PayorIDs: [''],
    Mobile: [''],
    Features: [''],
  });
  const [id, setId] = useState<string>(''); // State to hold the id
  const [refId, setRefId] = useState('');
  const statusOptions = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'Inactive' }
  ];
  const Username = localStorage.getItem('UserName') || '';

  const updatedata = async (data: any, id: string) => {
    const EncParams = AesEncrypt(JSON.stringify({ data, id }));
    const res = await fetch(webservice + 'api/Payor/editpayor', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return res;
  };

  const handleSubmit = async (data: any, { setSubmitting }: FormikHelpers<any>) => {
    if (formSubmit) {
      return;
    }
    setFormSubmit(true);

    let updatedFields: any[] = [];

    for (const key in data) {
      if (initialValues && initialValues[key as keyof typeof initialValues] !== data[key as keyof typeof initialValues]) {
        const beforeValue = initialValues[key as keyof typeof initialValues];
        const afterValue = data[key as keyof typeof initialValues];

        if (beforeValue !== null && afterValue !== null) {
          updatedFields.push({
            field: key,
            before: beforeValue,
            after: afterValue,
          });
        }
      }
    }

    data.Activestatus = data.Activestatus.value; // Transform Activestatus to send only the value
    const response = await updatedata(data, id);
    const decryptedResponse = JSON.parse(AesDecrypt(response));

    if (decryptedResponse.success) {
      await updateAuditlog({ action: 'update', data: updatedFields, refId, Username });

      Swal.fire({
        title: 'Payor Management',
        icon: 'success',
        text: 'Payor Update Successfully!',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });
      navigate(`/payor`);
    } else {
      Swal.fire({
        title: 'Payor Management',
        icon: 'error',
        text: 'Failed to update payor!',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    }
    setFormSubmit(false);
    setSubmitting(false);
  };

  const updateAuditlog = async (data: any) => {
    data.Userid = localStorage.id;
    data.ParentUserId = localStorage.ParentUserId;
    const EncParams = AesEncrypt(JSON.stringify(data));

    const response = await fetch(webservice + 'api/Payor/EditAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

  const getPayorData = async (id: string) => {
    const EncParams = AesEncrypt(id);
    const response = await fetch(webservice + 'api/Payor/Getpayordata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    return TempData;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = localStorage.getItem('PayorEdit');
      if (data != null) {
        const res = await getPayorData(data);
        if (res.length > 0) {
          setId(res[0].id);
          setRefId(res[0].RefId); // Set the id state
          setInitialValues({
            Userid: res[0].Userid,
            UserName: res[0].Username,
            location: res[0].location,
            Activestatus: statusOptions.find(option => option.value === res[0].Activestatus) || statusOptions[0],
            PayorIDs: res[0].PayorIDs.length ? res[0].PayorIDs : [''],
            Mobile: res[0].Mobile.length ? res[0].Mobile : [''],
            Features: res[0].Features.length ? res[0].Features : [''],
          });
        }
      }
    };

    fetchData();
  }, []);

  const handleNumbers = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }
  const validationSchema = Yup.object().shape({
    Userid: Yup.string().required('Payer ID is required'),
    UserName: Yup.string().required('Insurance Name is required'),
    // Email: Yup.string().email('Invalid email address').required('Email is required'),
    PayorIDs: Yup.array().of(Yup.string().required('AltPayer ID is required')),
    Features: Yup.array().of(Yup.string().required('Feature is required')),
    Mobile: Yup.array().of(Yup.string().required('Mobile no is required')),
    Activestatus: Yup.object().shape({
      value: Yup.string().required('Status is required')
    }).required('Status is required')
  });
  return (
    <>
      <Breadcrumbs mainTitle='Payer Management' parent='Payer Management' title='Payer Management' to='/payor' />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
            <h3>Edit Payer</h3>
          </CardHeader>
          <CardBody className='py-2 my-25'>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                handleSubmit(values, actions);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form className={`needs-validation custom-input ${formSubmit ? 'was-validated' : ''}`} noValidate>
                  <Row className="g-3">
                    <Col md={4} xs={12}>
                      <Label>Payer Id<span style={{ color: 'red' }}>*</span></Label>
                      <Field name="Userid" type="text" className="form-control" placeholder="" required />
                      <ErrorMessage name="Userid" component="span" className="text-danger" />
                    </Col>
                    <Col md={4} xs={12}>
                      <Label>Insurance Name<span style={{ color: 'red' }}>*</span></Label>
                      <Field name="UserName" type="text" className="form-control" placeholder="" required />
                      <ErrorMessage name="UserName" component="span" className="text-danger" />
                    </Col>

                    {/* <Col md={4} xs={12}>
                      <Label>{Email}</Label>
                      <Field name="Email" type="email" className="form-control" placeholder="" required />
                      <div className="invalid-feedback">{EmailError}</div>
                      <div className="valid-feedback">{Verified}</div>
                    </Col> */}
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label>Status <span style={{ color: 'red' }}>*</span></Label>
                        <Select
                          name="Activestatus"
                          options={statusOptions}
                          value={values.Activestatus}
                          onChange={option => setFieldValue('Activestatus', option)}
                          className="react-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage name="Activestatus" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>

                    <Row>
                      <Col md={3} xs={12}>
                        <Label>Location</Label>
                        <Field name="location" type="text" className="form-control" placeholder="" required />
                        <ErrorMessage name="location" component="span" className="text-danger" />
                      </Col>

                      <Col md={3} xs={12}>
                        <FieldArray name="Mobile">
                          {({ push, remove }) => (
                            <>
                              {values.Mobile.map((mobile, index) => (
                                <div key={index} className="mb-3">
                                  <Label>Mobile No<span style={{ color: 'red' }}>*</span></Label>
                                  <div className="d-flex align-items-center">
                                    <Field name={`Mobile[${index}]`} type="text" className="form-control" onInput={handleNumbers} placeholder="" required />
                                    <Plus size={16} color="green" className="ms-2 cursor-pointer" onClick={() => push('')} />
                                    {values.Mobile.length > 1 && (
                                      <X size={16} color="red" className="ms-2 cursor-pointer" onClick={() => remove(index)} />
                                    )}
                                  </div>
                                  <ErrorMessage name={`Mobile[${index}]`} component="span" className="text-danger" />
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Col>

                      <Col md={3} xs={12}>
                        <FieldArray name="PayorIDs">
                          {({ push, remove }) => (
                            <>
                              {values.PayorIDs.map((payorID, index) => (
                                <div key={index} className="mb-3">
                                  <Label>Alt Payer Id<span style={{ color: 'red' }}>*</span></Label>
                                  <div className="d-flex align-items-center">
                                    <Field name={`PayorIDs[${index}]`} type="text" className="form-control" placeholder="" required />
                                    <Plus size={16} color="green" className="ms-2 cursor-pointer" onClick={() => push('')} />
                                    {values.PayorIDs.length > 1 && (
                                      <X size={16} color="red" className="ms-2 cursor-pointer" onClick={() => remove(index)} />
                                    )}
                                  </div>
                                  <ErrorMessage name={`PayorIDs[${index}]`} component="span" className="text-danger" />
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Col>

                      <Col md={3} xs={12}>
                        <FieldArray name="Features">
                          {({ push, remove }) => (
                            <>
                              {values.Features.map((feature, index) => (
                                <div key={index} className="mb-3">
                                  <Label>Features<span style={{ color: 'red' }}>*</span></Label>
                                  <div className="d-flex align-items-center">
                                    <Field name={`Features[${index}]`} type="text" className="form-control" placeholder="" required />
                                    <Plus size={16} color="green" className="ms-2 cursor-pointer" onClick={() => push('')} />
                                    {values.Features.length > 1 && (
                                      <X size={16} color="red" className="ms-2 cursor-pointer" onClick={() => remove(index)} />
                                    )}
                                  </div>
                                  <ErrorMessage name={`Features[${index}]`} component="span" className="text-danger" />
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Col>


                    </Row>

                    <Col>
                      <div className="text-end" style={{ marginTop: "100px" }}>
                        <button type="submit" className="btn btn-success me-3">Submit</button>
                        <Link to='/payor'>
                          <button type="button" className="btn btn-danger">Cancel</button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default Editpayor;
