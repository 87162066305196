import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ChangePassword, Users } from "../../../../utils/Constant";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import MD5 from "crypto-js/md5"; 
import webservice from '../../../../Service/webservice';
import { AesEncrypt } from "../../../../Service/crypto";
import MySwal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from 'react-feather';

interface FormData {
  newPassword: string;
  currentPassword: string;
  retypeNewPassword: string;
}

const defaultValues: FormData = {
  newPassword: "",
  currentPassword: "",
  retypeNewPassword: "",
};

const ChangeUserPassword = () => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });
  const [commonError, setCommonError] = useState<string>("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const navigate = useNavigate();

  const UserId = localStorage.id;

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    setCommonError("");
    if (formData.newPassword !== formData.retypeNewPassword) {
      setError("retypeNewPassword", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }
    
    if (formData.currentPassword === formData.newPassword) {
      setError("newPassword", {
        type: "manual",
        message: "Passwords already exist to update the new password",
      });
      return;
    }

    try {
      const currentPassword = MD5(formData.currentPassword).toString();
      const newPassword1 = MD5(formData.newPassword).toString();
      const PassData = { currentPassword, newPassword1, UserId }
      const EncParams = AesEncrypt(JSON.stringify(PassData))
      const changepassword = async () => {
        try {
          const response = await fetch(webservice + "api/Authentication/changepassword", {
            method: "POST",
            body: JSON.stringify(EncParams),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const result = await response.json();
          if (result === "current password is incorrect") {
            setError("currentPassword", {
              type: "manual",
              message: "current password is incorrect",
            });
          } else {
            return MySwal.fire({
              title: 'Insurance Verification Platform',
              text: 'Password Changed Successfully',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                reset();
              }
            });
          }
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
        }
      };
      await changepassword();
    } catch (error) {
      console.error(error);
    }
  };  

  return (
    <>
      <Breadcrumbs title={ChangePassword} mainTitle={ChangePassword} parent={Users} />
      <Container fluid>
        <div className="edit-profile">
          <Row>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle tag="h4">Change Password</CardTitle>
              </CardHeader>
              <CardBody className="pt-1">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col sm="4" className="mb-3">
                      <Label className="form-label">
                        Current Password <span style={{ color: "red" }}>*</span>
                      </Label>
                      <InputGroup className="form-input position-relative">
                        <Controller
                          name="currentPassword"
                          control={control}
                          render={({ field }) => (
                            <Input
                              type={showCurrentPassword ? "text" : "password"}
                              placeholder="*********"
                              invalid={!!errors.currentPassword}
                              {...field}
                            />
                          )}
                          rules={{ required: "Please enter the Current Password" }}
                        />
                        <InputGroupText onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                          {showCurrentPassword ? <EyeOff /> : <Eye />}
                        </InputGroupText>
                        {errors.currentPassword && (
                          <FormFeedback>
                            {errors.currentPassword.message}
                          </FormFeedback>
                        )}
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4" className="mb-3">
                      <Label className="form-label">
                        New Password <span style={{ color: "red" }}>*</span>
                      </Label>
                      <InputGroup className="form-input position-relative">
                        <Controller
                          name="newPassword"
                          control={control}
                          render={({ field }) => (
                            <Input
                              type={showNewPassword ? "text" : "password"}
                              placeholder="*********"
                              invalid={!!errors.newPassword}
                              {...field}
                            />
                          )}
                          rules={{ required: "Please enter the New Password" }}
                        />
                        <InputGroupText onClick={() => setShowNewPassword(!showNewPassword)}>
                          {showNewPassword ? <EyeOff /> : <Eye />}
                        </InputGroupText>
                        {errors.newPassword && (
                          <FormFeedback>
                            {errors.newPassword.message}
                          </FormFeedback>
                        )}
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4" className="mb-3">
                      <Label className="form-label">
                        Retype New Password <span style={{ color: "red" }}>*</span>
                      </Label>
                      <InputGroup className="form-input position-relative">
                        <Controller
                          name="retypeNewPassword"
                          control={control}
                          render={({ field }) => (
                            <Input
                              type={showRetypePassword ? "text" : "password"}
                              placeholder="*********"
                              invalid={!!errors.retypeNewPassword}
                              {...field}
                            />
                          )}
                          rules={{ required: "Please retype the New Password" }}
                        />
                        <InputGroupText onClick={() => setShowRetypePassword(!showRetypePassword)}>
                          {showRetypePassword ? <EyeOff /> : <Eye />}
                        </InputGroupText>
                        {errors.retypeNewPassword && (
                          <FormFeedback>
                            {errors.retypeNewPassword.message}
                          </FormFeedback>
                        )}
                      </InputGroup>
                    </Col>
                  </Row>
                  {commonError && (
                    <Row>
                      <Col sm="4" className="mb-3">
                        <div className="text-danger">{commonError}</div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm="4" className="mb-3">
                      <Button type="submit" color="primary">
                        Save changes
                      </Button>
                      <Button outline color="danger" type="reset" className="ms-2" onClick={() => reset()}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ChangeUserPassword;
