import { Container, Row } from "reactstrap";
import {Dashboard, WeAreComingSoon} from "../../../utils/Constant";
// import TotalEarning from "../ShoppingPlace/TotalEarning/TotalEarning";
// import RecentOrders from "../ShoppingPlace/RecentOrders/RecentOrders";
// import TopSeller from "../ShoppingPlace/TopSeller/TopSeller";
// import NewProduct from "../ShoppingPlace/NewProduct/NewProduct";
// import TopCountries from "../ShoppingPlace/TopCountries/TopCountries";
// import ActivityTimeline from "../ShoppingPlace/ActivityTimeline/ActivityTimeline"
// import SalesSummary from "../ShoppingPlace/SalesSummary/SalesSummary";
// import WeekendSale from "../ShoppingPlace/WeekendSale/WeekendSale";
// import Invoice from "../ShoppingPlace/Invoice/Invoice";
// import ProductSales from "../ShoppingPlace/ProductSales/ProductSales";
// import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { H2, H5 } from "../../../AbstractElements";

const ShoppingPlace = () => {
  return (
    <Container fluid className="dashboard_default">
      <Row className="widget-grid">
        {/* <Breadcrumbs mainTitle={Dashboard} parent={Dashboard} title={Dashboard} /> */}
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Container fluid className="p-0">
        <div className="comingsoon">
          <div className="comingsoon-inner text-center">
            <H2>{WeAreComingSoon}</H2>
            <div className="countdown" id="clockdiv">
            </div>
          </div>
        </div>
      </Container>
    </div>
      </Row>
    </Container>
  );
};

export default ShoppingPlace;
