interface PropTypes {
  iconId: string | undefined;
  onClick?: () => void;
  className?: string;
  size?:any
}

const SVG = (props: PropTypes) => {

  return (
    <svg className={props.className} onClick={props.onClick}  width={props.size}
    height={props.size}>
      <use href={`${process.env.PUBLIC_URL}/assets/svg/icon-sprite.svg#${props.iconId}`}></use>
    </svg>
  );
};

export default SVG;
